import FlowsByModel from 'types/FlowsByModel';
import FlowsByOrigin from 'types/FlowsByOrigin';
import {
  KioskTypeToVendor, PartteamModel, ShareboxModel, Vendor,
} from 'types/KioskVendors';
import { SelfServiceStepConfig } from 'modules/selfServices/types/SelfServiceStepConfig';
import { SelfService, SelfServiceOrigin, SelfServiceType } from 'modules/selfServices/types/SelfService';

import { getFollowingStep } from 'utils/stepUtils';

import { isVisibleStep } from './utils';
import CHECK_IN_STEPS from './check-in';
import CHECK_OUT_STEPS from './check-out';
import BREAKDOWN_STEPS from './breakdown';
import type { SelfServiceStep } from '../types';

export type OriginFlowsBySelfServiceType = Record<SelfServiceType, FlowsByOrigin>;

const STEPS: OriginFlowsBySelfServiceType = {
  [SelfServiceType.BREAKDOWN]: BREAKDOWN_STEPS,
  [SelfServiceType.CHECK_IN]: CHECK_IN_STEPS,
  [SelfServiceType.CHECK_OUT]: CHECK_OUT_STEPS,
};

export const getSelfServiceSteps = (selfService: SelfService, config: SelfServiceStepConfig) => {
  const selfServiceTypeFlows = STEPS[selfService.type];

  let selfServiceSteps: SelfServiceStep[];
  if (selfService.origin === SelfServiceOrigin.KIOSK) {
    const model = config.kioskModel;
    const vendor = KioskTypeToVendor[config.kioskType];
    const kioskVendor = selfServiceTypeFlows.KIOSK[vendor];

    if (vendor === Vendor.SHAREBOX) {
      const flowsByModel = kioskVendor as FlowsByModel<ShareboxModel>;
      selfServiceSteps = Object.values(flowsByModel[model as ShareboxModel]);
    } else {
      const flowsByModel = kioskVendor as FlowsByModel<PartteamModel>;
      selfServiceSteps = Object.values(flowsByModel[model as PartteamModel]);
    }
  } else {
    selfServiceSteps = Object.values(selfServiceTypeFlows[selfService.origin]);
  }

  let steps = selfServiceSteps.filter((step) => isVisibleStep(step, selfService, config));
  steps = steps.map((step) => ({
    ...step,
    next: getFollowingStep<SelfServiceStep>(step, steps, selfServiceSteps),
    previous: getFollowingStep<SelfServiceStep>(step, steps, selfServiceSteps, 'previous'),
  }));

  return steps;
};

export default STEPS;
