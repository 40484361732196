import { KioskPreferencesQuestionsIds } from 'modules/selfServices/constants';

import Flow from 'types/Flow';
import { BreakdownServiceAuthorType } from 'modules/selfServices/types/SelfService';
import { SelfServiceAPISteps, SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import { isKioskPreferenceQuestionVisible } from 'modules/selfServices/utils';

import {
  SIGNATURE,
  FINAL_INSTRUCTIONS,
  PARKING as COMMON_PARKING,
  QUESTIONS as COMMON_QUESTIONS,
  KIOSK_USAGE as COMMON_KIOSK_USAGE,
  CUSTOMER_INFO as COMMON_CUSTOMER_INFO,
} from '../common';
import { isNotAnswered } from '../utils';
import BreakdownInfo from '../../../BreakdownInfo';
import type { SelfServiceStep } from '../../types';

export const CUSTOMER_INFO: SelfServiceStep = {
  ...COMMON_CUSTOMER_INFO,
  next: SelfServiceSteps.BREAKDOWN_INFO,
};

export const BREAKDOWN_INFO: SelfServiceStep = {
  key: SelfServiceSteps.BREAKDOWN_INFO,
  apiKey: SelfServiceAPISteps.ISSUE_DESCRIPTION,
  component: BreakdownInfo,
  previous: SelfServiceSteps.CUSTOMER_INFO,
  next: SelfServiceSteps.QUESTIONS,
  data: {
    isVisible: ({ status }) => isNotAnswered(status),
  },
};

export const QUESTIONS: SelfServiceStep = {
  ...COMMON_QUESTIONS,
  previous: BREAKDOWN_INFO.key,
  next: SelfServiceSteps.KIOSK_USAGE,
};

export const KIOSK_USAGE: SelfServiceStep = {
  ...COMMON_KIOSK_USAGE,
  previous: SelfServiceSteps.QUESTIONS,
  next: SelfServiceSteps.PARKING,
  data: {
    isVisible: ({
      type, origin, status, breakdownServiceAuthorType,
    }, config) => {
      const isCheckOutVisible = isKioskPreferenceQuestionVisible(
        KioskPreferencesQuestionsIds.CHECK_OUT,
        type,
        origin,
        config,
      );

      return isNotAnswered(status)
          && isCheckOutVisible
          && breakdownServiceAuthorType === BreakdownServiceAuthorType.CUSTOMER;
    },
  },
};

export const PARKING: SelfServiceStep = {
  ...COMMON_PARKING,
  previous: SelfServiceSteps.KIOSK_USAGE,
  next: SIGNATURE.key,
};

type OnSiteBreakdownSteps =
  SelfServiceSteps.CUSTOMER_INFO
  | SelfServiceSteps.BREAKDOWN_INFO
  | SelfServiceSteps.QUESTIONS
  | SelfServiceSteps.KIOSK_USAGE
  | SelfServiceSteps.PARKING
  | SelfServiceSteps.SIGNATURE
  | SelfServiceSteps.FINAL_INSTRUCTIONS;

const ON_SITE_BREAKDOWN_STEPS: Flow<OnSiteBreakdownSteps> = {
  CUSTOMER_INFO,
  BREAKDOWN_INFO,
  QUESTIONS,
  KIOSK_USAGE,
  PARKING,
  SIGNATURE: {
    ...SIGNATURE,
    previous: PARKING.key,
    next: FINAL_INSTRUCTIONS.key,
    data: { ...SIGNATURE.data, isFinalizeStep: true },
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: SIGNATURE.key },
};

export default ON_SITE_BREAKDOWN_STEPS;
