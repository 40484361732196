import Flow from 'types/Flow';
import FlowsByModel from 'types/FlowsByModel';
import { PartteamModel } from 'types/KioskVendors';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import COMMON_KIOSK_CHECK_IN_STEPS from './common';
import { FINAL_INSTRUCTIONS, PARTTEAM_KEY, SIGNATURE } from '../../common';
import { CommonOnSiteKioskCheckInSteps, VEHICLE_CHECK, VEHICLE_CHECK_QR_CODE } from '../onSite';

type PartteamKioskCheckInSteps =
  CommonOnSiteKioskCheckInSteps
  | SelfServiceSteps.SIGNATURE
  | SelfServiceSteps.PARTTEAM_KEY
  | SelfServiceSteps.VEHICLE_CHECK_QR_CODE
  | SelfServiceSteps.FINAL_INSTRUCTIONS;

const flow: Flow<PartteamKioskCheckInSteps> = {
  ...COMMON_KIOSK_CHECK_IN_STEPS,
  SIGNATURE: {
    ...SIGNATURE,
    previous: VEHICLE_CHECK.key,
    next: PARTTEAM_KEY.key,
    data: {
      ...SIGNATURE.data,
      isFinalizeStep: true,
    },
  },
  PARTTEAM_KEY: {
    ...PARTTEAM_KEY,
    previous: SIGNATURE.key,
    next: VEHICLE_CHECK_QR_CODE.key,
  },
  VEHICLE_CHECK_QR_CODE: { ...VEHICLE_CHECK_QR_CODE, previous: PARTTEAM_KEY.key, next: FINAL_INSTRUCTIONS.key },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: VEHICLE_CHECK_QR_CODE.key },
};

const PARTTEAM_KIOSK_CHECK_IN_STEPS: FlowsByModel<PartteamModel> = {
  [PartteamModel.INDOOR]: flow,
  [PartteamModel.OUTDOOR]: flow,
};

export default PARTTEAM_KIOSK_CHECK_IN_STEPS;
