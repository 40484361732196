import SelfServiceStatus from 'modules/selfServices/types/SelfServiceStatus';
import { SelfServiceStepConfig } from 'modules/selfServices/types/SelfServiceStepConfig';
import { FlowType, Parking as ParkingType, SelfService } from 'modules/selfServices/types/SelfService';

import type { SelfServiceStep } from '../types';

export const isVisibleStep = (step: SelfServiceStep, selfService: SelfService, config?: SelfServiceStepConfig) => {
  const { isVisible } = step.data;
  return typeof isVisible === 'boolean' ? isVisible : isVisible(selfService, config);
};

export const hasParking = (parking: ParkingType = {}) => Boolean(parking.spotNumber || parking.x > 0 || parking.y > 0);

export const isNotAnswered = (status: SelfServiceStatus) => status !== SelfServiceStatus.ANSWERED;

export const areAllStepsHidden = (
  selfService: SelfService,
  config: SelfServiceStepConfig,
  steps: SelfServiceStep[],
) => steps.length > 0 && steps.every((step) => !isVisibleStep(step, selfService, config));

export const hasHiddenSteps = (stepsToCheck: SelfServiceStep[]) => (
  (selfService: SelfService, config: SelfServiceStepConfig) => areAllStepsHidden(selfService, config, stepsToCheck)
);

export const isClassicCheckIn = (_: Partial<SelfService>, config: SelfServiceStepConfig) => config.flowType !== FlowType.QUICK;
