import { createDraftSafeSelector } from '@reduxjs/toolkit';

import {
  getContext, getOrigin, hasShareboxStyle, isRemoteKiosk,
} from 'modules/dealers/selectors';

// Use "Porsche" to test the theme
export const getAppTheme = createDraftSafeSelector(getContext, () => 'Default');

export const getAppOrigin = createDraftSafeSelector(
  getOrigin,
  hasShareboxStyle,
  isRemoteKiosk,
  (origin, shouldApplyShareboxStyle, isRemote) => {
    if (isRemote) {
      return 'REMOTE_KIOSK';
    }

    if (shouldApplyShareboxStyle) {
      return 'SHAREBOX_INDOOR_KIOSK';
    }

    return origin ?? '';
  }
  ,
);

export const isLowPerfMode = hasShareboxStyle;
