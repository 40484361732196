import { KeyStatus } from 'modules/kiosk/types/Kiosk';
import { SelfService, SelfServiceType } from 'modules/selfServices/types/SelfService';

export enum KeyAction {
  UNKNOWN = 'UNKNOWN',
  RETRIEVE = 'RETRIEVE',
  DROP = 'DROP',
  SWAP = 'SWAP',
}

export const getKeyActionFromSelfService = (selfService?: SelfService) => {
  if (!selfService) {
    return KeyAction.UNKNOWN;
  }

  const hasMobility = selfService.mobilityEnabled && (
    selfService.type !== SelfServiceType.CHECK_IN || selfService.keyStatus === KeyStatus.IN_SLOT
  );

  if (hasMobility) {
    return KeyAction.SWAP;
  }

  if (selfService.type === SelfServiceType.BREAKDOWN || selfService.type === SelfServiceType.CHECK_IN) {
    return KeyAction.DROP;
  }

  return KeyAction.RETRIEVE;
};
