import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import { hasErrorOnKiosk, isCourtesyUnavailable, isKioskFull as isKioskFullSelector } from 'modules/dealers/selectors';

export type State = {
  atDealership?: boolean,
  type?: SelfServiceType,
};

type UseLoginRedirectionProps = {
  onError: (value: boolean) => void,
};

const useLoginRedirection = ({ onError }: UseLoginRedirectionProps) => {
  const navigate = useNavigate();
  const { search, state: pageState } = useLocation();

  const hasKioskError = useSelector(hasErrorOnKiosk);
  const isKioskFull = useSelector(isKioskFullSelector);
  const isCourtesyNotAvailable = useSelector(isCourtesyUnavailable);

  return React.useCallback(({ atDealership, type }: State) => {
    const shouldShowPopup = (atDealership && (hasKioskError || isCourtesyNotAvailable)) || (type === SelfServiceType.BREAKDOWN && isKioskFull);

    if (shouldShowPopup) {
      onError(true);
    } else {
      navigate(`/login${search}`, {
        state: {
          ...pageState,
          atDealership,
          type,
        },
      });
    }
  }, [navigate, search, pageState, hasKioskError, isKioskFull, onError, isCourtesyNotAvailable]);
};

export default useLoginRedirection;
