import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames, { Argument } from 'classnames';

import { GET_MEDIA_UPLOAD_URL } from 'constants/url';
import { SUPPORTED_MEDIAS } from 'constants/mediaTypes';

import { AbstractMedia, MediaType } from 'types/AbstractMedia';
import { MediaEntityType, MediaSubType } from 'types/MediaTypeEnum';

import useUpload from 'hooks/useUpload';

import { Carousel, Spinner } from 'components/ui';

import Trash from 'assets/icons/trash.svg';
import Retry from 'assets/icons/retry.svg';
import EyeIcon from 'assets/icons/eye.svg';
import Warning from 'assets/icons/warning.svg';
import CheckIcon from 'assets/icons/check.svg';
import CameraIcon from 'assets/icons/camera.svg';
import PictureIcon from 'assets/icons/picture.svg';
import Uploading from 'assets/icons/uploading.svg';

export interface CardProps {
  selfServiceId: string;
  onUploaded: (uploadedMedia: AbstractMedia) => void;
  className?: Argument;
  hidePreview?: boolean;
  defaultMedia?: AbstractMedia;
  onDelete?: () => void;
}

const MediaCardUpload: React.FC<CardProps> = ({
  selfServiceId, defaultMedia, onUploaded, onDelete, hidePreview, className,
}) => {
  const {
    browse, upload, isError, isLoading,
  } = useUpload(selfServiceId);

  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [file, setFile] = React.useState<File>();
  const [media, setMedia] = React.useState<AbstractMedia>(defaultMedia);

  const { type, uploadId } = media ?? {};

  const isUploaded = !isError && Boolean(uploadId);
  const UploadIcon = type === MediaType.VIDEO ? CameraIcon : PictureIcon;

  const displayErrorStatus = !isLoading && isError;
  const displayCheckStatus = !isLoading && isUploaded;
  const displayStatus = isLoading || isUploaded || isError;
  const displayUploadIcon = !isLoading && !isError && !isUploaded;

  const handleUpload = React.useCallback(async (fileParam: File = undefined) => {
    let newFile = fileParam;

    try {
      // If no file was provided, we browse for one
      if (!newFile) {
        const files = await browse({
          accept: defaultMedia?.type === MediaType.VIDEO
            ? SUPPORTED_MEDIAS.VEHICLE_CHECK_VIDEO
            : SUPPORTED_MEDIAS.VEHICLE_CHECK_IMAGE,
          capture: 'environment',
        });

        if (files.length > 0) {
          // Neither of the two writings are valid for this line 🤷‍♀️
          // eslint-disable-next-line prefer-destructuring
          newFile = files[0];
          setFile(newFile);
        }
      }

      if (newFile) {
        const newMedia = await upload({
          file: newFile,
          filename: newFile.name,
          contentType: newFile.type,
          url: GET_MEDIA_UPLOAD_URL,
          subType: MediaSubType.ITEM_RESPONSE_MEDIA,
          entityType: MediaEntityType.VEHICLE_CHECK,
        });

        setMedia(newMedia);
        onUploaded(newMedia);
      }
    } catch (e) {
      // Nothing to do
    }
  }, [browse, upload, onUploaded]);

  const handleOpen = React.useCallback(() => setIsFullscreen(true), []);
  const handleClose = React.useCallback(() => setIsFullscreen(false), []);
  // Good luck finding the right type to avoid creating this ugly wrapper
  const handleUploadOnClick = React.useCallback(() => handleUpload(), [handleUpload]);
  const handleRetry = React.useCallback(() => handleUpload(file), [file, handleUpload]);
  const handleDelete = React.useCallback(() => {
    setMedia(defaultMedia);
    onDelete?.();
  }, [defaultMedia, onDelete]);

  return (
    <div className="relative rounded-xl overflow-hidden">
      <Carousel
        medias={[media]}
        onClose={handleClose}
        fullscreen={isFullscreen}
        className={className}
      />
      <div className={classNames(
        'absolute flex top-0 left-0 size-full z-20',
        { 'justify-end items-end': isUploaded, 'justify-center items-center': !isUploaded },
      )}
      >
        {displayUploadIcon && (
          <div
            onClick={handleUploadOnClick}
            className={classNames(
              'size-14 !m-2 rounded-full cursor-pointer bg-white',
              isLoading ? 'p-4' : 'p-3',
            )}
            data-testid="upload"
          >
            {isLoading ? <Spinner /> : <UploadIcon />}
          </div>
        )}
        {isError && (
          <div className="flex flex-col items-center text-center text-white uppercase font-bold">
            <FormattedMessage id="error.media.upload.retry" defaultMessage="Upload fail, retry" />
            <div
              className="flex justify-center items-center size-14 p-3 !m-2 rounded-full cursor-pointer bg-white"
              data-testid="retry"
              onClick={handleRetry}
            >
              {isLoading ? <Spinner /> : <Retry className="size-8" />}
            </div>
          </div>
        )}
        {!hidePreview && (
          <div
            className="flex justify-center items-center size-14 p-3 !m-2 rounded-full cursor-pointer bg-white"
            onClick={handleOpen}
            data-testid="preview"
          >
            <EyeIcon />
          </div>
        )}
        {isUploaded && (
          <div
            className="flex justify-center items-center size-14 p-3 !m-2 rounded-full cursor-pointer bg-white"
            onClick={handleDelete}
            data-testid="delete"
          >
            <Trash className="fill-danger-default" />
          </div>
        )}
      </div>
      {!isUploaded && (
        <div
          className="absolute flex justify-center top-0 left-0 size-full bg-brand-high opacity-60 z-10 rounded-xl"
        />
      )}
      {displayStatus && (
        <div className={classNames(
          // eslint-disable-next-line max-len
          'absolute top-0 right-0 size-0 border-solid z-30 border-0 border-r-60 border-b-60 border-transparent border-r-success-default',
          { 'border-r-warning-default': isLoading, 'border-r-danger-default': isError },
        )}
        >
          {isLoading && <Uploading className="ml-7 mt-1.5 size-6 vertical-pulse" />}
          {displayErrorStatus && <Warning className="ml-7 mt-1.5 size-6 fill-white" />}
          {displayCheckStatus && <CheckIcon className="ml-7 mt-1.5 size-6 fill-white" />}
        </div>
      )}
    </div>
  );
};

export default MediaCardUpload;
