import React from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import PageBaseProps from 'types/PageBase';
import { ButtonType } from 'components/ui/Button';
import { ChannelEnum } from 'modules/auth/types/LoginRequest';
import { ErrorTypeEnum, isApiErrorResponse } from 'types/Error';

import { b, getHyperlink } from 'utils/i18nUtils';
import { useLoginMFAMutation } from 'modules/auth/service';

import { getContext, getPolicies, isKioskOrigin } from 'modules/dealers/selectors';
import { BreakdownServiceAuthorType, SelfServiceType } from 'modules/selfServices/types/SelfService';
import { isFlowTypeModalVisible, isSelfServiceLoading as isSelfServiceLoadingSelector } from 'modules/selfServices/selectors';
import {
  canCollectAnalyticsData, getLoginData, getMFAData, getSelectedSelfServiceId,
} from 'modules/auth/selectors';

import SupportCheckmarkIcon from 'assets/icons/supportCheckmark.svg';

import Footer from 'components/ui/PageFooter';
import FlowTypeModal from 'components/ui/FlowTypeModal';
import { Button, Input, PageHeader } from 'components/ui';

import LoginTerms from './LoginTerms';
import ResendCodeModal from './ResendCodeModal';
import MobilityNotPreparedModal from './MobilityNotPreparedModal';

const Verify: React.FC<PageBaseProps> = ({ onPrev }) => {
  const intl = useIntl();

  const context = useSelector(getContext);
  const isKiosk = useSelector(isKioskOrigin);
  const canUseGA = useSelector(canCollectAnalyticsData);
  const { isDriver } = useSelector(getLoginData);

  const { selfServicePhoneNumber } = context;

  const {
    type,
    email,
    mobility,
    phoneNumber,
    connectionId,
    remainingAttempt,
    channel = ChannelEnum.SMS,
    confidentialityPolicyLink,
    generalTermsConditionsLink,
  } = useSelector(getMFAData);

  const policies = useSelector(getPolicies);
  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const isSelfServiceLoading = useSelector(isSelfServiceLoadingSelector);
  const isFlowTypeModalOpen = useSelector(isFlowTypeModalVisible);

  const [code, setCode] = React.useState('');
  const [isChecked, setIsChecked] = React.useState(false);
  const [displayModal, setDisplayModal] = React.useState(false);
  const [displayMobilityWarning, setDisplayMobilityWarning] = React.useState(isKiosk && mobility?.notPrepared);

  const handleCloseMobilityWarning = React.useCallback(() => {
    setDisplayMobilityWarning(false);
  }, []);

  const [login, { isLoading: isLoginIn, error, isError }] = useLoginMFAMutation();

  const hasWrongCodeError = isApiErrorResponse(error) && error.data.errorType === ErrorTypeEnum.MFA_WRONG_CODE;
  const inputError = hasWrongCodeError
    ? intl.formatMessage({
      id: 'error.types.MFA_WRONG_CODE',
      defaultMessage: 'Wrong code, please try again',
    })
    : isError;

  const shouldDisplayHelper = Boolean(selfServicePhoneNumber?.local) && hasWrongCodeError;
  if (canUseGA && shouldDisplayHelper) {
    ReactGA.event({
      category: 'Help',
      action: 'Helper displayed',
      label: 'Issue with OTP',
    });
  }

  const sentChannelMessage = {
    id: `login.sentBy.${channel}`,
    defaultMessage: `Verification ${channel} sent to <b>{contactInfo}</b>`,
    values: {
      contactInfo: channel === ChannelEnum.EMAIL ? email : phoneNumber,
    },
  };

  const isLoading = isLoginIn || isSelfServiceLoading;
  const isDisabled = !isChecked || code.length < 6 || remainingAttempt === 0;

  const breakdownServiceAuthorType = React.useMemo(() => {
    if (type === SelfServiceType.BREAKDOWN) {
      return isDriver
        ? BreakdownServiceAuthorType.CUSTOMER
        : BreakdownServiceAuthorType.BREAKDOWN_MECHANIC;
    }
    return undefined;
  }, [isDriver, type]);

  const handleSendCode = React.useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setDisplayModal(true);
  }, []);

  const handleInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  }, []);

  const handleNextClick = React.useCallback(async () => {
    login({
      connectionId, selfServiceId, code, policies, breakdownServiceAuthorType,
    });
  }, [login, connectionId, selfServiceId, code, policies, breakdownServiceAuthorType]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isDisabled) {
      handleNextClick();
    }
  };

  const handleAccept = React.useCallback(() => {
    setIsChecked((checked) => !checked);
  }, []);

  return (
    <>
      <PageHeader
        hideSteps
        onPrev={onPrev}
        title={<FormattedMessage id="login.verificationCode" defaultMessage="Verification code" />}
      />
      <div className="main-content">
        <div className="content !m-auto w-full text-center">
          <FormattedMessage
            {...sentChannelMessage}
            values={{ b, ...sentChannelMessage.values }}
            tagName="span"
          />
          <Input
            center
            className="mt-4"
            value={code}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            label={intl.formatMessage({ id: 'login.verificationCode', defaultMessage: 'Verification code' })}
            autoComplete="one-time-code"
            type="number"
            inputMode="numeric"
            autoCorrect="off"
            spellCheck="false"
            error={inputError}
            data-testid="code-input"
          />
          <div className="text-center">
            <FormattedMessage
              id="login.didntReceiveCode"
              defaultMessage="You did not receive the code ?{linebreak}<link>Resend the code</link>"
              values={{
                linebreak: <br />,
                link: getHyperlink({
                  href: '#', className: 'font-bold', onClick: handleSendCode, 'data-testid': 'resend-button',
                }),
              }}
              tagName="span"
            />
          </div>
          {shouldDisplayHelper && (
            <div
              data-testid="login-helper"
              className="flex items-center justify-center bg-card-bg rounded-card kiosk:rounded-full p-5 w-full"
            >
              <SupportCheckmarkIcon className="shrink-0" />
              <span className="ml-4">
                <FormattedMessage
                  id="login.helpbox"
                  defaultMessage="If you are unable to log in, we can help! <b>Go to the front desk</b> or call the <b>{helpline}</b>."
                  values={{ b, helpline: `+${selfServicePhoneNumber.prefix} ${selfServicePhoneNumber.local}` }}
                />
              </span>
            </div>
          )}
          <div className="w-full h-px my-4 kiosk:my-8 bg-border-default" />
          <LoginTerms
            isChecked={isChecked}
            onAccept={handleAccept}
            confidentialityPolicyLink={confidentialityPolicyLink}
            generalTermsConditionsLink={generalTermsConditionsLink}
            className="justify-center"
          />
        </div>

        <Footer
          hideNextButton
          onNext={handleNextClick}
          loading={isLoading}
          disabled={isDisabled}
          onPrev={onPrev}
          shouldDisplayBackButton
        >
          <Button
            testId="verify"
            loading={isLoading}
            disabled={isDisabled}
            type={ButtonType.TERTIARY}
            onClick={handleNextClick}
            className="max-w-md w-full kiosk:inline-flex kiosk:justify-center kiosk:w-72"
          >
            <FormattedMessage id="login.verify" defaultMessage="Verify" />
          </Button>
        </Footer>
      </div>
      {isFlowTypeModalOpen && <FlowTypeModal />}
      {displayModal && <ResendCodeModal onClose={setDisplayModal} />}
      {displayMobilityWarning && <MobilityNotPreparedModal onClose={handleCloseMobilityWarning} />}
    </>
  );
};

export default Verify;
