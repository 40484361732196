import Flow from 'types/Flow';
import FlowsByModel from 'types/FlowsByModel';
import { ShareboxModel } from 'types/KioskVendors';

import { createDictionary } from 'utils/objectUtils';

import SHAREBOX_M28_BREAKDOWN_STEPS from './m28';
import SHAREBOX_W64_BREAKDOWN_STEPS from './w64';
import SHAREBOX_S32_BREAKDWON_STEPS from './s32';
import SHAREBOX_I32_BREAKDOWN_STEPS, { ShareboxI32BreakdownSteps } from './i32';

const SHAREBOX_KIOSK_BREAKDOWN_STEPS = createDictionary<ShareboxModel, Flow<ShareboxI32BreakdownSteps>>({
  [ShareboxModel.I32]: SHAREBOX_I32_BREAKDOWN_STEPS,
  [ShareboxModel.W64]: SHAREBOX_W64_BREAKDOWN_STEPS,
  [ShareboxModel.S32]: SHAREBOX_S32_BREAKDWON_STEPS,
  [ShareboxModel.M28]: SHAREBOX_M28_BREAKDOWN_STEPS,
}, SHAREBOX_I32_BREAKDOWN_STEPS) as FlowsByModel<ShareboxModel>;

export default SHAREBOX_KIOSK_BREAKDOWN_STEPS;
