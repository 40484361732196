import React from 'react';
import classNames, { Argument } from 'classnames';
import { useSelector } from 'react-redux';

import { getCurrentStep } from 'modules/steps/selector';

import QuestionMark from 'assets/icons/questionMark.svg';

import HelpModal from './Modal';
import Button, { ButtonType } from '../Button';

const HelpButton: React.FC<{ className?: Argument }> = ({ className }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const currentStep = useSelector(getCurrentStep);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  return (
    <div className="py-3 kiosk:py-10 z-50">
      <Button
        className={classNames('size-10 kiosk:size-20 !p-2', className)}
        onClick={handleOpenModal}
        type={ButtonType.SECONDARY}
        testId="help-button"
        analytics={{
          category: 'Help',
          action: 'Help button',
          label: `Help needed from ${currentStep}`,
        }}
      >
        <QuestionMark className="m-auto kiosk:size-8" />
      </Button>
      <HelpModal open={isOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default HelpButton;
