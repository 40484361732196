import {
  createAction,
  UnknownAction,
  combineReducers,
  StateFromReducersMapObject,
} from '@reduxjs/toolkit';

import api from 'service/index';
import auth from 'modules/auth/reducer';
import chat from 'modules/chat/reducer';
import forms from 'modules/form/reducer';
import steps from 'modules/steps/reducer';
import kiosk from 'modules/kiosk/reducers';
import socket from 'modules/sockets/reducer';
import dealers from 'modules/dealers/reducer';
import healthCheck from 'modules/healthCheck/reducer';
import elapsedTime from 'modules/elapsedTime/reducer';
import selfServices from 'modules/selfServices/reducer';
import translations from 'modules/translations/reducer';
import vehicleCheck from 'modules/vehicleCheck/reducer';
import onlinePayment from 'modules/onlinePayment/reducer';
import notifications from 'modules/notifications/reducer';

import { routerReducer } from './firstHistoryConfig';

export const resetApp = createAction('RESET_APP');

const reducer = {
  [api.reducerPath]: api.reducer,
  auth: auth.reducer,
  dealers: dealers.reducer,
  elapsedTime: elapsedTime.reducer,
  form: forms.reducer,
  healthCheck: healthCheck.reducer,
  kiosk,
  notifications: notifications.reducer,
  onlinePayment: onlinePayment.reducer,
  router: routerReducer,
  selfServices: selfServices.reducer,
  socket: socket.reducer,
  steps: steps.reducer,
  translations: translations.reducer,
  vehicleCheck: vehicleCheck.reducer,
  chat: chat.reducer,
};

const appReducer = combineReducers(reducer);
const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: UnknownAction,
) => appReducer(action.type === resetApp.type ? undefined : state, action);

export type RootState = StateFromReducersMapObject<typeof reducer>;
export default rootReducer;
