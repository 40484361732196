import { createSlice } from '@reduxjs/toolkit';

import { FlowType } from './types/SelfService';

export type InitialState = {
  isFlowTypeModalOpen: boolean;
  flowType?: FlowType;
};

const initialState: InitialState = {
  flowType: undefined,
  isFlowTypeModalOpen: false,
};

const selfServicesSlice = createSlice({
  name: 'selfServices',
  initialState,
  reducers: {
    openFlowTypeModal: (state) => ({
      ...state,
      isFlowTypeModalOpen: true,
    }),
    resetFlowType: (state) => ({
      ...state,
      ...initialState,
    }),
    setFlowType: (state, { payload }) => ({
      ...state,
      flowType: payload,
      isFlowTypeModalOpen: false,
    }),
  },
});

export default selfServicesSlice;
