import React from 'react';
import { useSelector } from 'react-redux';
import { MessageDescriptor } from 'react-intl';

import { ShareboxModel } from 'types/KioskVendors';
import { KeyStatus } from 'modules/kiosk/types/Kiosk';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import { createDictionary } from 'utils/objectUtils';

import { getKioskModel } from 'modules/dealers/selectors';
import { getSelectedSelfService } from 'modules/selfServices/selectors';

/* eslint-disable max-len */
export const DEFAULT_MESSAGES = {
  [`mobility_${SelfServiceType.CHECK_IN}_${ShareboxModel.I32}`]: 'Swap the vehicle keys and close the door.',
  [`mobility_${SelfServiceType.CHECK_IN}_${ShareboxModel.M28}`]: 'Swap the vehicle keys and close the door.',
  [`mobility_${SelfServiceType.CHECK_IN}_${ShareboxModel.W64}`]: 'Swap the vehicle keys and close the door.',
  [`mobility_${SelfServiceType.CHECK_IN}_${ShareboxModel.S32}`]: 'You can open the kiosk by clicking the green blinking button. Swap the vehicle keys and click again to close the door.',

  [`mobility_${SelfServiceType.CHECK_OUT}_${ShareboxModel.I32}`]: 'Swap the vehicle keys and close the door.',
  [`mobility_${SelfServiceType.CHECK_OUT}_${ShareboxModel.M28}`]: 'Swap the vehicle keys and close the door.',
  [`mobility_${SelfServiceType.CHECK_OUT}_${ShareboxModel.W64}`]: 'Swap the vehicle keys and close the door.',
  [`mobility_${SelfServiceType.CHECK_OUT}_${ShareboxModel.S32}`]: 'You can open the kiosk by clicking the green blinking button. Swap the vehicle keys and click again to close the door.',

  [`${SelfServiceType.CHECK_IN}_${ShareboxModel.I32}`]: 'Place the keys inside and close the door.',
  [`${SelfServiceType.CHECK_IN}_${ShareboxModel.M28}`]: 'Place the keys inside and close the door.',
  [`${SelfServiceType.CHECK_IN}_${ShareboxModel.W64}`]: 'Place the keys inside and close the door.',
  [`${SelfServiceType.CHECK_IN}_${ShareboxModel.S32}`]: 'You can open the kiosk by clicking the green blinking button. Place the keys inside and click again to close the door.',

  [`${SelfServiceType.CHECK_OUT}_${ShareboxModel.I32}`]: 'Retrieve the keys and close the door.',
  [`${SelfServiceType.CHECK_OUT}_${ShareboxModel.M28}`]: 'Retrieve the keys and close the door.',
  [`${SelfServiceType.CHECK_OUT}_${ShareboxModel.W64}`]: 'Retrieve the keys and close the door.',
  [`${SelfServiceType.CHECK_OUT}_${ShareboxModel.S32}`]: 'You can open the kiosk by clicking the green blinking button. Retrieve the keys inside and click again to close the door.',
};
/* eslint-enable max-len */

type UseRemoteKeyTranslationsParams = {
  path?: string;
  defaultMessages?: Record<string, string>;
};

const useShareboxKeyTranslations = ({
  path = 'key.step',
  defaultMessages: defaultMessagesProp = DEFAULT_MESSAGES,
}: UseRemoteKeyTranslationsParams = {}): MessageDescriptor => {
  const kioskModel = useSelector(getKioskModel);
  const selfService = useSelector(getSelectedSelfService);

  const type = selfService.type === SelfServiceType.BREAKDOWN ? SelfServiceType.CHECK_IN : selfService.type;
  const mobilityEnabled = selfService.mobilityEnabled
    && (selfService.type !== SelfServiceType.CHECK_IN || selfService.keyStatus === KeyStatus.IN_SLOT);

  return React.useMemo(() => {
    const defaultMessages = createDictionary<string, string>(defaultMessagesProp, '');

    if (mobilityEnabled) {
      return {
        id: `${path}.mobility_${type}_${kioskModel}.indication`,
        defaultMessage: defaultMessages[`mobility_${type}_${kioskModel}`],
      };
    }

    return {
      id: `${path}.${type}_${kioskModel}.indication`,
      defaultMessage: defaultMessages[`${type}_${kioskModel}`],
    };
  }, [path, type, mobilityEnabled, kioskModel, defaultMessagesProp]);
};

export default useShareboxKeyTranslations;
