import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getHyperlink } from 'utils/i18nUtils';

import { setCookies } from 'modules/auth/actions';

import { showCookieSettings } from 'modules/auth/selectors';

import { CookiesSettings } from 'modules/auth/types/InitialState';

import { Modal } from 'components/ui';
import Button, { ButtonType } from 'components/ui/Button';

import CookieSettings from './Settings';
import { PRIVACY_POLICY_URL } from './config';

const Cookies: React.FC = () => {
  const dispatch = useDispatch();
  const acceptAllRef = React.useRef<HTMLButtonElement>();

  const showModal = useSelector(showCookieSettings);

  const [preferencesVisible, setPreferencesVisible] = React.useState(false);

  const handleShowPreferences = React.useCallback(() => setPreferencesVisible(true), []);

  const onSubmit = React.useCallback((settings:CookiesSettings[]) => {
    dispatch(setCookies(settings));
  }, [dispatch]);

  const handleAcceptAllOnClick = React.useCallback(() => {
    onSubmit(Object.values(CookiesSettings));
  }, [onSubmit]);

  const handleAcceptEssentialOnClick = React.useCallback(() => {
    onSubmit([CookiesSettings.ESSENTIAL]);
  }, [onSubmit]);

  const handleSettingsOnSubmit = React.useCallback((settings:CookiesSettings[]) => {
    onSubmit(settings);
    setPreferencesVisible(false);
  }, [onSubmit]);

  return (
    <>
      <Modal
        hideCloseButton
        focusRef={acceptAllRef}
        open={showModal && !preferencesVisible}
        className="w-full max-w-lg max-h-[95vh] flex flex-col"
        title={(
          <div className="text-2xl text-default font-bold">
            <FormattedMessage id="cookies.title" defaultMessage="We value your privacy" />
          </div>
        )}
      >
        <div className="mt-8 text-justify max-h-1/2-screen overflow-y-auto scroll-smooth text-sm">
          <FormattedMessage
            id="cookies.content"
            defaultMessage='Fleetback uses cookies and other technologies to keep the website reliable and secure, and to measure performance. To do this, we collect information about users, their behaviour, and their devices. {linebreak}{linebreak} If you select "Accept all", you accept this and agree that we may share this information with third parties, such as our marketing partners and analytics services. Select "Set preferences" for further details and to manage your options.{linebreak}{linebreak}For more information, please read our <a>privacy notice</a>.'
            values={{
              a: getHyperlink({
                target: '_blank',
                className: 'font-bold',
                rel: 'noopener noreferrer',
                'data-testid': 'general-terms',
                href: PRIVACY_POLICY_URL,
              }),
              linebreak: <br />,
            }}
          />
        </div>

        <div className="mt-8">
          <Button
            ref={acceptAllRef}
            testId="accept-all"
            className="w-full mb-2 sm:mb-4"
            onClick={handleAcceptAllOnClick}
          >
            <FormattedMessage id="cookies.acceptAll" defaultMessage="Accept all" />
          </Button>

          <div className="flex flex-col sm:flex-row justify-between text-center whitespace-nowrap">
            <Button
              testId="preferences"
              type={ButtonType.SECONDARY}
              onClick={handleShowPreferences}
              className="w-full mb-2 sm:mb-0 mr-0 sm:mr-1"
            >
              <FormattedMessage id="cookies.preferences" defaultMessage="Set preferences" />
            </Button>
            <Button
              testId="essential"
              type={ButtonType.SECONDARY}
              className="w-full ml-0 sm:ml-1"
              onClick={handleAcceptEssentialOnClick}
            >
              <FormattedMessage id="cookies.onlyEssential" defaultMessage="Only essential" />
            </Button>
          </div>
        </div>
      </Modal>
      <CookieSettings open={preferencesVisible} onSubmit={handleSettingsOnSubmit} />
    </>
  );
};

export default Cookies;
