import { SelfServiceAPISteps, SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';
import { BreakdownServiceAuthorType, SelfServiceOrigin } from 'modules/selfServices/types/SelfService';

import { isKioskPreferenceQuestionVisible } from 'modules/selfServices/utils';
import { KioskPreferencesQuestionsIds } from 'modules/selfServices/constants';
import {
  KEY,
  SIGNATURE,
  REMOTE_KEY,
  FINAL_INSTRUCTIONS,
  PARKING as COMMON_PARKING,
  QUESTIONS as COMMON_QUESTIONS,
  KIOSK_USAGE as COMMON_KIOSK_USAGE,
  CUSTOMER_INFO as COMMON_CUSTOMER_INFO,
} from './common';

import { isNotAnswered } from './utils';
import BreakdownInfo from '../../BreakdownInfo';
import type { SelfServiceStep } from '../types';

const CUSTOMER_INFO: SelfServiceStep = {
  ...COMMON_CUSTOMER_INFO,
  next: SelfServiceSteps.BREAKDOWN_INFO,
};

const BREAKDOWN_INFO: SelfServiceStep = {
  key: SelfServiceSteps.BREAKDOWN_INFO,
  apiKey: SelfServiceAPISteps.ISSUE_DESCRIPTION,
  component: BreakdownInfo,
  previous: SelfServiceSteps.CUSTOMER_INFO,
  next: SelfServiceSteps.QUESTIONS,
  data: {
    isVisible: ({ status }) => isNotAnswered(status),
  },
};

const QUESTIONS: SelfServiceStep = {
  ...COMMON_QUESTIONS,
  previous: BREAKDOWN_INFO.key,
  next: SelfServiceSteps.KIOSK_USAGE,
};

const KIOSK_USAGE: SelfServiceStep = {
  ...COMMON_KIOSK_USAGE,
  previous: SelfServiceSteps.QUESTIONS,
  next: SelfServiceSteps.PARKING,
  data: {
    isVisible: ({
      type, origin, status, breakdownServiceAuthorType,
    }, config) => {
      const isCheckOutVisible = isKioskPreferenceQuestionVisible(
        KioskPreferencesQuestionsIds.CHECK_OUT,
        type,
        origin,
        config,
      );

      return isNotAnswered(status)
          && isCheckOutVisible
          && breakdownServiceAuthorType === BreakdownServiceAuthorType.CUSTOMER;
    },
  },
};
const PARKING: SelfServiceStep = { ...COMMON_PARKING, previous: SelfServiceSteps.KIOSK_USAGE, next: SIGNATURE.key };

const ON_SITE_BREAKDOWN_STEPS: Record<string, SelfServiceStep> = {
  CUSTOMER_INFO,
  BREAKDOWN_INFO,
  QUESTIONS,
  KIOSK_USAGE,
  PARKING,
  SIGNATURE: {
    ...SIGNATURE,
    previous: PARKING.key,
    next: FINAL_INSTRUCTIONS.key,
    data: { ...SIGNATURE.data, isFinalizeStep: true },
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: SIGNATURE.key },
};

const KIOSK_BREAKDOWN_STEPS: Record<string, SelfServiceStep> = {
  CUSTOMER_INFO,
  BREAKDOWN_INFO,
  QUESTIONS,
  KIOSK_USAGE,
  PARKING,
  SIGNATURE: {
    ...SIGNATURE,
    previous: PARKING.key,
    next: KEY.key,
    data: { ...SIGNATURE.data, isFinalizeStep: true },
  },
  KEY: {
    ...KEY,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
  },
  REMOTE_KEY: {
    ...REMOTE_KEY,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: KEY.key },
};

export default {
  [SelfServiceOrigin.ON_SITE]: ON_SITE_BREAKDOWN_STEPS,
  [SelfServiceOrigin.KIOSK]: KIOSK_BREAKDOWN_STEPS,
};
