import React from 'react';
import Popup from './Popup';

import Warning from '../../assets/icons/warning.svg';

export interface InfoPopupProps {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  content?: React.ReactNode;
  children?: React.ReactNode;
  onClose: (value: boolean) => void;
}

const WARNING_MODAL_DURATION = 8000;

const InfoPopup: React.FC<InfoPopupProps> = ({
  title,
  footer,
  content,
  onClose,
  children,
  icon = <Warning className="absolute size-14 kiosk:size-28" />,
}) => (
  <Popup onClose={onClose} duration={WARNING_MODAL_DURATION} className="!bg-card-bg">
    <div className="flex flex-col items-center m-auto kiosk:mb-52 max-w-4xl sm:max-w-none w-full sm:w-auto sm:mx-4 bg-card-bg rounded-t-2xl sm:rounded-2xl kiosk:rounded-4xl p-8 kiosk:p-20">
      <div className="rounded-full size-28 kiosk:size-52 bg-white relative flex justify-center items-center">
        {icon}
      </div>
      <span className="flex flex-col items-center gap-2.5 kiosk:gap-5 text-center mt-5 kiosk:mt-10">
        {title && <h1>{title}</h1>}
        {content ?? children}
      </span>
      {footer}
    </div>
  </Popup>
);

export default InfoPopup;
