import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../ui/Button';

const SignatureError: React.FC<{ onRetry: () => void }> = ({ onRetry }) => (
  <div className="flex flex-col items-center justify-center gap-3">
    <p className="text-white">
      <FormattedMessage
        id="signature.generateFailure"
        defaultMessage="We are unable to generate your summary"
      />
    </p>
    <Button onClick={onRetry} testId="retry" className="px-8 py-4">
      <FormattedMessage id="retry" defaultMessage="Retry" />
    </Button>
  </div>
);

export default SignatureError;
