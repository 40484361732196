import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { b } from 'utils/i18nUtils';
import { useSelector } from 'hooks';

import PageBaseProps from 'types/PageBase';

import { getMobilityVehicle, getParkingMaps, getSelfServiceStatus } from 'modules/selfServices/selectors';

import Value from 'components/Parking/Value';
import FileList from 'components/ui/FileList';
import ParkingContent from 'components/Parking/ParkingContent';
import { isNotAnswered } from 'components/SelfServices/Steps/utils';

const CourtesyVehicle: React.FC<PageBaseProps> = () => {
  const intl = useIntl();

  const maps = useSelector(getParkingMaps);
  const vehicle = useSelector(getMobilityVehicle);
  const selfServiceStatus = useSelector(getSelfServiceStatus);

  const { medias, parking } = vehicle;

  const isFinalized = React.useMemo(() => !isNotAnswered(selfServiceStatus), [selfServiceStatus]);

  const information = React.useMemo(
    () => [
      {
        translation: {
          id: 'courtesyVehicle.info.description',
          defaultMessage: 'Brand and model',
        },
        value: vehicle.description,
      },
      {
        translation: {
          id: 'login.licensePlate',
          defaultMessage: 'License plate',
        },
        value: vehicle.registration,
      },
      {
        translation: {
          id: 'courtesyVehicle.info.color',
          defaultMessage: 'Color',
        },
        value: vehicle.color,
      },
      {
        translation: {
          id: 'courtesyVehicle.info.fuelLevel',
          defaultMessage: 'Fuel level',
        },
        value: vehicle.fuelLevel && intl.formatMessage({
          id: `fuelLevel.${vehicle.fuelLevel}`,
          defaultMessage: vehicle.fuelLevel.toString(),
        }),
      },
      {
        translation: {
          id: 'courtesyVehicle.info.mileage',
          defaultMessage: 'Plate number',
        },
        value: vehicle.mileage?.toString(),
      },
    ].filter(({ value }) => Boolean(value)),
    [intl, vehicle],
  );
  const emailTranslation = React.useMemo(() => (isFinalized ? {
    id: 'courtesyVehicle.sentEmail',
    defaultMessage: 'This information <b>has been sent to you</b> by email at the end of your check-in.',
  } : {
    id: 'courtesyVehicle.email',
    defaultMessage: 'This information <b>will be sent to you</b> by email at the end of your check-in.',
  }), [isFinalized]);

  return (
    <>
      <p className="text-center text-low">
        <FormattedMessage
          id="courtesyVehicle.description"
          defaultMessage="Information about <b>your courtesy vehicle</b>"
          values={{ b }}
        />
      </p>
      {information.length > 0 && (
        // eslint-disable-next-line max-len
        <div className="flex flex-wrap justify-center w-full bg-card-bg rounded-card p-2 kiosk:rounded-2xl kiosk:p-4 kiosk:mb-10">
          {information.map(({ translation, value }) => (
            <Value
              value={value}
              key={translation.id}
              inputClassName="overflow-ellipsis"
              label={intl.formatMessage(translation)}
            />
          ))}
        </div>
      )}
      {medias?.length > 0 && <FileList medias={medias} className="w-full justify-center kiosk:mb-10" />}
      {parking && (
        <>
          <div className="min-h-[2px] h-[2px] w-10/12 bg-border-default kiosk:mb-10 mx-auto" />
          <ParkingContent
            maps={maps}
            hideInstructions
            title={{
              id: 'courtesyVehicle.parkingTitle',
              defaultMessage: 'Where to find it',
            }}
            {...parking}
          />
        </>
      )}
      <p className="text-center text-low px-10 kiosk:mt-10">
        <FormattedMessage {...emailTranslation} values={{ b }} />
      </p>
    </>
  );
};

export default CourtesyVehicle;
