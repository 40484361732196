import { SOCKET_UPDATE_STEPS } from 'modules/sockets/constants';
import { KioskPreferencesQuestionsIds } from 'modules/selfServices/constants';

import { isKioskPreferenceQuestionVisible } from 'modules/selfServices/utils';

import Flow from 'types/Flow';
import {
  DocumentStatus, SignatureStatus,
} from 'modules/selfServices/types/SelfService';
import SelfServiceStatus from 'modules/selfServices/types/SelfServiceStatus';
import { SelfServiceAPISteps, SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import Extras from 'components/Extras';
import RepairOrder from 'components/RepairOrder';
import MobilityDocument from 'components/Mobility';

import {
  SIGNATURE,
  QUESTIONS as COMMON_QUESTIONS,
  KIOSK_USAGE as COMMON_KIOSK_USAGE,
  CUSTOMER_INFO as COMMON_CUSTOMER_INFO,
  FINAL_INSTRUCTIONS as COMMON_FINAL_INSTRUCTIONS,
} from '../common';
import { isNotAnswered } from '../utils';
import type { SelfServiceStep } from '../../types';

export const CUSTOMER_INFO: SelfServiceStep = {
  ...COMMON_CUSTOMER_INFO,
  next: SelfServiceSteps.REPAIR_ORDER,
};

export const REPAIR_ORDER: SelfServiceStep = {
  key: SelfServiceSteps.REPAIR_ORDER,
  apiKey: SelfServiceAPISteps.REPAIR_ORDER,
  component: RepairOrder,
  title: {
    id: 'repairOrder.title',
    defaultMessage: 'Repair Order',
  },
  previous: CUSTOMER_INFO.key,
  next: SelfServiceSteps.EXTRAS,
  data: {
    socketUpdateFields: SOCKET_UPDATE_STEPS.REPAIR_ORDER,
    isVisible: ({ status }) => isNotAnswered(status),
  },
};

export const EXTRAS: SelfServiceStep = {
  key: SelfServiceSteps.EXTRAS,
  apiKey: SelfServiceAPISteps.EXTRAS_ADDITIONAL_SERVICES,
  component: Extras,
  title: {
    id: 'extras.title',
    defaultMessage: 'Extras',
  },
  previous: REPAIR_ORDER.key,
  next: SelfServiceSteps.QUESTIONS,
  data: {
    socketUpdateFields: SOCKET_UPDATE_STEPS.EXTRAS,
    isVisible: ({ status, checklists }) => isNotAnswered(status)
      && Boolean(checklists?.some(({ items = [] }) => items.some(({ advices = [] }) => advices.length > 0))),
  },
};

export const QUESTIONS: SelfServiceStep = {
  ...COMMON_QUESTIONS,
  previous: EXTRAS.key,
  data: {
    ...COMMON_QUESTIONS.data,
    socketUpdateFields: SOCKET_UPDATE_STEPS.QUESTIONS,
  },
};

export const KIOSK_USAGE: SelfServiceStep = {
  ...COMMON_KIOSK_USAGE,
  data: {
    isVisible: ({ status, type, origin }, config) => {
      const isCheckInVisible = isKioskPreferenceQuestionVisible(
        KioskPreferencesQuestionsIds.CHECK_IN,
        type,
        origin,
        config,
      );
      const isCheckOutVisible = isKioskPreferenceQuestionVisible(
        KioskPreferencesQuestionsIds.CHECK_OUT,
        type,
        origin,
        config,
      );
      return isNotAnswered(status) && (isCheckInVisible || isCheckOutVisible);
    },
  },
};

export const MOBILITY_DOCUMENTS: SelfServiceStep = {
  key: SelfServiceSteps.MOBILITY_DOCUMENTS,
  apiKey: SelfServiceAPISteps.MOBILITY,
  component: MobilityDocument,
  title: {
    id: 'mobilityDocument.title',
    defaultMessage: 'Mobility Documents',
  },
  previous: QUESTIONS.key,
  next: KIOSK_USAGE.key,
  data: {
    // This step is visible if the self-service has mobility enabled and it is not answered or has documents to validate
    isVisible: ({ status, mobility }) => {
      if (mobility) {
        if (isNotAnswered(status)) {
          return true;
        }

        const { documents } = mobility;
        return documents?.some(({ status: documentStatus }) => documentStatus === DocumentStatus.REQUESTED) ?? false;
      }
      return false;
    },
    // When the self-service is answered and has documents to validate this step updates it
    isFinalizeStep: ({ status, mobility }) => status === SelfServiceStatus.ANSWERED
      && (!mobility?.contract || mobility.contract.status === SignatureStatus.SIGNED),
  },
};

export const FINAL_INSTRUCTIONS: SelfServiceStep = {
  ...COMMON_FINAL_INSTRUCTIONS,
  data: {
    ...COMMON_FINAL_INSTRUCTIONS.data,
    socketUpdateFields: SOCKET_UPDATE_STEPS.FINAL_INSTRUCTIONS,
  },
};

export type CommonCheckInSteps =
  SelfServiceSteps.CUSTOMER_INFO
  | SelfServiceSteps.REPAIR_ORDER
  | SelfServiceSteps.EXTRAS;

export const COMMON_CHECKIN_STEPS: Flow<CommonCheckInSteps> = {
  CUSTOMER_INFO,
  REPAIR_ORDER,
  EXTRAS,
};

export type HomeCheckInSteps =
  CommonCheckInSteps
  | SelfServiceSteps.QUESTIONS
  | SelfServiceSteps.MOBILITY_DOCUMENTS
  | SelfServiceSteps.KIOSK_USAGE
  | SelfServiceSteps.SIGNATURE
  | SelfServiceSteps.FINAL_INSTRUCTIONS;

const HOME_CHECK_IN_STEPS: Flow<HomeCheckInSteps> = {
  ...COMMON_CHECKIN_STEPS,
  QUESTIONS: { ...QUESTIONS, next: MOBILITY_DOCUMENTS.key },
  MOBILITY_DOCUMENTS,
  KIOSK_USAGE: { ...KIOSK_USAGE, previous: MOBILITY_DOCUMENTS.key, next: SIGNATURE.key },
  SIGNATURE: {
    ...SIGNATURE,
    apiKey: SelfServiceAPISteps.SUMMARY_SIGNATURE,
    previous: KIOSK_USAGE.key,
    next: FINAL_INSTRUCTIONS.key,
    data: {
      ...SIGNATURE.data,
      isVisible: (selfService, config) => {
        // If the signature is visible in the base step, it should be visible
        const isVisibleBase = SIGNATURE.data.isVisible;
        const isVisibleParent = typeof isVisibleBase === 'function'
          ? isVisibleBase(selfService, config)
          : isVisibleBase;

        // Otherwise, it should be visible if the mobility contract is not signed
        return isVisibleParent || selfService.mobility?.contract?.status === SignatureStatus.UNSIGNED;
      },
      isFinalizeStep: true,
    },
  },
  FINAL_INSTRUCTIONS: {
    ...FINAL_INSTRUCTIONS,
    apiKey: SelfServiceAPISteps.INSTRUCTIONS,
    previous: SIGNATURE.key,
  },
};

export default HOME_CHECK_IN_STEPS;
