import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from 'hooks';

import PageBaseProps from 'types/PageBase';
import { KioskLocation } from 'modules/dealers/types/ContextResponse';

import { logout } from 'modules/auth/actions';
import { openKeysSafe } from 'modules/kiosk/actions';

import { useLazyGetLatestMessageQuery } from 'modules/kiosk/service';

import {
  getKioskId,
  getIsCloseSuccess,
  getSlotIdBySelfServiceId,
  hasError as hasErrorSelector,
} from 'modules/kiosk/selectors';
import { getKioskLocation } from 'modules/dealers/selectors';
import { getSelectedSelfService } from 'modules/selfServices/selectors';

import Modal from 'components/ui/Modal';
import KeysError from 'components/Key/KeysError';
import Button, { ButtonType } from 'components/ui/Button';

import useRemoteKeyTranslations from './useRemoteKeyTranslations';

import shareboxOutdoorKiosk from '../../assets/images/shareboxOutdoorKiosk.png';
import shareboxIndoorKiosk from '../../assets/images/shareboxIndoorKiosk.png';

const RemoteKey: React.FC<PageBaseProps> = ({ onNext }) => {
  const dispatch = useDispatch();

  const [getLatestMessage] = useLazyGetLatestMessageQuery();

  const kioskId = useSelector(getKioskId);
  const hasError = useSelector(hasErrorSelector);
  const isDoorClosed = useSelector(getIsCloseSuccess);
  const kioskLocation = useSelector(getKioskLocation);
  const selfService = useSelector(getSelectedSelfService);
  const slotId = useSelector((state) => getSlotIdBySelfServiceId(state, selfService.id));

  const handleLogout = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleOpenDoor = React.useCallback(() => {
    dispatch(openKeysSafe({ selfServiceId: selfService.id, selfServiceType: selfService.type }));
  }, [dispatch, selfService.id, selfService.type]);

  const handleVisibilityChange = React.useCallback(() => {
    if (!document.hidden) {
      getLatestMessage({ kioskId, slotId, processId: selfService.id });
    }
  }, [getLatestMessage, kioskId, selfService.id, slotId]);

  React.useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [handleVisibilityChange]);

  React.useEffect(() => {
    handleOpenDoor();
  }, [handleOpenDoor]);

  React.useEffect(() => {
    if (isDoorClosed) {
      onNext();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoorClosed]);

  const description = useRemoteKeyTranslations();

  return (
    <>
      <div className="main-content max-h-svh overflow-hidden">
        <div className="h-full flex grow flex-col items-center">
          <div className="content pb-10">
            <p className="text-center mt-5 text-gray-70">
              <FormattedMessage {...description} />
            </p>
          </div>
          <div className="grow flex items-end justify-center w-full ">
            <img
              alt="Sharebox kiosk"
              src={kioskLocation === KioskLocation.OUTDOOR ? shareboxOutdoorKiosk : shareboxIndoorKiosk}
              className=" translate-y-4 landscape:max-w-64 lg:landscape:max-w-full"
            />
          </div>
        </div>
      </div>
      {hasError && (
        <Modal outsideClickClose={false} className="w-full max-w-md kiosk:max-w-3xl" open>
          <KeysError
            onOpen={handleOpenDoor}
            onClose={handleOpenDoor}
            className="[&>img]:hidden gap-0 [&>#keys-modal-actions]:flex-col"
          >
            <Button testId="logOut" className="w-72" type={ButtonType.SECONDARY} onClick={handleLogout}>
              <FormattedMessage id="header.title" defaultMessage="Log out" />
            </Button>
          </KeysError>
        </Modal>
      )}
    </>
  );
};

export default RemoteKey;
