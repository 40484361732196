import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { DEFAULT_ROW_SIZE } from 'components/ui/ExpandableList';
import RepairOrderItem, { RepairOrderItemProps } from 'components/RepairOrder/RepairOrderItem';

import ArrowDirection from 'assets/icons/Arrow';
import Arrow from 'assets/icons/arrow.svg';

type ExpandableTextAreaProps = {
  data: RepairOrderItemProps[];
};

const RepairOrderExpandableList: React.FC<ExpandableTextAreaProps> = ({ data }) => {
  const showCollapseButton = data?.length > DEFAULT_ROW_SIZE;
  const [collapsed, setCollapsed] = React.useState(showCollapseButton);

  const grouped = React.useMemo(
    () => data?.reduce<Record<string, RepairOrderItemProps[]>>((acc, work, index) => {
      const canPush = !collapsed || index < DEFAULT_ROW_SIZE;
      if (canPush) {
        const { workOrderTag = '' } = work;
        return {
          ...acc,
          [workOrderTag]: [...(acc[workOrderTag] ?? []), work],
        };
      }

      return acc;
    }, {}),
    [data, collapsed],
  );

  const handleClick = React.useCallback(() => setCollapsed((prev) => !prev), []);

  return (
    <div className="flex flex-col gap-6 bg-card-bg rounded-card w-full p-2 kiosk:p-2">
      {Object.entries(grouped).map(([workOrderTag, items]) => (
        <div key={workOrderTag} className="flex flex-col gap-3">
          {workOrderTag && (
            <div className="bg-white font-bold kiosk:text-3xl p-2 rounded-md">
              {workOrderTag}
            </div>
          )}
          {items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <RepairOrderItem {...item} className="px-2 border-border-default border-b pb-3 last:border-b-0" key={index} />
          ))}
        </div>
      ))}

      {showCollapseButton && (
        <div onClick={handleClick} data-testid="ExpandCollapseBtn" className="cursor-pointer -mt-6">
          <div className="flex justify-center items-center kiosk:text-2xl kioskSharebox:text-xl">
            {collapsed && <FormattedMessage id="list.viewAll" defaultMessage="View all" />}
            {!collapsed && <FormattedMessage id="list.reduce" defaultMessage="Reduce" />}
            <Arrow className={classNames('size-4 ml-3', collapsed ? [ArrowDirection.DOWN] : [ArrowDirection.UP])} />
          </div>
        </div>
      )}
    </div>
  );
};

export default RepairOrderExpandableList;
