import SelfServiceStatus from 'modules/selfServices/types/SelfServiceStatus';
import { SelfService, SelfServiceOrigin, SelfServiceType } from 'modules/selfServices/types/SelfService';

const RedirectPathConfig = {
  [SelfServiceType.CHECK_IN]: '/checkin',
  [SelfServiceType.CHECK_OUT]: '/checkout',
  [SelfServiceType.BREAKDOWN]: '/breakdown',
};

export const getRedirectUrl = (selfService: SelfService): string => {
  const { origin, type } = selfService;

  const isAnswered = selfService.originContext[origin]?.status === SelfServiceStatus.ANSWERED;
  const hasHomeAnswered = selfService.originContext.HOME?.status === SelfServiceStatus.ANSWERED;
  const shouldGoToSkipPage = !isAnswered
      && hasHomeAnswered
      && type === SelfServiceType.CHECK_IN;
  return shouldGoToSkipPage ? '/resume' : RedirectPathConfig[type];
};

export const shouldOpenFlowTypeModal = (selfService: SelfService) => {
  const isAnswered = selfService.originContext[selfService.origin]?.status === SelfServiceStatus.ANSWERED;
  const hasHomeAnswered = selfService.originContext.HOME?.status === SelfServiceStatus.ANSWERED;
  return (
    selfService.dealer?.allowQuickCheckIn
    && selfService.type === SelfServiceType.CHECK_IN
    && selfService.origin !== SelfServiceOrigin.HOME
    && !hasHomeAnswered
    && !isAnswered
  );
};
