/* eslint-disable quote-props */
import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { createDictionary } from 'utils/objectUtils';
import { getCountry } from 'modules/dealers/selectors';
import { getSelectedLanguage, getTranslations } from 'modules/translations/selectors';

const ISO_LOCALE = {
  en: createDictionary({ us: 'en-us' }, 'en-gb'),
  fr: createDictionary({}, 'fr'),
  de: createDictionary({}, 'de'),
  es: createDictionary({}, 'es'),
  it: createDictionary({}, 'it'),
  nl: createDictionary({}, 'nl'),
  no: createDictionary({}, 'nb-no'),
  sv: createDictionary({}, 'sv-se'),
  pt: createDictionary({}, 'pt'),
  pl: createDictionary({}, 'pl'),
};

const IntlWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const countryCode = useSelector(getCountry);
  const locale = useSelector(getSelectedLanguage);
  const messages = useSelector(getTranslations);
  const isReady = Object.keys(messages).length > 0;

  const userLocaleVariant = React.useMemo(() => ISO_LOCALE[locale][countryCode], [locale, countryCode]);

  return isReady && (
    <IntlProvider messages={messages} locale={userLocaleVariant} defaultLocale={ISO_LOCALE.en[countryCode]}>
      {children}
    </IntlProvider>
  );
};

export default IntlWrapper;
