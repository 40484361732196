import React from 'react';
import classNames, { Argument } from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/solid';

import Spinner from './Spinner';

export type TextareaProps = {
  label?: string;
  value: string;
  rows?: number;
  error?: string;
  loading?: boolean;
  disabled?: boolean;
  required?: boolean;
  onClear?: () => void;
  description?: React.ReactNode;
  className?: Argument;
  icon?: React.ReactNode;
  inputClassName?: Argument;
  iconClassName?: Argument;
  'data-testid'?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({
  label, onChange, value, rows = 5, className, inputClassName, description, required, disabled, loading, icon, onClear, iconClassName, error, 'data-testid': dataTestid, ...rest
}, ref) => {
  const id = React.useId();

  const displayIcon = Boolean(icon || loading || onClear);
  const hasValue = value?.length > 0;

  return (
    <div
      className={classNames('relative z-0 group bg-card-bg rounded-card flex flex-col', className)}
    >
      <textarea
        id={id}
        ref={ref}
        value={value}
        rows={rows}
        placeholder=" "
        onChange={onChange}
        required={required}
        disabled={disabled}
        data-testid={dataTestid}
        className={classNames('textarea focus:outline-none peer', inputClassName, { '!mb-0': description, 'border border-danger-default': error })}
        {...rest}
      />
      {label && <label className="textarea-label" htmlFor={id}>{label}</label>}
      {description && <span className="my-5 text-sm text-low">{description}</span>}
      {displayIcon && (
        <div className={classNames('absolute top-5 right-5 kiosk:top-11 kiosk:right-10', iconClassName)}>
          {loading && <Spinner className="loadingIcon" />}
          {!loading && (
            <>
              {Boolean(onClear) && hasValue && (
                <div
                  onClick={onClear}
                  data-testid="input-clear-btn"
                  // eslint-disable-next-line max-len
                  className="size-7 -mr-1.5 -mt-1.5 kiosk:w-16 kiosk:h-16 kiosk:-mt-4 kiosk:-mr-4 cursor-pointer flex justify-between items-center clearIcon"
                >
                  <XMarkIcon className="m-auto size-3.5 kiosk:w-8 kiosk:h-8" />
                </div>
              )}
              {!hasValue && icon}
            </>
          )}
        </div>
      )}
      {error && (<span className="mb-5 text-danger-default px-1 text-xs kiosk:text-xl" data-testid="error-text">{error}</span>)}
    </div>
  );
});

export default Textarea;
