import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames, { Argument } from 'classnames';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import Modal, { ModalProps } from 'components/ui/Modal';
import Button, { ButtonType } from 'components/ui/Button';

import CheckIcon from 'assets/icons/check.svg';

export enum ProcessingStep {
  INIT,
  PENDING,
  SUCCESS,
  ERROR,
}

export type ProcessingStepConfig = {
  content?: React.ReactNode;
  hideButton?: boolean;
  buttonText?: React.ReactNode;
  wrapperClassName?: Argument;
} & Pick<ModalProps, 'title'>;

export type ProcessingStepModalProps = {
  open?: boolean;
  step?: ProcessingStep;
  config: Partial<Record<ProcessingStep, ProcessingStepConfig>>;
  onClick?: () => void;
};

const ProcessingStepModal: React.FC<ProcessingStepModalProps> = ({
  open, step = ProcessingStep.INIT, config, onClick,
}) => {
  const ref = React.useRef();

  const {
    title, content, hideButton, buttonText, wrapperClassName,
  } = React.useMemo(() => config[step] ?? {}, [config, step]);

  return (
    <Modal
      open={open}
      title={title}
      focusRef={ref}
      className="w-full max-w-md kiosk:max-w-4xl kiosk:pt-36 kiosk:pb-32 kioskSharebox:max-w-2xl"
    >
      <div
        data-testid="processing-modal"
        className={classNames('flex flex-col gap-10 items-center mt-5 kiosk:mt-10 text-center', wrapperClassName)}
      >
        {content}
        {step === ProcessingStep.PENDING && (
          <div className="w-36 kiosk:w-128 kioskSharebox:w-96 aspect-square grid place-items-center relative">
            <div
              className="absolute w-full aspect-square bg-amber-500 opacity-20 rounded-full animate-fleetback-ping"
            />
            <div
              className="z-10 flex justify-center items-center gap-2 kiosk:gap-4 w-5/12 aspect-square rounded-full bg-amber-500"
            >
              <div className="rounded-full min-w-2 w-1/12 aspect-square bg-white" />
              <div className="rounded-full min-w-2 w-1/12 aspect-square bg-white" />
              <div className="rounded-full min-w-2 w-1/12 aspect-square bg-white" />
            </div>
          </div>
        )}
        {step === ProcessingStep.SUCCESS && (
          // eslint-disable-next-line max-len
          <div className="w-36 kiosk:w-128 kioskSharebox:w-96 aspect-square grid place-items-center bg-icon-success/[.20] rounded-full">
            <CheckIcon
              className="fill-white check-icon rounded-full size-2/5 bg-icon-success ring-8 ring-icon-success"
            />
          </div>
        )}
        {step === ProcessingStep.ERROR && (
          // eslint-disable-next-line max-len
          <div className="w-36 kiosk:w-128 kioskSharebox:w-96 aspect-square grid place-items-center bg-error/[.20] rounded-full">
            <ExclamationTriangleIcon className="text-white rounded-full size-2/5 bg-error ring-8 ring-error" />
          </div>
        )}
      </div>
      {!hideButton && (
        <div className="flex flex-col kiosk:flex-row w-full mt-4 kiosk:mt-10">
          <Button
            ref={ref}
            onClick={onClick}
            className="w-full"
            testId="bottom-button-modal"
            type={ButtonType.SECONDARY}
          >
            {buttonText}
            {!buttonText && <FormattedMessage id="cancel" defaultMessage="Cancel" />}
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default ProcessingStepModal;
