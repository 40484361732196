import { SelfServiceOrigin } from 'modules/selfServices/types/SelfService';

import FlowsByOrigin from 'types/FlowsByOrigin';
import KIOSK_BREAKDOWN_STEPS from './kiosk';
import ON_SITE_BREAKDOWN_STEPS from './onSite';

export default {
  [SelfServiceOrigin.ON_SITE]: ON_SITE_BREAKDOWN_STEPS,
  [SelfServiceOrigin.KIOSK]: KIOSK_BREAKDOWN_STEPS,
} as FlowsByOrigin;
