import { SOCKET_UPDATE_STEPS } from 'modules/sockets/constants';

import Flow from 'types/Flow';
import { SignatureStatus } from 'modules/selfServices/types/SelfService';
import { SelfServiceAPISteps, SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import { shouldDisplayMobilityParking } from 'modules/selfServices/selectors';

import Parking from 'components/Parking';
import ParkingInstruction from 'components/Parking/ParkingInstruction';

import type { SelfServiceStep } from '../../types';
import { FINAL_INVOICE, HomeCheckOutSteps, SUMMARY } from './home';
import {
  hasParking, isNotAnswered, hasHiddenSteps, isVisibleStep,
} from '../utils';
import { FINAL_INSTRUCTIONS, SIGNATURE as COMMON_SIGNATURE } from '../common';

export const PARKING: SelfServiceStep = {
  key: SelfServiceSteps.PARKING,
  apiKey: SelfServiceAPISteps.CUSTOMER_CAR_INFO,
  component: ParkingInstruction,
  title: {
    id: 'parking.title',
    defaultMessage: 'Parking',
  },
  previous: FINAL_INVOICE.key,
  data: {
    isVisible: ({ status, parking }) => isNotAnswered(status) && hasParking(parking),
    socketUpdateFields: SOCKET_UPDATE_STEPS.PARKING,
  },
};

export const MOBILITY_PARKING: SelfServiceStep = {
  key: SelfServiceSteps.MOBILITY_PARKING,
  apiKey: SelfServiceAPISteps.COURTESY_CAR_PARKING,
  component: Parking,
  title: (selfService) => {
    const isMobility = shouldDisplayMobilityParking(selfService);
    return {
      id: isMobility ? 'parking.mobility.title' : 'parking.title',
      defaultMessage: isMobility ? 'Courtesy vehicle parking' : 'Parking',
    };
  },
  previous: PARKING.key,
  data: {
    isVisible: ({ status, mobilityEnabled, parkingMap }) => (
      isNotAnswered(status) && mobilityEnabled && (parkingMap?.allowParkingSpot || parkingMap?.maps?.length > 0)
    ),
  },
};

export const SIGNATURE: SelfServiceStep = {
  ...COMMON_SIGNATURE,
  data: {
    ...COMMON_SIGNATURE.data,
    isFinalizeStep: true,
    isVisible: (selfService, config) => {
      const isVisibleParent = isVisibleStep(COMMON_SIGNATURE, selfService, config);
      return isVisibleParent && selfService.mobilityEnabled && selfService.mobility?.deliveryDocument?.status === SignatureStatus.UNSIGNED;
    },
  },
};

export type OnSiteCheckOutSteps =
  HomeCheckOutSteps
  | SelfServiceSteps.PARKING
  | SelfServiceSteps.MOBILITY_PARKING
  | SelfServiceSteps.SIGNATURE;

const ON_SITE_CHECK_OUT_STEPS: Flow<OnSiteCheckOutSteps> = {
  SUMMARY: {
    ...SUMMARY,
    data: {
      ...SUMMARY.data,
      isFinalizeStep: hasHiddenSteps([FINAL_INVOICE, PARKING, MOBILITY_PARKING, SIGNATURE]),
    },
  },
  FINAL_INVOICE: {
    ...FINAL_INVOICE,
    data: {
      ...FINAL_INVOICE.data,
      isFinalizeStep: hasHiddenSteps([PARKING, MOBILITY_PARKING, SIGNATURE]),
    },
  },
  PARKING: {
    ...PARKING,
    next: MOBILITY_PARKING.key,
    data: {
      ...PARKING.data,
      isFinalizeStep: hasHiddenSteps([MOBILITY_PARKING, SIGNATURE]),
    },
  },
  MOBILITY_PARKING: {
    ...MOBILITY_PARKING,
    next: SIGNATURE.key,
    data: {
      ...MOBILITY_PARKING.data,
      isFinalizeStep: hasHiddenSteps([SIGNATURE]),
    },
  },
  SIGNATURE: {
    ...SIGNATURE,
    previous: MOBILITY_PARKING.key,
    next: FINAL_INSTRUCTIONS.key,
    data: {
      ...SIGNATURE.data,
      isFinalizeStep: true,
    },
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: SIGNATURE.key },
};

export default ON_SITE_CHECK_OUT_STEPS;
