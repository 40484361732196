import React from 'react';
import { FormattedMessage } from 'react-intl';

import Label from 'types/Label';
import { Modal } from 'components/ui';
import useLabelTranslation from 'hooks/useLabelTranslation';

import EditIcon from 'assets/icons/edit.svg';

import Prompt from '../Prompt';

export interface ContentProps {
  title: Label | string;
  description: Label | string;
  placeholder?: string;
  editable?: boolean;
  mandatory?: boolean;
  onChangeName?: (value: string) => void;
}

const Content: React.FC<ContentProps> = ({
  title, description, editable, mandatory, placeholder, onChangeName,
}) => {
  const { getLabelTranslation } = useLabelTranslation();

  const displayedTitle = typeof title === 'string' ? title : getLabelTranslation(title);
  const displayedDescription = typeof description === 'string' ? description : getLabelTranslation(description);

  const descriptionRef = React.useRef<HTMLSpanElement>();
  const [isInstructionModalOpen, setInstructionIsModalOpen] = React.useState(false);
  const [isReadMoreVisible, setIsReadMoreVisible] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);

  React.useLayoutEffect(() => {
    const setReadMore = () => {
      setIsReadMoreVisible(descriptionRef.current?.scrollHeight > descriptionRef.current?.clientHeight);
    };

    setReadMore();
    window.addEventListener('resize', setReadMore);
    return () => window.removeEventListener('resize', setReadMore);
  }, []);

  const handleOpenInstructions = React.useCallback(() => {
    setInstructionIsModalOpen(true);
  }, []);

  const handleOpenEditModal = React.useCallback(() => {
    setIsEditModalOpen(true);
  }, []);

  const handleSaveTitle = React.useCallback((name: string) => {
    onChangeName(name);
    setIsEditModalOpen(false);
  }, [onChangeName]);

  return (
    <>
      <div className="flex justify-between mt-4">
        <div className="w-full break-words">
          {mandatory && <span className="text-danger-default mr-1">*</span>}
          {displayedTitle && (
            <span className="text-base font-bold" data-testid="card-title">
              {displayedTitle}
            </span>
          )}
          {!displayedTitle && placeholder && <span className="italic">{placeholder}</span>}
        </div>
        {editable && <span className="cursor-pointer" onClick={handleOpenEditModal} data-testid="edit-title"><EditIcon /></span>}
      </div>
      <div className="flex flex-col">
        <span ref={descriptionRef} className="text-sm line-clamp-2">{displayedDescription}</span>
        {isReadMoreVisible && (
          <span
            className="font-bold underline cursor-pointer text-sm"
            onClick={handleOpenInstructions}
            data-testid="read-more"
          >
            <FormattedMessage id="readMore" defaultMessage="Read more" />
          </span>
        )}
      </div>
      <Modal
        open={isInstructionModalOpen}
        onClose={setInstructionIsModalOpen}
        className="w-full max-w-lg kiosk:max-w-4xl kioskSharebox:max-w-2xl"
        title={<h2>{displayedTitle}</h2>}
      >
        {displayedDescription}
      </Modal>
      {isEditModalOpen && <Prompt value={displayedTitle} placeholder={placeholder} title={<FormattedMessage id="vehicleCheck.editName" defaultMessage="Edit name" tagName="h2" />} onClose={setIsEditModalOpen} onSave={handleSaveTitle} />}
    </>
  );
};

export default Content;
