import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormRenderProps } from 'react-final-form';

import { ExtrasForm as ExtrasFormType } from 'types/ExtrasForm';

import PageFooter from 'components/ui/PageFooter';
import PageBaseProps from 'types/PageBase';
import Extras from './Extras';

const ExtrasFormRender: React.FC<FormRenderProps<ExtrasFormType> & Pick<PageBaseProps, 'onPrev' | 'shouldDisplayBackButton'>> = ({
  handleSubmit, hasValidationErrors, onPrev, shouldDisplayBackButton,
}) => (
  <div className="main-content">
    <p className="text-center text-low">
      <FormattedMessage
        id="extras.indication"
        defaultMessage="Here are some additional services that might interest you."
      />
    </p>
    <div className="content md:grid-cols-2 gap-5 justify-center">
      <Extras />
    </div>
    <PageFooter onNext={handleSubmit} onPrev={onPrev} disabledStyle={hasValidationErrors} shouldDisplayBackButton={shouldDisplayBackButton} />
  </div>
);

export default ExtrasFormRender;
