import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import PageBaseProps from 'types/PageBase';
import FormKeys from 'modules/form/types/FormKeys';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { useGetSelfServiceByIdQuery } from 'modules/selfServices/service';

import RepairOrderForm from './RepairOrderForm';
import ConnectedForm from '../ui/ConnectedForm';

const RepairOrder: React.FC<PageBaseProps> = ({ onNext, onPrev, shouldDisplayBackButton }) => {
  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const { data: selfService } = useGetSelfServiceByIdQuery({ id: selfServiceId });

  return (
    <div className="main-content">
      <p className="text-center text-low">
        {selfService.repairOrder?.length > 0 ? (
          <FormattedMessage
            id="repairOrder.indicationWithRepairOrder"
            // eslint-disable-next-line max-len
            defaultMessage="Please find the subject of your appointment below. You may communicate any remarks/media related to your vehicle."
          />
        ) : (
          <FormattedMessage
            id="repairOrder.indicationWithoutRepairOrder"
            defaultMessage="You may communicate any remarks/media related to your vehicle."
          />
        )}
      </p>
      <ConnectedForm
        onPrev={onPrev}
        subscription={{}}
        onSubmit={onNext}
        keepDirtyOnReinitialize
        component={RepairOrderForm}
        formKey={FormKeys.REPAIR_ORDER}
        shouldDisplayBackButton={shouldDisplayBackButton}
      />
    </div>
  );
};

export default RepairOrder;
