import React from 'react';
import { FormattedMessage } from 'react-intl';

import Button, { ButtonType } from 'components/ui/Button';

import Note from 'assets/icons/note.svg';
import CheckmarkCircle from 'assets/icons/checkmarkCircle.svg';

import { SignDocument } from './types';

const Document: React.FC<SignDocument & { onClick: (id: string) => () => void }> = ({
  id, read, filename, onClick,
}) => (
  <div className="p-3 mb-3 kiosk:p-6 rounded-lg items-center flex cursor-pointer bg-input-bg gap-3 kiosk:gap-6">
    <div className="flex-initial">
      {read && (
        <CheckmarkCircle
          className="fill-success-default size-8 kiosk:size-16"
          data-testid="read-success"
        />
      )}
      {!read && (
        <Note
          className="fill-default size-8 kiosk:size-16"
          data-testid="read-not-done"
        />
      )}
    </div>
    <div className="grow w-full">
      <p className="text-default font-medium line-clamp-2">
        {filename}
      </p>
    </div>
    <Button type={read ? ButtonType.PRIMARY : ButtonType.WHITE} testId={`document-${id}`} onClick={onClick(id)}>
      <FormattedMessage id="consult" defaultMessage="Consult" />
    </Button>
  </div>
);

export default Document;
