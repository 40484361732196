import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';

import {
  getCourtesyVehicleAvailabilityDate,
  shouldDisplayPhoneNumbers as shouldDisplayPhoneNumbersSelector,
} from 'modules/dealers/selectors';

import { InfoPopupProps } from 'components/ui/InfoPopup';
import DealersPhone from 'components/ui/DealersPhone';
import { ErrorType } from '../types';

export interface ErrorContentConfig {
  availabilityDate: string;
  showPhoneNumber: boolean;
}

const useErrorConfig = (): Record<string, Omit<InfoPopupProps, 'onClose'>> => {
  const availabilityDate = useSelector(getCourtesyVehicleAvailabilityDate);
  const shouldDisplayPhoneNumbers = useSelector(shouldDisplayPhoneNumbersSelector);

  return React.useMemo(
    () => ({
      [ErrorType.KIOSK_FULL]: {
        content: (
          <FormattedMessage
            id="kiosk.fullWarning"
            defaultMessage="The kiosk is full. It is no longer possible to drop keys."
          />
        ),
      },
      [ErrorType.KIOSK_ERROR]: {
        title: <FormattedMessage id="home.emergency.title" defaultMessage="Issues with the kiosk" />,
        content: (
          <FormattedMessage
            id="home.emergency.description"
            defaultMessage="We are currently experiencing an issue with the kiosk, please visit the dealership or contact us via the number below."
          />
        ),
        footer: shouldDisplayPhoneNumbers && <DealersPhone />,
      },
      [ErrorType.COURTESY_UNAVAILABLE]: {
        content: (
          <FormattedMessage
            id="error.types.SO_COURTESY_VEHICLE_NOT_AVAILABLE"
            defaultMessage="Your appointment is not ready yet, please try again from {availabilityDate}."
            values={{ availabilityDate: <FormattedDate value={availabilityDate} /> }}
          />
        ),
      },
    }),
    [availabilityDate, shouldDisplayPhoneNumbers],
  );
};

export default useErrorConfig;
