import React from 'react';

import { Vendor } from 'types/KioskVendors';

import { getUrlParam } from 'utils/urlUtils';
import { openOfflineMode } from 'service/apiElectron';

import { getKioskVendor, isLocalKiosk } from 'modules/dealers/selectors';

import { useSelector } from '../../hooks';

const ShareboxEmergencyAccess = () => {
  const timer = React.useRef<NodeJS.Timeout>();

  const isKiosk = useSelector(isLocalKiosk);
  const vendor = useSelector(getKioskVendor);
  const outdoorShareboxToken = getUrlParam('OSToken');

  const displayShareboxEmergencyAccess = (isKiosk && vendor === Vendor.SHAREBOX) || Boolean(outdoorShareboxToken);

  const handleTouchEnd = () => {
    clearTimeout(timer.current);
  };

  const handleTouchStart = () => {
    timer.current = setTimeout(() => {
      openOfflineMode();
    }, 5000);
  };

  return displayShareboxEmergencyAccess && (
    <div
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      className="absolute z-max w-24 h-24"
      data-testid="sharebox-emergency-access"
    />
  );
};

export default ShareboxEmergencyAccess;
