import { SelfServiceOrigin } from 'modules/selfServices/types/SelfService';

import HOME_CHECK_OUT_STEPS from './home';
import ON_SITE_CHECK_OUT_STEPS from './onSite';
import DEFAULT_KIOSK_CHECK_OUT_STEPS from './kiosk';

export default {
  [SelfServiceOrigin.HOME]: HOME_CHECK_OUT_STEPS,
  [SelfServiceOrigin.ON_SITE]: ON_SITE_CHECK_OUT_STEPS,
  [SelfServiceOrigin.KIOSK]: DEFAULT_KIOSK_CHECK_OUT_STEPS,
};
