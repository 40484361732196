/* eslint-disable import/no-extraneous-dependencies */
import ReactGA from 'react-ga4';
import { lookup } from 'mime-types';
import * as Sentry from '@sentry/react';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

import { MediaType } from 'types/AbstractMedia';
import { ApiErrorResponse, isApiErrorResponse } from 'types/Error';
import { VehicleCheckType } from 'modules/vehicleCheck/types/VehicleCheckModel';
import { SelfService, SelfServiceType } from 'modules/selfServices/types/SelfService';
import { PutSelfServiceRequest } from 'modules/selfServices/types/PutSelfServiceRequest';
import { FinalInstructionsRequest } from 'modules/dealers/types/FinalInstructionsRequest';
import { PutCustomerInfoRequest } from 'modules/selfServices/types/PutCustomerInfoRequest';
import { FinalInstructionsResponse } from 'modules/dealers/types/FinalInstructionsResponse';
import type { GetSelfServiceRequest } from 'modules/selfServices/types/GetSelfServiceRequest';
import { VehicleCheckDecisionRequest } from 'modules/selfServices/types/VehicleCheckDecisionRequest';

import api from 'service/index';
import { br } from 'utils/i18nUtils';
import { RootState } from 'App/Store';
import { SELF_SERVICE } from 'constants/url';
import socketSlice from 'modules/sockets/reducer';
import { downloadBase64 } from 'utils/downloadUtils';

import { addNotification } from 'modules/notifications/actions';

import { isLocalKiosk } from 'modules/dealers/selectors';
import { canCollectAnalyticsData } from 'modules/auth/selectors';

import { generateKioskPreferencesQuestions } from './utils';

import { PaymentMethod } from './types/PaymentMethod';
import { CreatePdfPreviewRequest } from './types/CreatePdfPreview';
import { GetPaymentMethodsRequest } from './types/GetPaymentMethods';
import { NotificationType } from '../notifications/types/Notification';
import type { PayFinalInvoiceResponse } from './types/ProceedToPayment';

// API can't handle difference between IMAGE and DOCUMENT types in finalInvoice
// So we have to do it here so the media are displayed correctly
const differentiateMedias = (selfService: SelfService) => {
  if (selfService?.finalInvoice?.medias) {
    const medias = selfService.finalInvoice.medias.map((media) => {
      if (media.type === MediaType.DOCUMENT) {
        const contentType = media.contentType || lookup(media.filename) || '';
        const isImage = contentType.startsWith('image');
        if (isImage) {
          return {
            ...media,
            type: MediaType.IMAGE,
          };
        }
      }
      return media;
    });

    return { ...selfService, finalInvoice: { ...selfService.finalInvoice, medias } };
  }
  return selfService;
};

const selfServicesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSelfServiceById: build.query<SelfService, GetSelfServiceRequest>({
      transformResponse: (selfService: SelfService) => {
        const formattedSelfService = differentiateMedias(selfService);

        if (selfService.type === SelfServiceType.CHECK_IN || selfService.type === SelfServiceType.BREAKDOWN) {
          formattedSelfService.kioskPreferencesQuestions = generateKioskPreferencesQuestions(selfService);
        }
        return formattedSelfService;
      },
      query: ({ id, params }) => ({
        method: 'GET',
        url: `${SELF_SERVICE}/${id}`,
        params,
      }),

    }),
    updateSelfService: build.mutation<SelfService, PutSelfServiceRequest>({
      queryFn: async (
        { id, body },
        _,
        __,
        baseQuery,
      ): Promise<QueryReturnValue<SelfService, ApiErrorResponse, FetchBaseQueryMeta>> => {
        const selfService = { ...body, medias: body.medias?.map(({ path, ...media }) => media) };
        return (await baseQuery({
          method: 'PUT',
          url: `${SELF_SERVICE}/${id}`,
          body: selfService,
        })) as QueryReturnValue<SelfService, ApiErrorResponse, FetchBaseQueryMeta>;
      },
      async onQueryStarted({ body }, { dispatch, queryFulfilled, getState }) {
        const canUseGA = canCollectAnalyticsData(getState() as RootState);

        try {
          const { data: selfService } = await queryFulfilled;

          body.medias
            ?.filter((media) => media.path?.toString().startsWith('blob:'))
            .forEach((media) => URL.revokeObjectURL(media.path));

          if (canUseGA) {
            ReactGA.event({
              category: 'End',
              action: `${selfService.origin} ${selfService.type} completed`,
            });
          }
        } catch ({ error: queryError }) {
          let errorDescription = {
            id: 'signature.error.description',
            defaultMessage: 'We could not send your signature. Please try again later or contact us.',
          };

          if (isApiErrorResponse(queryError) && queryError.data.errorType) {
            errorDescription = {
              id: `error.types.${queryError.data.errorType}`,
              defaultMessage: queryError.data.errorMessage,
            };
          }

          dispatch(
            addNotification({
              type: NotificationType.ERROR,
              title: {
                id: 'signature.error.title',
                defaultMessage: 'An error occured while signing',
              },
              description: errorDescription,
            }),
          );
        }
      },
    }),
    proceedToPayment: build.mutation<PayFinalInvoiceResponse, { id: string }>({
      query: ({ id }) => ({
        method: 'POST',
        url: `${SELF_SERVICE}/${id}/payFinalInvoice`,
        body: {
          returnPath: window.location.pathname,
        },
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch, getState }) {
        try {
          const { data: { redirectUrl } } = await queryFulfilled;

          const isKiosk = isLocalKiosk(getState() as RootState);

          if (!isKiosk) {
            if (redirectUrl) {
              window.location.assign(redirectUrl);
            }
          } else {
            dispatch(socketSlice.actions.openInstructionModal());
          }
        } catch (error) {
          dispatch(
            addNotification({
              title: {
                id: 'page.finalInvoice.payment.error.title',
                defaultMessage: 'An error occur during the payment process',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
    sendPayingLink: build.mutation<null, { id: string }>({
      query: ({ id }) => ({
        method: 'POST',
        url: `${SELF_SERVICE}/${id}/sendFinalInvoicePaymentUrl`,
        body: {
          returnPath: window.location.pathname,
        },
      }),
    }),
    getSelfServicePdf: build.mutation<void, { id: string; filename: string }>({
      queryFn: async ({ id, filename }, { dispatch }, __, baseQuery) => {
        try {
          const { data, ...query } = (await baseQuery({
            method: 'GET',
            url: `${SELF_SERVICE}/${id}/pdf`,
            responseHandler: 'text',
          })) as QueryReturnValue<string, FetchBaseQueryError, FetchBaseQueryMeta>;

          if (data) {
            downloadBase64(data, 'application/pdf', filename);
          }

          return { data: undefined, ...query }; // We don't want to persist the file in the store
        } catch (error) {
          dispatch(
            addNotification({
              title: {
                id: 'page.finalInstructions.downloadPdf.error.title',
                defaultMessage: 'An error occur while downloading the PDF',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
          return error;
        }
      },
    }),
    updateCustomerInfo: build.mutation<SelfService, PutCustomerInfoRequest>({
      query: ({ id, body, params }) => ({
        method: 'PUT',
        url: `${SELF_SERVICE}/${id}/intervention/customer`,
        params,
        body,
      }),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'page.contactInfo.updateCustomerError.title',
                defaultMessage: 'An error occurred while updating your contact information.',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
    getFinalInstructions: build.query<FinalInstructionsResponse, FinalInstructionsRequest>({
      query: ({ selfServiceId }) => ({
        method: 'GET',
        url: `${SELF_SERVICE}/${selfServiceId}/finalInstruction`,
      }),
    }),
    patchVehicleCheckDecision: build.mutation<SelfService, VehicleCheckDecisionRequest>({
      query: ({ selfServiceId, accepted, type }) => {
        const suffix = type && type !== VehicleCheckType.PERSONAL ? `/${type.toLowerCase()}` : '';
        return ({
          method: 'PATCH',
          url: `${SELF_SERVICE}/${selfServiceId}/vehicleCheck${suffix}/decision`,
          body: {
            accepted,
          },
        });
      },
    }),
    createPreviewPDF: build.query<string, CreatePdfPreviewRequest>({
      keepUnusedDataFor: 0,
      query: ({ id, body }) => ({
        method: 'POST',
        url: `${SELF_SERVICE}/${id}/pdf/preview`,
        body,
        headers: {
          accept: 'application/pdf',
        },
        responseHandler: (response) => response.blob(),
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          Sentry.captureException(e);
        }
      },
      transformResponse: (response: Blob) => URL.createObjectURL(response),
    }),
    getPaymentMethods: build.query<PaymentMethod, GetPaymentMethodsRequest>({
      query: ({ selfServiceId }) => ({
        method: 'GET',
        url: `${SELF_SERVICE}/${selfServiceId}/paymentMethods`,
      }),
    }),
  }),

});

export const {
  useSendPayingLinkMutation,
  useGetSelfServiceByIdQuery,
  useUpdateSelfServiceMutation,
  useGetSelfServicePdfMutation,
  useUpdateCustomerInfoMutation,
  useProceedToPaymentMutation,
  useGetFinalInstructionsQuery,
  usePatchVehicleCheckDecisionMutation,
  useLazyGetSelfServiceByIdQuery,
  useCreatePreviewPDFQuery,
  useLazyCreatePreviewPDFQuery,
  useGetPaymentMethodsQuery,
} = selfServicesApi;

export default selfServicesApi;
