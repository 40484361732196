import React from 'react';
import { useSelector } from 'react-redux';
import { MessageDescriptor } from 'react-intl';

import { KeyStatus } from 'modules/kiosk/types/Kiosk';
import { KioskLocation } from 'modules/dealers/types/ContextResponse';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import { createDictionary } from 'utils/objectUtils';

import { getKioskLocation } from 'modules/dealers/selectors';
import { getSelectedSelfService } from 'modules/selfServices/selectors';

/* eslint-disable max-len */
export const DEFAULT_MESSAGES = {
  [`mobility_${KioskLocation.INDOOR}`]: 'Swap the vehicle keys and close the door.',
  [`mobility_${KioskLocation.OUTDOOR}`]: 'You can open the kiosk by clicking the green blinking button. Swap the vehicle keys and click again to close the door.',
  [`${SelfServiceType.CHECK_IN}_${KioskLocation.INDOOR}`]: 'Place the keys inside and close the door.',
  [`${SelfServiceType.CHECK_IN}_${KioskLocation.OUTDOOR}`]: 'You can open the kiosk by clicking the green blinking button. Place the keys inside and click again to close the door.',
  [`${SelfServiceType.CHECK_OUT}_${KioskLocation.INDOOR}`]: 'Retrieve the keys and close the door.',
  [`${SelfServiceType.CHECK_OUT}_${KioskLocation.OUTDOOR}`]: 'You can open the kiosk by clicking the green blinking button. Retrieve the keys inside and click again to close the door.',
};
/* eslint-enable max-len */

type UseRemoteKeyTranslationsParams = {
  path?: string;
  defaultMessages?: Record<string, string>;
};

const useRemoteKeyTranslations = ({
  path = 'remoteKey',
  defaultMessages: defaultMessagesProp = DEFAULT_MESSAGES,
}: UseRemoteKeyTranslationsParams = {}): MessageDescriptor => {
  const kioskLocation = useSelector(getKioskLocation);
  const selfService = useSelector(getSelectedSelfService);

  const type = selfService.type === SelfServiceType.BREAKDOWN ? SelfServiceType.CHECK_IN : selfService.type;
  const mobilityEnabled = selfService.mobilityEnabled && (
    selfService.type !== SelfServiceType.CHECK_IN || selfService.keyStatus === KeyStatus.IN_SLOT
  );

  return React.useMemo(() => {
    const defaultMessages = createDictionary<string>(defaultMessagesProp, '');

    if (mobilityEnabled) {
      return {
        id: `${path}.mobility.${kioskLocation}.indication`,
        defaultMessage: defaultMessages[`mobility_${kioskLocation}`],
      };
    }

    return {
      id: `${path}.${type}.${kioskLocation}.indication`,
      defaultMessage: defaultMessages[`${type}_${kioskLocation}`],
    };
  }, [path, type, mobilityEnabled, kioskLocation, defaultMessagesProp]);
};

export default useRemoteKeyTranslations;
