import React from 'react';
import classNames, { Argument } from 'classnames';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';

import ArrowDirection from 'assets/icons/Arrow';
import { AbstractMedia, MediaType } from 'types/AbstractMedia';

import { downloadURL } from 'utils/downloadUtils';

import Arrow from 'assets/icons/arrow.svg';
import DownloadIcon from 'assets/icons/download.svg';

import { createPortal } from 'react-dom';
import StepIndicator from '../StepIndicator';
import { DEFAULT_MEDIA_COMPONENTS } from '../File';
import Spinner from '../Spinner';

export interface CarouselProps {
  className?: Argument;
  medias: Array<AbstractMedia>;
  error?: React.ReactNode;
  loading?: boolean;
  footer?: React.ReactElement;
  onClose?: () => void;
  fullscreen?: boolean;
  hideActions?: boolean;
  hideCursor?: boolean;
  hideNavigationArrow?: boolean;
  showDownload?: boolean;
  defaultMediaIndex?: number;
  onDelete?: (media: AbstractMedia) => void;
  onMediaClick?: (media: AbstractMedia) => void;
  onPageChange?: (pageNumber: number, totalPages: number) => void;
  watermarkTitle?: string;
}

const MIN_SWIPE_DISTANCE = 75;

const getCarouselStyle = (isFullscreen: boolean) => ({
  wrapper: isFullscreen
    ? 'fixed inset-0 h-svh w-svw bg-default overflow-hidden z-60 flex flex-col'
    : 'overflow-hidden relative w-full flex items-center',
  subWrapper: isFullscreen ? 'w-full grow overflow-y-auto' : 'mx-auto w-full',
  subSubWrapper: `aspect-video relative overflow-hidden ${isFullscreen ? 'flex size-full justify-center' : ''}`,
  media: `absolute ease-in duration-300 inset-0 ${isFullscreen ? 'flex items-center justify-center flex-col' : ''}`,
  // eslint-disable-next-line max-len
  arrow: `flex items-center justify-center rounded-full p-3 absolute top-1/2 z-10 -mt-5 backdrop-blur-sm overflow-hidden duration-500 cursor-pointer  ${isFullscreen ? 'bg-black/70 hover:bg-black/90 fill-white size-12 ' : 'size-10 bg-white/70 hover:bg-white/90 fill-default'}`,
});

const Carousel: React.FC<CarouselProps> = ({
  error,
  medias,
  footer,
  loading,
  onClose,
  onDelete,
  className,
  fullscreen,
  hideCursor,
  hideActions,
  onMediaClick,
  onPageChange,
  showDownload,
  watermarkTitle,
  defaultMediaIndex,
  hideNavigationArrow,
}) => {
  const touchPosition = React.useRef<number>();

  const [currentIndex, setCurrentIndex] = React.useState(() => (defaultMediaIndex > -1 ? defaultMediaIndex : 0));

  React.useEffect(() => {
    if (defaultMediaIndex >= 0) {
      setCurrentIndex(defaultMediaIndex);
    }
  }, [defaultMediaIndex]);

  const onPrev = React.useCallback(() => setCurrentIndex((index) => Math.max(0, index - 1)), []);
  const onNext = React.useCallback(
    () => setCurrentIndex((index) => Math.min(medias.length - 1, index + 1)),
    [medias.length],
  );

  const handleTouchStart = React.useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    touchPosition.current = event.targetTouches[0].clientX;
  }, []);

  const handleTouchEnd = React.useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    if (touchPosition.current) {
      const x = event.changedTouches[0].clientX;
      if (Math.abs(x - touchPosition.current) > MIN_SWIPE_DISTANCE) {
        if (x < touchPosition.current) {
          onNext();
        } else if (x > touchPosition.current) {
          onPrev();
        }
      }
    }
  }, [onNext, onPrev]);

  const handleDelete = React.useCallback(() => {
    setCurrentIndex((index) => {
      onDelete(medias[index]);
      return index === medias.length - 1 ? Math.max(0, medias.length - 2) : index;
    });
  }, [onDelete, medias]);

  const handleDownload = React.useCallback(() => {
    const { path, filename } = medias[currentIndex];
    downloadURL(path, filename);
  }, [medias, currentIndex]);

  const handleMediaClick = React.useCallback(
    () => onMediaClick?.(medias[currentIndex]),
    [onMediaClick, medias, currentIndex],
  );

  const handleOnSeeking = React.useCallback(() => {
    touchPosition.current = null;
  }, []);

  const showActions = !hideActions && typeof onDelete === 'function';
  const displayFooter = fullscreen && (showActions || Boolean(footer));
  const showStepIndicator = !fullscreen && medias.length > 1;

  const styles = React.useMemo(() => getCarouselStyle(fullscreen), [fullscreen]);

  const displayCursor = React.useMemo(
    () => !hideCursor && !fullscreen && onMediaClick && medias.length > 1,
    [fullscreen, hideCursor, medias.length, onMediaClick],
  );

  const media = React.useMemo(() => medias[currentIndex], [currentIndex, medias]);

  // This allows the carousel to be rendered *over* a modal
  const portalFn = React.useMemo(
    () => (
      fullscreen
        ? (children: React.ReactNode) => createPortal(children, document.body)
        : (children: React.ReactNode) => children
    ),
    [fullscreen],
  );

  React.useEffect(() => {
    // Prevent to scroll in the page when you display a media in fullscreen
    const action = fullscreen ? 'add' : 'remove';
    document.body.classList[action]('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [fullscreen]);

  React.useEffect(() => {
    // Fallback
    const hasBeenRemoved = currentIndex > 0 && currentIndex >= medias.length;
    if (hasBeenRemoved) {
      setCurrentIndex(medias.length - 1);
    }
  }, [setCurrentIndex, currentIndex, medias.length]);

  const displayError = React.useMemo(() => !loading && Boolean(error), [error, loading]);
  const displayMedia = React.useMemo(() => !loading && !error && medias.length > 0 && media, [error, loading, media, medias.length]);

  return portalFn(
    <div className={styles.wrapper}>
      {fullscreen && media && (
        <div className="flex justify-between gap-2 z-10 px-4 left-0 shrink-0 h-20 kiosk:h-36 kioskSharebox:h-28 bg-default w-full text-white items-center backdrop-blur-sm">
          <div className="size-7 kiosk:size-14 kioskSharebox:size-10">
            {showDownload && (
              <DownloadIcon
                onClick={handleDownload}
                className="cursor-pointer fill-white"
                data-testid="download-media-carousel"
              />
            )}
          </div>
          {media.type === MediaType.DOCUMENT && (
            <span data-testid="filename-carousel" className="truncate text-center flex-grow font-bold text-base kiosk:text-3xl kioskSharebox:text-2xl">
              {media.filename}
            </span>
          )}
          <div className="size-7 kiosk:size-14 kioskSharebox:size-10">
            <XMarkIcon
              onClick={onClose}
              data-testid="close-carousel"
              className="rounded-full cursor-pointer"
            />
          </div>
        </div>
      )}
      <div
        data-testid="carousel"
        onTouchEnd={handleTouchEnd}
        className={styles.subWrapper}
        onTouchStart={handleTouchStart}
      >
        <div className={styles.subSubWrapper}>
          {!hideNavigationArrow && currentIndex > 0 && (
            <Arrow
              onClick={onPrev}
              data-testid="carousel-leftArrow"
              className={classNames(styles.arrow, 'left-3', ArrowDirection.LEFT)}
            />
          )}
          {!hideNavigationArrow && currentIndex < medias.length - 1 && (
            <Arrow
              onClick={onNext}
              data-testid="carousel-rightArrow"
              className={classNames(styles.arrow, 'right-3')}
            />
          )}
          <div
            onClick={handleMediaClick}
            data-testid="carousel-inner"
            className={classNames(
              'relative overflow-hidden aspect-video scroll-smooth z-0 w-full flex items-center justify-center',
              { 'cursor-pointer': displayCursor },
              className,
            )}
          >
            {loading && <Spinner />}
            {displayError && error}
            {displayMedia && (
              <div
                key={media.uploadId ?? media.path}
                className={styles.media}
              >
                {DEFAULT_MEDIA_COMPONENTS[media.type]?.(media, {
                  thumbnail: !fullscreen, onPageChange, watermarkTitle, onSeeking: handleOnSeeking,
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      {displayFooter && (
        <div className="px-4 flex justify-center shrink-0 h-24 kiosk:h-44 kioskSharebox:h-40 w-full text-white bg-default backdrop-blur-sm">
          {onDelete && (
            <TrashIcon
              onClick={handleDelete}
              className="cursor-pointer fill-white"
              data-testid="delete-media-carousel"
            />
          )}
          {footer}
        </div>
      )}
      {showStepIndicator && (
        <StepIndicator
          currentIndex={currentIndex}
          length={medias.length}
          data-testid="steps-carousel"
          className="absolute bottom-0 pt-5 pb-3 bg-gradient-to-t from-slate-900/40 flex justify-center justify-items-center items-center w-full"
        />
      )}
    </div>,
  );
};

export default Carousel;
