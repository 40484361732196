import React from 'react';
import { useSelector } from 'react-redux';

import {
  getKioskWarningErrorType,
} from 'modules/dealers/selectors';

import InfoPopup from 'components/ui/InfoPopup';
import useErrorConfig from './hooks/useErrorConfig';

interface KioskWarningModalProps {
  onClose?: (value: boolean) => void;
}

const KioskWarningModal: React.FC<KioskWarningModalProps> = ({ onClose }) => {
  const errorKey = useSelector(getKioskWarningErrorType);
  const { [errorKey]: errorContent } = useErrorConfig();

  return errorContent && <InfoPopup {...errorContent} onClose={onClose} />;
};

export default KioskWarningModal;
