import React from 'react';
import classNames from 'classnames';

import useAnalytics, { Analytics } from 'hooks/useAnalytics';
import Spinner from './Spinner';

export enum ButtonType {
  /* eslint-disable max-len */
  PRIMARY = 'bg-btn-primary-bg text-btn-primary-text border-btn-primary-size hover:bg-btn-primary-hover-bg disabled:text-btn-primary-disabled-text disabled:bg-btn-primary-disabled-bg disabledStyle:bg-btn-primary-disabled-bg disabledStyle:text-btn-primary-disabled-text',

  SECONDARY = 'bg-btn-secondary-bg text-btn-secondary-text border-btn-secondary-size border-btn-secondary-border hover:bg-btn-secondary-hover-bg hover:border-btn-secondary-hover-border disabled:bg-btn-secondary-disabled-bg disabledStyle:bg-btn-secondary-disabled-bg disabled:border-btn-secondary-disabled-border disabled:text-btn-secondary-disabled-text disabledStyle:text-btn-secondary-disabled-text disabledStyle:border-btn-secondary-disabled-border',

  TERTIARY = 'bg-btn-tertiary-bg text-btn-tertiary-text hover:bg-btn-tertiary-hover-bg disabled:bg-btn-tertiary-disabled-bg disabledStyle:bg-btn-tertiary-disabled-bg',

  WHITE = 'bg-white text-default hover:border-border-default focus:shadow-focus focus:hover:shadow-none disabled:bg-white/50 disabled:hover:text-default outline-tertiary/10 disabledStyle:bg-white/50 disabledStyle:hover:text-default outline-tertiary/10',
  DENY = 'bg-default text-white hover:default/90 focus:bg-default/80 focus:shadow-focus focus:hover:shadow-none',
  LIGHT = 'bg-transparent text-default hover:underline hover:underline-offset-1 focus:underline focus:outline-0',
  ERROR = 'bg-danger-default text-white hover:bg-btn-error-hover-bg disabled:bg-btn-error-disabled-bg disabledStyle:bg-btn-error-disabled-bg focus:ring-btn-error-disabled-bg',
  SUCCESS = 'bg-success-default text-white hover:bg-success-darken-15 disabled:bg-success-lighten-60 disabledStyle:bg-success-lighten-60',
  /* eslint-enable max-len */
}

export interface ButtonProps {
  type?: ButtonType;
  submit?: boolean;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  testId?: string;
  disabledStyle?: boolean;
  analytics?: Analytics;
}

const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>((
  {
    type = ButtonType.TERTIARY,
    submit,
    disabled: disabledProp,
    loading,
    onClick: onClickDefault,
    className,
    children,
    testId,
    disabledStyle,
    analytics,
  },
  ref,
) => {
  const { onClick } = useAnalytics();

  const disabled = disabledProp || loading;

  return (
    <button
      ref={ref}
      onClick={onClick(analytics, onClickDefault)}
      data-testid={testId}
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      className={classNames(
        { 'cursor-not-allowed': disabled },
        // eslint-disable-next-line max-len
        'rounded-button font-btn-weight py-4 px-7 button transform transition duration-500 p-4 kiosk:p-9 kioskSharebox:p-5 text-base kiosk:text-2xl kioskSharebox:text-xl drop-shadow-none',
        type,
        className,
      )}
      data-fake-disabled={disabledStyle}
    >
      {loading && (<Spinner className={classNames({ '!text-white/30': type === ButtonType.TERTIARY })} />)}
      {!loading && children}
    </button>
  );
});

export default Button;
