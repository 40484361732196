import Flow from 'types/Flow';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import {
  FINAL_INSTRUCTIONS, SHAREBOX_KEY, KIOSK_LOCATION, SIGNATURE,
} from 'components/SelfServices/Steps/common';

import { VEHICLE_CHECK, VEHICLE_CHECK_QR_CODE } from '../../onSite';
import COMMON_KIOSK_CHECK_IN_STEPS, { CommonKioskCheckInSteps } from '../common';

export type ShareboxS32CheckInSteps =
  CommonKioskCheckInSteps
  | SelfServiceSteps.SIGNATURE
  | SelfServiceSteps.KIOSK_LOCATION
  | SelfServiceSteps.VEHICLE_CHECK_QR_CODE
  | SelfServiceSteps.FINAL_INSTRUCTIONS;

// Alias 'OUTDOOR' flow
const SHAREBOX_S32_CHECK_IN_STEPS: Flow<ShareboxS32CheckInSteps> = {
  ...COMMON_KIOSK_CHECK_IN_STEPS,
  SIGNATURE: {
    ...SIGNATURE,
    previous: VEHICLE_CHECK.key,
    next: SHAREBOX_KEY.key,
    data: {
      ...SIGNATURE.data,
      isFinalizeStep: true,
    },
  },
  KIOSK_LOCATION: {
    ...KIOSK_LOCATION,
    key: SHAREBOX_KEY.key,
    previous: SIGNATURE.key,
    next: VEHICLE_CHECK_QR_CODE.key,
  },
  VEHICLE_CHECK_QR_CODE: { ...VEHICLE_CHECK_QR_CODE, previous: SHAREBOX_KEY.key, next: FINAL_INSTRUCTIONS.key },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: VEHICLE_CHECK_QR_CODE.key },
};

export default SHAREBOX_S32_CHECK_IN_STEPS;
