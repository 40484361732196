import React from 'react';

import { Vendor } from 'types/KioskVendors';

import { useSelector } from 'hooks';
import { useCheckQRCodeTokenMutation } from 'modules/auth/service';

import { getKioskId } from 'modules/kiosk/selectors';
import { getKioskVendor } from 'modules/dealers/selectors';

import QRCodeScannerDevice from '../models/QRCodeScannerDevice';

/**
 * This custom hook works only on Kiosk with Edge browser, Edge policy "WebHidAllowDevicesForUrls" enabled and in HTTPS.
 * @see https://fleetback.atlassian.net/wiki/x/WhcdAQ
 */
const useQrCodeScanner = () => {
  const kioskId = useSelector(getKioskId);
  const vendor = useSelector(getKioskVendor);

  const [login] = useCheckQRCodeTokenMutation();

  const handleLogin = React.useCallback((qrCodeToken: string) => {
    login({ qrCodeToken, kioskId });
  }, [login, kioskId]);

  React.useEffect(() => {
    if (kioskId && vendor === Vendor.PARTTEAM) {
      const qrCodeScanner = QRCodeScannerDevice.getInstance();

      qrCodeScanner.addListener(handleLogin);
      return () => qrCodeScanner.removeListener(handleLogin);
    }

    return undefined;
  }, [kioskId, vendor, handleLogin]);
};

export default useQrCodeScanner;
