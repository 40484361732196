import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { b } from 'utils/i18nUtils';
import useUpload from 'hooks/useUpload';
import { validateEmail } from 'utils/formUtils';
import { isPublicDevice } from 'modules/dealers/selectors';
import { useUpdateSelfServiceMutation } from 'modules/selfServices/service';
import { getBreakdownServiceAuthorType } from 'modules/selfServices/selectors';
import { BreakdownServiceAuthorType } from 'modules/selfServices/types/SelfService';

import PageBaseProps from 'types/PageBase';
import { MediaEntityType, MediaSubType } from 'types/MediaTypeEnum';

import { GET_MEDIA_UPLOAD_URL } from 'constants/url';

import formActions from 'modules/form/actions';

import { getSelectedSelfServiceId } from 'modules/auth/selectors';

import Input from 'components/ui/Input';
import Switch from 'components/ui/Switch';
import Footer from 'components/ui/PageFooter';
import Button, { ButtonType } from 'components/ui/Button';

import Document from './Document';
import Carousel from '../ui/Carousel';
import SignatureModal from './SignatureModal';
import SignatureError from './SignatureError';
import useDocuments from './hooks/useDocuments';

const Signature: React.FC<PageBaseProps> = ({ onPrev, onNext, isPenultimate, shouldDisplayBackButton }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isPublic = useSelector(isPublicDevice);
  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const breakdownServiceAuthorType = useSelector(getBreakdownServiceAuthorType);

  const { upload, isLoading: isUploadLoading } = useUpload(selfServiceId);
  const [, { isLoading: isUpdateLoading }] = useUpdateSelfServiceMutation({ fixedCacheKey: 'UPDATE/SELF_SERVICE' });

  const [email, setEmail] = React.useState('');
  const [signature, setSignature] = React.useState<Blob>(null);
  const [emailError, setEmailError] = React.useState<string>();
  const [displayEmailField, setDisplayEmailField] = React.useState(false);
  const [hasConfirmedReading, setHasConfirmedReading] = React.useState(false);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = React.useState(false);

  const {
    documents, documentIndex, setDocumentIndex, hasError, readDocument, isLoading, readNextDocument, hasReadAllDocuments,
  } = useDocuments();

  const isLoadingSignature = isUploadLoading || isUpdateLoading;

  const shouldShowEmailSwitch = breakdownServiceAuthorType === BreakdownServiceAuthorType.BREAKDOWN_MECHANIC && hasConfirmedReading;
  const isSignButtonDisabled = !hasConfirmedReading || Boolean(emailError) || (displayEmailField && !email);

  const handleSignatureChange = React.useCallback((newSignature?: HTMLCanvasElement) => {
    if (newSignature) {
      newSignature.toBlob((blob) => {
        setSignature(blob);
      });
    } else {
      setSignature(null);
    }
  }, []);

  const handleEmailChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newEmail } = event.target;
    let error = 'validation.required';
    if (newEmail) {
      error = validateEmail(newEmail);
    }
    setEmailError(error);
    setEmail(newEmail);
  }, []);

  const handleNextClick = React.useCallback(async () => {
    const media = await upload({
      file: signature,
      isSignature: true,
      contentType: 'image/png',
      url: GET_MEDIA_UPLOAD_URL,
      subType: MediaSubType.MEDIAS,
      entityType: MediaEntityType.SELF_SERVICE,
    });

    // BMWVID-20295 - Avoid the case of the media is not correctly uploaded
    if (media) {
      const { uploadId } = media;
      dispatch(
        formActions.upsertForm({
          key: 'signature',
          values: { uploadId, breakdownServiceEmail: email || undefined },
        }),
      );
      onNext();
    }
  }, [dispatch, email, onNext, signature, upload]);

  const handleOpenSignature = React.useCallback(() => {
    setIsSignatureModalOpen(true);
  }, []);

  const handleCloseSignature = React.useCallback(() => {
    setSignature(null);
    setIsSignatureModalOpen(false);
  }, []);

  const handleDisplayEmailField = React.useCallback((open: boolean) => {
    setEmail('');
    setEmailError(undefined);
    setDisplayEmailField(open);
  }, []);

  const handleRetry = React.useCallback(() => {
    const { id: documentId } = documents[documentIndex];
    readDocument(documentId);
  }, [documentIndex, documents, readDocument]);

  const handlePreview = React.useCallback((id: string) => () => readDocument(id), [readDocument]);
  const handleClosePreview = React.useCallback(() => setDocumentIndex(undefined), [setDocumentIndex]);

  return (
    <>
      <div className="main-content min-h-[calc(100svh-104px)] kiosk:min-h-svh pb-0 kioskPartteam:pb-48 kioskSharebox:pb-40">
        <p className="text-center text-low">
          <FormattedMessage
            id="signature.indication"
            defaultMessage="Please <b>sign</b> the {count, plural, one {document} other {documents}} below."
            values={{ b, count: documents.length }}
          />
        </p>
        <div className="content pb-4 kiosk:pb-0">
          <div className="flex flex-col">
            {documents.map((documentToSign) => <Document key={documentToSign.id} onClick={handlePreview} {...documentToSign} />)}
          </div>
          <div className="relative">
            <Switch
              checked={hasConfirmedReading}
              onChange={setHasConfirmedReading}
              data-testid="confirm-switch"
            >
              <FormattedMessage
                id="signature.confirm"
                defaultMessage="I confirm that I have read, understood, and agree to sign the {count, plural, one {document} other {documents}} above."
                values={{ count: documents.length }}
              />
            </Switch>
            {shouldShowEmailSwitch && (
              <>
                <Switch
                  checked={displayEmailField}
                  onChange={handleDisplayEmailField}
                  data-testid="email-switch"
                  className="mt-5 kiosk:mt-10"
                >
                  <FormattedMessage
                    id="signature.breakdownSwitch"
                    defaultMessage="I want to receive a summary of my answers by email"
                  />
                </Switch>
                {displayEmailField && (
                  <Input
                    type="email"
                    value={email}
                    autoCorrect="off"
                    inputMode="email"
                    spellCheck="false"
                    autoComplete="email"
                    data-testid="email-input"
                    onChange={handleEmailChange}
                    className="mt-3 kiosk:mt-10"
                    error={emailError && intl.formatMessage({ id: emailError })}
                    label={intl.formatMessage({ id: 'customerInfo.email', defaultMessage: 'Email' })}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <SignatureModal
          disabled={!signature}
          open={isSignatureModalOpen}
          onClose={handleCloseSignature}
          onConfirm={handleNextClick}
          onChange={handleSignatureChange}
          loading={isLoadingSignature}
          confirmMessage={(
            <>
              {isPenultimate && <FormattedMessage id="confirm.title" defaultMessage="Confirm" />}
              {!isPenultimate && <FormattedMessage id="steps.next" defaultMessage="Next" />}
            </>
          )}
        />
      </div>
      <Footer
        hideNextButton
        onPrev={onPrev}
        onNext={handleNextClick}
        disabled={isSignButtonDisabled}
        shouldDisplayBackButton={shouldDisplayBackButton}
        wrapperProps={{ className: 'relative kiosk:fixed' }}
      >
        <Button
          type={ButtonType.TERTIARY}
          className={classNames('max-w-md w-full kiosk:inline-flex kiosk:justify-center', {
            'kiosk:w-72': shouldDisplayBackButton,
            'kiosk:w-full': !shouldDisplayBackButton,
          })}
          onClick={handleOpenSignature}
          disabled={isSignButtonDisabled}
        >
          <FormattedMessage
            id="signature.sign"
            defaultMessage="Sign {count, plural, one {document} other {documents}}"
            values={{ count: documents.length }}
          />
        </Button>
      </Footer>
      {documentIndex >= 0 && (
        <Carousel
          fullscreen
          medias={documents}
          loading={isLoading}
          hideNavigationArrow
          showDownload={!isPublic}
          onClose={handleClosePreview}
          defaultMediaIndex={documentIndex}
          error={hasError && <SignatureError onRetry={handleRetry} />}
          footer={!isLoading && (
            <div className="flex justify-center w-full items-center text-center px-6 max-w-sm kiosk:max-w-xl">
              <Button
                type={ButtonType.WHITE}
                className="w-full h-14 kiosk:h-28"
                testId={hasReadAllDocuments ? 'continue' : 'next'}
                onClick={hasReadAllDocuments ? handleClosePreview : readNextDocument}
              >
                {hasReadAllDocuments && <FormattedMessage id="document.continue" defaultMessage="Continue" />}
                {!hasReadAllDocuments && <FormattedMessage id="document.next" defaultMessage="Next document" />}
              </Button>
            </div>
          )}
        />
      )}
    </>
  );
};

export default Signature;
