import Flow from 'types/Flow';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import { FINAL_INSTRUCTIONS, KIOSK_LOCATION } from 'components/SelfServices/Steps/common';

import COMMON_KIOSK_CHECK_OUT_STEPS, { CommonKioskCheckOutSteps, MOBILITY_PARKING } from '../common';
import { SIGNATURE } from '../../onSite';

export type ShareboxS32CheckOutSteps =
  CommonKioskCheckOutSteps
  | SelfServiceSteps.MOBILITY_PARKING
  | SelfServiceSteps.SIGNATURE
  | SelfServiceSteps.KIOSK_LOCATION
  | SelfServiceSteps.FINAL_INSTRUCTIONS;

// Alias 'OUTDOOR' flow
const SHAREBOX_S32_CHECK_OUT_STEPS: Flow<ShareboxS32CheckOutSteps> = {
  ...COMMON_KIOSK_CHECK_OUT_STEPS,
  MOBILITY_PARKING: {
    ...MOBILITY_PARKING,
    next: SIGNATURE.key,
  },
  SIGNATURE: {
    ...SIGNATURE,
    previous: MOBILITY_PARKING.key,
    next: KIOSK_LOCATION.key,
  },
  KIOSK_LOCATION: {
    ...KIOSK_LOCATION,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: KIOSK_LOCATION.key },
};

export default SHAREBOX_S32_CHECK_OUT_STEPS;
