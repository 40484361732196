import Label from 'types/Label';
import { Brand } from 'types/Brand';
import { Context } from 'types/Context';
import { KioskModel, KioskType } from 'types/KioskVendors';
import { AbstractMedia } from 'types/AbstractMedia';
import { PhoneNumber, SelfServiceOrigin, SelfServiceType } from 'modules/selfServices/types/SelfService';
import { KioskImages } from 'types/KioskImages';

export enum KioskConnectionStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export interface ContextResponse extends Context {
  backgroundMedia: AbstractMedia,
  brands: Brand[],
  confidentialityPolicyLink?: string,
  courtesyVehicleAvailabilityDate?: string,
  dealerCountry?: DealerCountry,
  id?: string,
  isAvailable?: boolean,
  isEmergencyMode?: boolean,
  isPublic: boolean,
  isTokenExpired?: boolean,
  kioskBackgroundMedia: AbstractMedia,
  kioskConnectionStatus?: KioskConnectionStatus,
  kioskDealers? : Dealers[],
  kioskId? : string,
  kioskModel?: KioskModel,
  kioskImages?: KioskImages,
  kioskLocationId?: number,
  kioskName? : string,
  kioskSpeech?: Label,
  kioskType?: KioskType,
  kioskTypes?: KioskType[],
  origin: SelfServiceOrigin,
  registration?: string,
  selfServiceAvailableTypes?: Partial<Record<SelfServiceType, SelfServiceOrigin[]>>,
  selfServiceKioskExplanationMedias?: AbstractMedia[],
  type?: SelfServiceType,
}

export enum ConnectionTypeLogin {
  TOKEN = 'TOKEN',
  MFA = 'MFA',
}

export interface Dealers {
  id: string,
  dealerName: string,
  dealerCustomerLanguage: string,
  confidentialityPolicyLink: string,
  generalTermsConditionsLink: string,
  selfServicePhoneNumber: PhoneNumber,
  selfServiceAvailableTypes: Partial<Record<SelfServiceType, SelfServiceOrigin[]>>,
  selfServiceKioskExplanationMedias?: AbstractMedia[],
  brands?: Brand[],
  dealerCountry?: DealerCountry,
  timezone?: string,
}

export interface DealerCountry {
  code: string,
  prefix: number,
  valueAddedTax: number,
  currency: string,
  currencyIsoCode: string,
  senderIdAllowed: boolean,
  appCountry: boolean,
  name: string,
  timezones?: Record<string, string>,
}
