import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch } from 'hooks';
import actions from 'modules/translations/actions';
import { getAllLanguages, getSelectedLanguage, getTranslationsSelectors } from 'modules/translations/selectors';
import {
  getPaymentMethods,
  isPublicDevice,
  shouldDisplayPhoneNumbers as shouldDisplayPhoneNumbersSelector,
} from 'modules/dealers/selectors';

import LanguageEnum from 'types/LanguageEnum';

import { b, getHyperlink } from 'utils/i18nUtils';

import Modal from '../Modal';
import SelectInput from '../SelectInput';
import DealersPhone from '../DealersPhone';
import QRCodeViewer from '../QRCodeViewer';

const DSA_URL = 'https://www.fleetback.com/digital-services-act/';

const HelpModal: React.FC<{ open?: boolean; onClose: () => void }> = ({ open, onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const ref = React.useRef<HTMLButtonElement>();

  const isPublic = useSelector(isPublicDevice);
  const languages = useSelector(getAllLanguages);
  const locale = useSelector(getSelectedLanguage);
  const paymentMethods = useSelector(getPaymentMethods);
  const shouldDisplayPhoneNumbers = useSelector(shouldDisplayPhoneNumbersSelector);
  const { isLoading } = useSelector(getTranslationsSelectors);

  const hasPaymentMethods = paymentMethods?.length > 0;

  const languageData = React.useMemo(
    () => languages.reduce<Record<string, string>>(
      (acc, lang) => ({
        ...acc,
        [lang]: intl.formatMessage({ id: `language.${lang}`, defaultMessage: lang.toUpperCase() }),
      }),
      {},
    ),
    [intl, languages],
  );

  const handleChangeLanguage = React.useCallback(
    (newLocale: unknown) => {
      dispatch(actions.setSelectedLanguage(newLocale as LanguageEnum));
    },
    [dispatch],
  );

  return (
    <Modal
      open={open}
      focusRef={ref}
      className="w-full max-w-2xl max-h-[95vh] min-h-[300px] kioskPartteam:min-h-[600px] kioskSharebox:min-h-[400px] flex flex-col"
      title={<FormattedMessage id="helpLine.title" defaultMessage="Need help?" tagName="h2" />}
      onClose={onClose}
    >
      <div className="scroll-smooth flex-1 p-1">
        <div className="mb-8">
          <SelectInput
            value={locale}
            data={languageData}
            loading={isLoading}
            onChange={handleChangeLanguage}
            label={intl.formatMessage({ id: 'helpLine.changeLanguage', defaultMessage: 'Change language' })}
            className={classNames('max-h-44 kiosk:max-h-96 max-h-[160px] ', {
              'max-h-28 kiosk:max-h-52': !hasPaymentMethods && !shouldDisplayPhoneNumbers && !isPublic,
            })}
            data-testid="language-select"
          />
        </div>
        <div className="w-full h-px my-4 kioskSharebox:my-6 kiosk:my-8 bg-border-default" />
        {hasPaymentMethods && (
          <>
            <div className="flex flex-col items-center">
              <p className="text-center">
                <FormattedMessage
                  id="helpLine.paymentMethods"
                  defaultMessage="Here are the methods of payment accepted at the dealership"
                />
              </p>
              <ul className="flex justify-center items-center w-full flex-wrap gap-4 my-4">
                {paymentMethods.map(({ name }) => (
                  <li className="flex items-center justify-center px-2 bg-card-bg rounded-md text-low">
                    {name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full h-px my-4 kioskSharebox:my-6 kiosk:my-8 bg-border-default" />
          </>
        )}
        {shouldDisplayPhoneNumbers && (
          <>
            <div className="flex flex-col items-center">
              <p className="text-center">
                <FormattedMessage
                  id="helpLine.message"
                  defaultMessage="If you have any problems with the terminal, do not hesitate to contact the number below."
                />
              </p>
              <DealersPhone />
            </div>
            <div className="w-full h-px my-4 kioskSharebox:my-6 kiosk:my-8 bg-border-default" />
          </>
        )}
        <div className="flex flex-col items-center">
          {!isPublic && (
            <FormattedMessage
              id="helpLine.dsa"
              defaultMessage="Content not adapted? You can report the problem <a><b>here</b></a>"
              values={{ a: getHyperlink({ href: DSA_URL, target: '_blank' }), b }}
              tagName="p"
            />
          )}
          {isPublic && (
            <>
              <p className="text-center">
                <FormattedMessage
                  id="helpLine.dsaKiosk"
                  defaultMessage="Content not adapted? You can <b>report the problem</b> by scanning this QR code"
                  values={{ b }}
                />
              </p>
              <QRCodeViewer content={DSA_URL} className="!w-1/2 !h-1/2 max-w-40 mt-4 kioskSharebox:mt-6 kiosk:mt-8 flex justify-center" />
              <p className="text-center mt-4 kioskSharebox:mt-6 kiosk:mt-8">
                <span className="kiosk:text-xl font-medium">{DSA_URL}</span>
              </p>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default HelpModal;
