enum FormKeys {
  BREAKDOWN_INFO = 'breakdownInfo',
  CUSTOMER_INFO = 'customerInfo',
  REPAIR_ORDER = 'repairOrder',
  EXTRAS = 'extras',
  KIOSK_USAGE = 'kioskPreferencesQuestions',
  MOBILITY = 'mobility',
  MOBILITY_PARKING = 'mobilityParking',
  QUESTIONS = 'questions',
  PARKING = 'parking',
  VEHICLE_CHECK = 'vehicleCheck',
}

export default FormKeys;
