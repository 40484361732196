import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from 'App/Store';
import selfServicesApi from 'modules/selfServices/service';
import { getMFAData, getSelectedSelfServiceId } from 'modules/auth/selectors';
import {
  getOrigin, isPublicDevice, getContext as getDealerContext, getContext,
} from 'modules/dealers/selectors';

import { SelfService, SelfServiceOrigin, SelfServiceType } from './types/SelfService';

const getRoot = (state: RootState) => state;
const getProps = (_: RootState, props: string) => props;

export const getSelectedSelfService = createDraftSafeSelector(
  getRoot,
  getSelectedSelfServiceId,
  (state, selectedSelfServiceId) => {
    const { data } = selfServicesApi.endpoints.getSelfServiceById.select({
      id: selectedSelfServiceId,
    })(state);
    return data;
  },
);

export const isSelfServiceLoading = createDraftSafeSelector(
  getRoot,
  getSelectedSelfServiceId,
  (state, selectedSelfServiceId) => {
    const { isLoading } = selfServicesApi.endpoints.getSelfServiceById.select({
      id: selectedSelfServiceId,
    })(state);
    return isLoading;
  },
);

export const getCustomerInfo = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.customerInfo,
);

export const getCustomerLanguage = createDraftSafeSelector(
  getCustomerInfo,
  (customerInfo) => customerInfo?.language,
);

export const getParkingInfo = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.parking,
);

export const getFinalInvoice = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.finalInvoice,
);

export const getSelfServiceType = createDraftSafeSelector(
  getContext,
  getSelectedSelfService,
  (context, selfService) => selfService?.type ?? context?.type,
);

export const isCheckoutSelfService = createDraftSafeSelector(
  getSelfServiceType,
  (type) => type === SelfServiceType.CHECK_OUT,
);

export const isCheckinSelfService = createDraftSafeSelector(
  getSelfServiceType,
  (type) => type === SelfServiceType.CHECK_IN,
);

export const isBreakdownSelfService = createDraftSafeSelector(
  getSelfServiceType,
  (type) => type === SelfServiceType.BREAKDOWN,
);

export const isFinalInvoicePayable = createDraftSafeSelector(
  getFinalInvoice,
  (finalInvoice) => Boolean(finalInvoice?.payable),
);

export const isInvoicePaymentSourceDelayable = createDraftSafeSelector(
  getOrigin,
  (origin) => origin !== SelfServiceOrigin.KIOSK,
);

export const isInvoicePaymentDelayable = createDraftSafeSelector(
  isFinalInvoicePayable,
  isCheckoutSelfService,
  isInvoicePaymentSourceDelayable,
  (isPayable, isCheckout, delayableType) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    isPayable && isCheckout && delayableType,
);

export const hasClickableInstruction = createDraftSafeSelector(
  getOrigin,
  isPublicDevice,
  isCheckinSelfService,
  isCheckoutSelfService,
  (origin, isDealerDevice, isCheckin, isCheckout) => (
    (isCheckin || isCheckout) && (
      origin === SelfServiceOrigin.HOME
        || (origin === SelfServiceOrigin.ON_SITE && !isDealerDevice)
        || (origin === SelfServiceOrigin.KIOSK && !isDealerDevice)
    )
  ),
);

export const getSelfServiceStatus = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.status,
);

// This is useful to know if the mobility WAS accepted before, hence there are new documents to send
export const isMobilityAccepted = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.mobility?.customerAccepted === true,
);

export const getMobilityDocuments = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.mobility?.documents ?? [],
);

export const getMobilityContract = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.mobility?.contract,
);

export const shouldDisplayMobilityParking = (selfService: SelfService) => selfService?.mobilityEnabled
  && selfService.type === SelfServiceType.CHECK_OUT;

export const displayMobilityParking = createDraftSafeSelector(
  getSelectedSelfService,
  shouldDisplayMobilityParking,
);

export const getMobilityVehicle = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.mobility?.vehicle,
);

export const getParkingMaps = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.parkingMap?.maps,
);

export const isMobilityEnabled = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.mobilityEnabled,
);

export const isCourtesyVehicleCheckEnabled = createDraftSafeSelector(
  getMFAData,
  getDealerContext,
  isMobilityEnabled,
  (mfa, context, hasMobility) => (
    (mfa?.mobility?.courtesyVehicleCheckEnabled || context?.mobility?.courtesyVehicleCheckEnabled) && hasMobility
  ),
);

export const getBreakdownServiceAuthorType = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.breakdownServiceAuthorType,
);

export const getPayerById = createDraftSafeSelector(
  getSelectedSelfService,
  getProps,
  ({ payers }, payerId) => payers?.find(({ id }) => id === payerId),
);

const getSelfServiceDealerId = createDraftSafeSelector(
  getSelectedSelfService,
  (selfService) => selfService?.dealer?.id,
);

export const getKioskExplanationMedias = createDraftSafeSelector(
  getDealerContext,
  getSelfServiceDealerId,
  (context, dealerId) => context.selfServiceKioskExplanationMedias
    ?? context.kioskDealers?.find(({ id }) => id === dealerId)?.selfServiceKioskExplanationMedias,
);
