import api from 'service/index';

const appApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVersion: build.query<{ version: string }, void>({
      query: () => {
        const { origin } = window.location;
        return {
          method: 'GET',
          url: `${origin}/meta.json`,
          baseUrl: undefined,
          cache: 'no-store',
        };
      },
    }),
  }),
});

export default appApi;
