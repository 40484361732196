export const INACTIVITY_SELF_SERVICE_FLOWS = 120; // 2 minutes

export const INACTIVITY_TIME_ON_PAYMENT = 300; // 5 minutes

export const INACTIVITY_ADMIN = 20; // 20 seconds

export const INACTIVITY_TIME_TO_LOGOUT = 20; // 20 seconds

export const INACTIVITY_TIME_OPENED_DOORS = 300; // 5 minutes

export const INACTIVITY_TIME_ON_ERROR_PAGE = 60; // 1 minute

export const INACTIVITY_TIME_ON_FINAL_INSTRUCTIONS = 30; // 30 seconds
