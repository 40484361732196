import React from 'react';
import classNames from 'classnames';

type RadioBulletProps<T extends string> = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'title' | 'ref' | 'value' | 'onChange'> & {
  description: React.ReactNode;
  checked: boolean;
  onChange: (value: T) => void;
  title: React.ReactNode;
  value: T;
};

const RadioBullet = <T extends string>({
  value, checked, onChange, title, description, ...inputProps
}: RadioBulletProps<T>) => {
  const id = `item-${value}`;
  const handleChange = () => {
    onChange(value);
  };

  return (
    <div
      id={id}
      className={classNames(
        'flex items-center justify-between w-full p-5 kiosk:p-10 bg-card-radio-bg rounded-card border-card-radio-size border-card-radio-border cursor-pointer text-card-radio-text mb-4 kiosk:mb-10',
        { 'hover:bg-card-radio-hover-bg': !checked },
        { '!bg-card-radio-active-bg hover:bg-card-radio-active-bg !text-card-radio-active-text cursor-default': checked },
      )}
      data-testid="radiobullet-wrapper"
      onClick={handleChange}
    >
      <div className="flex flex-col">
        <label
          htmlFor={id}
          className="cursor-pointer text-xl font-bold"
        >
          {title}
        </label>
        <span>
          {description}
        </span>
      </div>
      <input
        className="cursor-pointer appearance-none rounded-full size-5 ring-radio-size ring-radio-border hover:ring-radio-hover-border checked:bg-radio-active-bg after:checked:block after:checked:size-3 after:checked:m-1 after:checked:rounded-full after:checked:bg-radio-active-dot checked:ring-radio-active-border shrink-0"
        {...inputProps}
        checked={checked}
        value={value}
        onChange={handleChange}
        type="radio"
      />
    </div>
  );
};

export default RadioBullet;
