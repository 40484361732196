import { Vendor } from 'types/KioskVendors';

import { getDeviceName as getPartteamDeviceName } from './utils/partteam';

export const PARTTEAM_RACKS = [1, 2];

export const WEBCAM_DEVICE_NAMES: Partial<Record<Vendor, Array<string>>> = {
  [Vendor.PARTTEAM]: PARTTEAM_RACKS.map(getPartteamDeviceName),
};

export const RETRY_GET_MEDIA_TIMEOUT = 1000;
