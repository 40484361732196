import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';

import { Maps } from 'modules/selfServices/types/SelfService';

import { b } from 'utils/i18nUtils';

import Value from './Value';
import ParkingMap from './ParkingMap';

interface ParkingContentProps {
  spotNumber?: string;
  x?: number;
  y?: number;
  mapId?: string;
  maps?: Maps[];
  hideInstructions?: boolean;
  title?: MessageDescriptor;
}

const ParkingContent:React.FC<ParkingContentProps> = ({
  spotNumber, x, y, mapId, maps, hideInstructions, title,
}) => {
  const intl = useIntl();

  const map = React.useMemo(
    () => maps?.find(({ id }) => id === mapId),
    [maps, mapId],
  );

  const displayMap = x >= 0 && y >= 0;
  const hasMapName = Boolean(map?.name);
  const hasSpotNumber = Boolean(spotNumber);
  const displayMapData = hasMapName || hasSpotNumber;

  return (
    <>
      <div className="text-center mb-5 kiosk:mb-10">
        {title && (<FormattedMessage tagName="h1" {...title} />)}
        {!hideInstructions && (
          <p className="text-low mt-10">
            <FormattedMessage
              id="parking.instruction"
              defaultMessage="We have <b>parked your vehicle</b> at the following spot."
              values={{ b }}
            />
          </p>
        )}
      </div>
      {displayMapData && (
        <div
          className="flex flex-wrap justify-center w-full bg-card-bg rounded-card p-2 kiosk:p-6 kiosk:mb-10"
        >
          {hasMapName && (
            <Value
              value={map.name}
              data-testid="map-name-input"
              className={classNames({ '!rounded-r-none': hasSpotNumber })}
              inputClassName={classNames({ '!rounded-r-none': hasSpotNumber })}
              label={intl.formatMessage({ id: 'parking.title', defaultMessage: 'Parking' })}
            />
          )}
          {hasSpotNumber && (
            <Value
              value={spotNumber}
              data-testid="spot-number-input"
              className={classNames({ '!rounded-l-none': hasMapName })}
              inputClassName={classNames({ '!rounded-l-none': hasMapName })}
              label={intl.formatMessage({ id: 'parking.spotNumber.title', defaultMessage: 'Parking spot number' })}
            />
          )}
        </div>
      )}
      {displayMap && <ParkingMap map={map} value={{ x, y }} disabled />}
    </>
  );
};

export default ParkingContent;
