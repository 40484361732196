import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { ShareboxModel } from 'types/KioskVendors';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';
import { getKeyActionFromSelfService, KeyAction } from 'types/KeyAction';

import { NOTIFICATION_TIMEOUT } from 'constants/index';

import { openKeysSafe } from 'modules/kiosk/actions';

import { getSelectedSelfService } from 'modules/selfServices/selectors';
import { getIsCloseSuccess, getIsOpenFailure, hasError as hasErrorSelector } from 'modules/kiosk/selectors';

import useShareboxKeyTranslations, { DEFAULT_MESSAGES } from 'components/Keys/ShareboxKey/useShareboxKeyTranslations';
import ProcessingStepModal, { ProcessingStep, ProcessingStepModalProps } from 'components/ui/ProcessingStepModal';

interface RemoteKioskModalProps {
  open: boolean;
  onClose: (error: boolean) => void;
}

/* eslint-disable max-len */
const DEFAULT_MESSAGES_MODAL_VARIANT = {
  ...DEFAULT_MESSAGES,
  [`mobility_${SelfServiceType.CHECK_IN}_${ShareboxModel.S32}`]: 'To swap the keys, press the blinking green button on the kiosk to open and close the door.',
  [`mobility_${SelfServiceType.CHECK_OUT}_${ShareboxModel.S32}`]: 'To swap the keys, press the blinking green button on the kiosk to open and close the door.',
  [`${SelfServiceType.CHECK_IN}_${ShareboxModel.S32}`]: 'To drop the keys, press the blinking green button on the kiosk to open and close the door.',
  [`${SelfServiceType.CHECK_OUT}_${ShareboxModel.S32}`]: 'To retrieve the keys, press the blinking green button on the kiosk to open and close the door.',
};
/* eslint-enable max-len */

const getSteps = (
  description: MessageDescriptor,
  successDescription: MessageDescriptor,
  isOpen: boolean,
): ProcessingStepModalProps['config'] => ({
  [ProcessingStep.PENDING]: {
    title: <FormattedMessage id="remoteKeyModal.title" defaultMessage="Continue on the kiosk" tagName="h2" />,
    content: <FormattedMessage {...description} />,
    hideButton: true,
  },
  [ProcessingStep.SUCCESS]: {
    hideButton: true,
    title: <FormattedMessage id="remoteKeyModal.successTitle" defaultMessage="Completed!" tagName="h2" />,
    content: <FormattedMessage {...successDescription} />,
  },
  [ProcessingStep.ERROR]: {
    buttonText: <FormattedMessage id="steps.back" defaultMessage="Back" />,
    title: (
      <FormattedMessage
        id={`key.error.${isOpen ? 'opening' : 'closing'}`}
        // eslint-disable-next-line max-len
        defaultMessage={isOpen ? 'An error occurred while opening the slot' : 'An error occurred while closing the slot'}
        tagName="h2"
      />
    ),
    content: (
      <FormattedMessage
        id="kiosk.doorError.USER.OPENED.description"
        // eslint-disable-next-line max-len
        defaultMessage="Please try again. If the problem persists, please log out without leaving any keys in the kiosk and contact us."
      />
    ),
  },
});

const RemoteKeyModal: React.FC<RemoteKioskModalProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const timeoutId = React.useRef<NodeJS.Timeout>();

  const [currentStep, setCurrentStep] = React.useState(ProcessingStep.PENDING);

  const hasError = useSelector(hasErrorSelector);
  const openError = useSelector(getIsOpenFailure);
  const isDoorClosed = useSelector(getIsCloseSuccess);
  const selfService = useSelector(getSelectedSelfService);

  const successDescription = React.useMemo(() => {
    switch (getKeyActionFromSelfService(selfService)) {
      case KeyAction.DROP:
        return {
          id: 'remoteKeyModal.successDescription.DROP',
          defaultMessage: 'You have sucessfully dropped the keys.',
        };
      case KeyAction.RETRIEVE:
        return {
          id: 'remoteKeyModal.successDescription.PICK',
          defaultMessage: 'You have sucessfully retrieved the keys.',
        };
      case KeyAction.SWAP:
        return {
          id: 'remoteKeyModal.successDescription.SWAP',
          defaultMessage: 'You have sucessfully swapped the keys.',
        };
      default:
        return {};
    }
  }, [selfService]);

  const description = useShareboxKeyTranslations({
    path: 'key.modal',
    defaultMessages: DEFAULT_MESSAGES_MODAL_VARIANT,
  });

  const steps = React.useMemo(
    () => getSteps(description, successDescription, openError),
    [description, successDescription, openError],
  );

  const handleOpenDoor = React.useCallback(() => {
    dispatch(openKeysSafe({ selfServiceId: selfService.id, selfServiceType: selfService.type }));
  }, [dispatch, selfService.id, selfService.type]);

  const handleClose = React.useCallback(() => {
    timeoutId.current = setTimeout(() => {
      onClose(false);
    }, NOTIFICATION_TIMEOUT);
  }, [onClose]);

  const handleOnClick = React.useCallback(() => {
    onClose(hasError || currentStep !== ProcessingStep.SUCCESS);
  }, [hasError, onClose, currentStep]);

  React.useEffect(() => {
    if (hasError) {
      setCurrentStep(ProcessingStep.ERROR);
    } else if (isDoorClosed) {
      setCurrentStep(ProcessingStep.SUCCESS);
      handleClose();
    }

    return () => clearTimeout(timeoutId.current);
  }, [hasError, isDoorClosed, handleClose]);

  React.useEffect(() => {
    if (open) {
      setCurrentStep(ProcessingStep.PENDING);
      handleOpenDoor();
    }
  }, [open, handleOpenDoor]);

  return (
    <ProcessingStepModal
      open={open}
      config={steps}
      step={currentStep}
      onClick={handleOnClick}
    />
  );
};

export default RemoteKeyModal;
