import Flow from 'types/Flow';
import FlowsByModel from 'types/FlowsByModel';
import { PartteamModel } from 'types/KioskVendors';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import { FINAL_INSTRUCTIONS, PARTTEAM_KEY, SIGNATURE } from 'components/SelfServices/Steps/common';

import { PARKING } from '../onSite';
import COMMON_KIOSK_BREAKDOWN_STEPS, { CommonKioskBreakdownSteps } from './common';

export type PartteamKioskBreakdownSteps =
  CommonKioskBreakdownSteps
  | SelfServiceSteps.SIGNATURE
  | SelfServiceSteps.PARTTEAM_KEY
  | SelfServiceSteps.FINAL_INSTRUCTIONS;

const flow: Flow<PartteamKioskBreakdownSteps> = {
  ...COMMON_KIOSK_BREAKDOWN_STEPS,
  SIGNATURE: {
    ...SIGNATURE,
    previous: PARKING.key,
    next: PARTTEAM_KEY.key,
    data: { ...SIGNATURE.data, isFinalizeStep: true },
  },
  PARTTEAM_KEY: {
    ...PARTTEAM_KEY,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: PARTTEAM_KEY.key },
};

const PARTTEAM_KIOSK_BREAKDOWN_STEPS: FlowsByModel<PartteamModel> = {
  [PartteamModel.INDOOR]: flow,
  [PartteamModel.OUTDOOR]: flow,
};

export default PARTTEAM_KIOSK_BREAKDOWN_STEPS;
