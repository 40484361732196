import Flow from 'types/Flow';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import {
  BREAKDOWN_INFO, CUSTOMER_INFO, KIOSK_USAGE, PARKING, QUESTIONS,
} from '../onSite';

export type CommonKioskBreakdownSteps =
  SelfServiceSteps.CUSTOMER_INFO
  | SelfServiceSteps.BREAKDOWN_INFO
  | SelfServiceSteps.QUESTIONS
  | SelfServiceSteps.KIOSK_USAGE
  | SelfServiceSteps.PARKING;

const COMMON_KIOSK_BREAKDOWN_STEPS: Flow<CommonKioskBreakdownSteps> = {
  CUSTOMER_INFO,
  BREAKDOWN_INFO,
  QUESTIONS,
  KIOSK_USAGE,
  PARKING,
};

export default COMMON_KIOSK_BREAKDOWN_STEPS;
