import React from 'react';
import Spinner from './Spinner';

interface MediaLoaderProps {
  displaySpinner?: boolean;
}

const MediaLoader : React.FC<MediaLoaderProps> = ({ displaySpinner }) => (
  <div className="flex justify-center align-center size-full">
    {displaySpinner && <Spinner />}
    {!displaySpinner && <div className="animate-pulse bg-brand-low size-full" />}
  </div>
);

export default MediaLoader;
