import { KeyStatus } from 'modules/kiosk/types/Kiosk';
import { ConnectionTypeLogin } from 'modules/dealers/types/ContextResponse';
import { SelfService, SelfServiceType } from 'modules/selfServices/types/SelfService';
import { SelfServiceAPISteps, SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import { shouldDisplayMobilityParking } from 'modules/selfServices/selectors';

import Parking from 'components/Parking';
import Questions from 'components/Questions';
import Signature from 'components/Signature';
import CustomerInfo from 'components/CustomerInfo';
import PartteamKey from 'components/Keys/PartteamKey';
import ShareboxKey from 'components/Keys/ShareboxKey';
import KioskLocationId from 'components/KioskLocationId';
import FinalInstructions from 'components/FinalInstructions';

import { isNotAnswered } from './utils';
import KioskUsageForm from '../../KioskUsage';
import type { SelfServiceStep } from '../types';

export const CUSTOMER_INFO: SelfServiceStep = {
  key: SelfServiceSteps.CUSTOMER_INFO,
  apiKey: SelfServiceAPISteps.CONTACT_INFO,
  title: {
    id: 'page.contactInfo.title',
    defaultMessage: 'Contact information',
  },
  component: CustomerInfo,
  data: {
    preventBrowserNavigation: true,
    isVisible: ({ status }) => isNotAnswered(status),
  },
};

export const QUESTIONS: SelfServiceStep = {
  key: SelfServiceSteps.QUESTIONS,
  apiKey: SelfServiceAPISteps.QUESTIONS,
  title: {
    id: 'questions.title',
    defaultMessage: 'Questions',
  },
  component: Questions,
  data: {
    isVisible: ({ status, questionForm }) => isNotAnswered(status) && questionForm?.questions?.length > 0,
  },
};

export const PARKING: SelfServiceStep = {
  key: SelfServiceSteps.PARKING,
  apiKey: SelfServiceAPISteps.PARKING,
  title: (selfService) => {
    const isMobility = shouldDisplayMobilityParking(selfService);
    return {
      id: isMobility ? 'parking.mobility.title' : 'parking.title',
      defaultMessage: isMobility ? 'Courtesy vehicle parking' : 'Parking',
    };
  },
  component: Parking,
  data: {
    isVisible: ({
      status,
      parkingMap,
    }) => isNotAnswered(status) && (parkingMap?.allowParkingSpot || parkingMap?.maps?.length > 0),
  },
};

export const SIGNATURE: SelfServiceStep = {
  key: SelfServiceSteps.SIGNATURE,
  apiKey: SelfServiceAPISteps.SIGNATURE,
  title: {
    id: 'signature.title',
    defaultMessage: 'Signature',
  },
  component: Signature,
  data: {
    isVisible: ({ status, isSummarySignatureNeeded }) => isNotAnswered(status) && isSummarySignatureNeeded !== false,
  },
};

// This step is supposed to be included *only* in Sharebox flows
export const KIOSK_LOCATION: SelfServiceStep = {
  key: SelfServiceSteps.KIOSK_LOCATION,
  apiKey: SelfServiceAPISteps.DIRECTIONS_TO_KIOSK,
  title: {
    id: 'kioskLocation.title',
    defaultMessage: 'Kiosk location',
  },
  component: KioskLocationId,
  data: {
    // Should be overridden in the flows that include this step
    isVisible: ({ status }) => isNotAnswered(status),
  },
};

const getKeyStepTitle = (selfService: SelfService) => {
  const mobilityEnabled = selfService.mobilityEnabled
    && (selfService.type !== SelfServiceType.CHECK_IN || selfService.keyStatus === KeyStatus.IN_SLOT);
  if (mobilityEnabled) {
    return ({ id: 'key.mobility.title', defaultMessage: 'Swap keys' });
  }

  const type = selfService.type === SelfServiceType.BREAKDOWN ? SelfServiceType.CHECK_IN : selfService.type;
  return {
    id: `key.${type}.title`,
    defaultMessage: (type === SelfServiceType.CHECK_IN ? 'Drop keys' : 'Retrieve keys'),
  };
};

// This step is supposed to be included *only* in Partteam flows
export const PARTTEAM_KEY: SelfServiceStep = {
  key: SelfServiceSteps.PARTTEAM_KEY,
  apiKey: SelfServiceAPISteps.KEYS,
  title: getKeyStepTitle,
  component: PartteamKey,
  data: {
    preventBrowserNavigation: true,
    isVisible: ({ status }) => isNotAnswered(status),
  },
};

// This step is supposed to be included *only* in Sharebox flows
export const SHAREBOX_KEY: SelfServiceStep = {
  key: SelfServiceSteps.SHAREBOX_KEY,
  apiKey: SelfServiceAPISteps.KEYS,
  title: getKeyStepTitle,
  component: ShareboxKey,
  data: {
    hidePrevButton: true,
    isVisible: ({ status }, { connectionType }) => isNotAnswered(status) && connectionType === ConnectionTypeLogin.MFA,
  },
};

export const FINAL_INSTRUCTIONS: SelfServiceStep = {
  key: SelfServiceSteps.FINAL_INSTRUCTIONS,
  apiKey: SelfServiceAPISteps.FINAL_INSTRUCTIONS,
  component: FinalInstructions,
  data: {
    isVisible: true,
    hideHeader: true,
    preventBrowserNavigation: true,
  },
};

export const KIOSK_USAGE: SelfServiceStep = {
  component: KioskUsageForm,
  title: {
    id: 'page.kioskUsage.title',
    defaultMessage: 'Kiosk usage',
  },
  key: SelfServiceSteps.KIOSK_USAGE,
  apiKey: SelfServiceAPISteps.KIOSK_USAGE_QUESTION,
};
