import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { getUrlParam } from 'utils/urlUtils';
import useInactivity from 'hooks/useInactivity';

import { returnURL, TOKEN_NAMES } from 'constants/ErrorPage';
import { INACTIVITY_TIME_ON_ERROR_PAGE } from 'modules/auth/constants';

import {
  getDealerName,
  getContextRequestStatus,
  getFormattedSelfServicePhoneNumber,
  isContextExpired as isContextExpiredSelector,
  shouldDisplayPhoneNumbers as shouldDisplayPhoneNumbersSelector,
} from 'modules/dealers/selectors';

import { Button, Help } from 'components/ui';
import DealersPhone from 'components/ui/DealersPhone';
import './stylesError.css';

const ErrorPage: React.FC<{ target?: string }> = ({ target: targetProps }) => {
  const target = getUrlParam('target') ?? targetProps;

  const dealerName = useSelector(getDealerName);
  const status = useSelector(getContextRequestStatus);
  const isContextExpired = useSelector(isContextExpiredSelector);
  const phoneNumber = useSelector(getFormattedSelfServicePhoneNumber);
  const shouldDisplayPhoneNumbers = useSelector(shouldDisplayPhoneNumbersSelector);

  const isForbidden = status === 403;
  const showReturnButton = !isForbidden && Boolean(target);
  const showSimpleContactInfo = !shouldDisplayPhoneNumbers && Boolean(dealerName && phoneNumber);

  const handleReturnToHomepage = React.useCallback(() => {
    const tokenName = TOKEN_NAMES.find((token) => getUrlParam(token));
    const token = getUrlParam(tokenName);
    window.location.assign(`${returnURL[target]}?${tokenName}=${token}`);
  }, [target]);

  useInactivity({
    // isContextExpired means the token is expired, returning to homepage is useless
    // 403 error means the token doesn't exist, returning to homepage is useless
    paused: isContextExpired || isForbidden,
    onInactive: handleReturnToHomepage,
    seconds: INACTIVITY_TIME_ON_ERROR_PAGE,
  });

  return (
    <div className="flex flex-col items-center">
      {/* eslint-disable-next-line max-len */}
      <div className="flex justify-center items-center w-full h-52 bg-home bg-cover bg-center relative">
        <div className="absolute top-0 right-4">
          <Help />
        </div>
      </div>
      <div className="text-center w-full max-w-7xl py-14 px-7">
        <p className="text-low text-xl">
          {isForbidden && <FormattedMessage id="page.error.403" defaultMessage="403 error" />}
          {!isForbidden && <FormattedMessage id="media.errorTitle" defaultMessage="Error" />}
        </p>
        <h1 className="mb-4 text-default" data-testid="title">
          <FormattedMessage id="page.error.title" defaultMessage="Page not found..." />
        </h1>
        <p className="text-low" data-testid="description">
          {isForbidden && (
            <FormattedMessage
              id="page.error.message"
              defaultMessage="Sorry, the page you are looking for does not exist."
            />
          )}
          {!isForbidden && (
            <>
              <FormattedMessage
                id="page.error.fullMessage"
                defaultMessage="Sorry, the page you are looking for does not exist or has been moved."
                tagName="p"
              />
              {showSimpleContactInfo && (
                <FormattedMessage
                  id="page.error.contact"
                  defaultMessage="Please contact your dealership {dealerName} at {phoneNumber}."
                  values={{ dealerName, phoneNumber }}
                  tagName="p"
                />
              )}
              {shouldDisplayPhoneNumbers && (
                <>
                  <FormattedMessage
                    id="helpLine.message"
                    // eslint-disable-next-line max-len
                    defaultMessage="If something is not working properly, please do not hesitate to contact the helpline below:"
                    tagName="p"
                  />
                  <DealersPhone />
                </>
              )}
            </>
          )}
        </p>
      </div>
      {showReturnButton && (
        <Button onClick={handleReturnToHomepage}>
          <FormattedMessage id="page.error.reload" defaultMessage="Return to homepage" />
        </Button>
      )}
    </div>
  );
};

export default ErrorPage;
