import { push } from 'redux-first-history';
import { addDays, differenceInMilliseconds, startOfDay } from 'date-fns';
import {
  createListenerMiddleware, ForkedTaskAPI, isAnyOf, PayloadAction, TaskAbortError,
} from '@reduxjs/toolkit';

import { locationChange } from 'modules/router/actions';

import { ContextResponse } from 'modules/dealers/types/ContextResponse';

import { AppDispatch } from 'App/Store';
import appApi from 'modules/app/service';
import addHtmlAttribute from 'utils/appUtils';
import { getUrlParam } from 'utils/urlUtils';
import dealersApi from 'modules/dealers/service';
import { AppStartListening } from 'App/ListenerMiddleware';

import { isHomePage, getSearch, isErrorPage } from 'modules/router/selectors';
import { isLocalKiosk, isPublicOnSite as isPublicOnSiteSelector } from 'modules/dealers/selectors';

import { getAppOrigin, getAppTheme } from './selectors';
import packageJson from '../../../package.json';

const RETRY_DELAY = 60 * 1000; // 1 minute
const { version: CURRENT_VERSION } = packageJson;

const listenerMiddleware = createListenerMiddleware();
const startAppListening = listenerMiddleware.startListening as AppStartListening;

const reload = () => window.location.reload();

const getContext = (dispatch: AppDispatch) => {
  // We need a task so it can be cancelled later by the middleware
  const getContextTask = async (forkApi: ForkedTaskAPI): Promise<void> => {
    let hasFetched = false;

    /* eslint-disable no-await-in-loop */
    do {
      try {
        await dispatch(dealersApi.endpoints.getContext.initiate(undefined, { forceRefetch: true })).unwrap();
        hasFetched = true;
      } catch (error) {
        // Stop the loop in case of cancellation
        hasFetched = error instanceof TaskAbortError;
        await forkApi.delay(RETRY_DELAY);
      }
    } while (!hasFetched);
    /* eslint-enable no-await-in-loop */
  };

  return getContextTask;
};

// Fetch context when on error page with a token
startAppListening({
  matcher: locationChange.match,
  effect: async (_, {
    dispatch, getState, fork, pause, cancelActiveListeners,
  }) => {
    cancelActiveListeners();

    const state = getState();
    if (isErrorPage(state) && getUrlParam('token')) {
      const getContextTask = fork(getContext(dispatch));
      await pause(getContextTask.result);
    }
  },
});

// Set app origin after context is sucessfully fetched
// Redirect to error page when token is expired or the query failed
startAppListening({
  matcher: isAnyOf(
    dealersApi.endpoints.getContext.matchFulfilled,
    dealersApi.endpoints.getContext.matchRejected,
  ),
  effect: (action, { dispatch, getState }) => {
    const state = getState();
    const isSuccess = dealersApi.endpoints.getContext.matchFulfilled(action);

    if (isSuccess) {
      const key = getAppOrigin(state);
      const theme = getAppTheme(state);
      addHtmlAttribute('data-origin', key);
      addHtmlAttribute('data-theme', theme);
    }

    // Redirect to the error page if not on it already
    if (!isErrorPage(getState())) {
      // Redirect when the query failed or when the token is expired
      if (!isSuccess || (action as PayloadAction<ContextResponse>).payload?.isTokenExpired) {
        const search = getSearch(state);
        const params = new URLSearchParams(search);
        dispatch(push(`/error?${params.toString()}`));
      }
    }
  },
});

const isJest = typeof process !== 'undefined' && Boolean(process.env?.JEST_WORKER_ID);

/**
  For public on-site devices, fetch app version every day at midnight to force reload if version is different
  For kiosk devices, fetch app version every minute to force reload if version is different
*/
startAppListening({
  // Do not listen when the context is fetch in a jest test.
  predicate: (action) => dealersApi.endpoints.getContext.matchFulfilled(action) && !isJest,
  effect: async (_, {
    dispatch, cancelActiveListeners, getState, take, delay,
  }) => {
    cancelActiveListeners();

    const state = getState();
    const outdoorShareboxToken = getUrlParam('OSToken');
    const isPublicOnSite = isPublicOnSiteSelector(state);
    const isKiosk = Boolean(isLocalKiosk(state) || outdoorShareboxToken);

    if (isPublicOnSite || isKiosk) {
      const now = Date.now();

      // Wait for midnight
      if (isPublicOnSite) {
        const nextDay = startOfDay(addDays(now, 1));
        const initialDelay = differenceInMilliseconds(nextDay, now);
        await delay(initialDelay);
      }

      // Fetch meta.json in app files
      let hasFetched = false;
      let shouldUpdate = false;
      /* eslint-disable no-await-in-loop */
      do {
        try {
          const { version } = await dispatch(appApi.endpoints.getVersion.initiate(undefined, { forceRefetch: true })).unwrap();
          shouldUpdate = CURRENT_VERSION !== version;
          hasFetched = true;
        } catch (error) {
          await delay(RETRY_DELAY);
        }
      } while (!hasFetched);
      /* eslint-enable no-await-in-loop */

      if (shouldUpdate) {
        // Wait for user to change their location to the homepage
        let isHome = isHomePage(getState());
        while (!isHome) {
          // eslint-disable-next-line no-await-in-loop
          await take(locationChange.match);
          isHome = isHomePage(getState());
        }

        // If version is different and we are on homepage and it's midnight or more, we reload
        reload();

        // If, for whatever reason, the reload fails, we will try again when user interacts with the page
        const options = { capture: true, once: true };
        window.addEventListener('visibilitychange', reload, options);
        window.addEventListener('keydown', reload, options);
        window.addEventListener('pointermove', reload, options);
      }
    }
  },
});

export default listenerMiddleware;
