import React from 'react';
import { useIntl } from 'react-intl';

import { RootState } from 'App/Store';
import { useSelector } from 'hooks';

import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { getFormattedFormData, isMobilityAccepted } from 'modules/form/selectors';

import { useGetSelfServiceByIdQuery, useLazyCreatePreviewPDFQuery } from 'modules/selfServices/service';

import { isCheckoutSelfService } from 'modules/selfServices/selectors';
import { SignatureStatus } from 'modules/selfServices/types/SelfService';

import { SignDocument } from '../types';
import { MediaType } from '../../../types/AbstractMedia';

const DOCUMENT_KEYS = {
  SUMMARY: 'SUMMARY',
  WORK_ORDER: 'WORK_ORDER',
  MOBILITY: 'MOBILITY',
  DELIVERY_DOCUMENT: 'DELIVERY_DOCUMENT',
};

const useDocuments = () => {
  const intl = useIntl();

  const isCheckOut = useSelector(isCheckoutSelfService);
  const selfServiceId = useSelector(getSelectedSelfServiceId);
  const hasAcceptedMobility = useSelector(isMobilityAccepted);

  const { data: selfService } = useGetSelfServiceByIdQuery({ id: selfServiceId });

  const formattedData = useSelector((state: RootState) => getFormattedFormData(state, selfService));

  const [retrieveSummaryDocument, { isLoading }] = useLazyCreatePreviewPDFQuery();

  const displayWorkOrderDocument = Boolean(selfService.workOrder?.media);
  const displayDeliveryDocument = Boolean(selfService?.mobility?.deliveryDocument?.media);
  const displayMobilityDocument = hasAcceptedMobility && Boolean(selfService.mobility?.contract?.media);

  const CHECK_IN_DOCUMENTS = React.useMemo(() => ({
    [DOCUMENT_KEYS.SUMMARY]: {
      read: false,
      path: undefined,
      type: MediaType.DOCUMENT,
      id: DOCUMENT_KEYS.SUMMARY,
      get: retrieveSummaryDocument,
      status: SignatureStatus.UNSIGNED,
      filename: intl.formatMessage({ id: 'signature.documents.SUMMARY', defaultMessage: 'Check-in summary' }),
    } as SignDocument,
    ...(displayWorkOrderDocument ? {
      [DOCUMENT_KEYS.WORK_ORDER]: {
        type: MediaType.DOCUMENT,
        id: DOCUMENT_KEYS.WORK_ORDER,
        status: selfService?.workOrder.status,
        path: selfService.workOrder.media.path,
        read: selfService.workOrder.status === SignatureStatus.SIGNED,
        filename: intl.formatMessage({ id: 'signature.documents.WORK_ORDER', defaultMessage: 'Work order' }),
      },
    } : {}),
    ...(displayMobilityDocument ? {
      [DOCUMENT_KEYS.MOBILITY]: {
        type: MediaType.DOCUMENT,
        id: DOCUMENT_KEYS.MOBILITY,
        status: selfService.mobility.contract.status,
        path: selfService.mobility.contract.media.path,
        read: selfService.mobility.contract.status === SignatureStatus.SIGNED,
        filename: intl.formatMessage({ id: 'mobilityContract.title', defaultMessage: 'Mobility contract' }),
      },
    } : {}),
  }), [intl, selfService, displayWorkOrderDocument, displayMobilityDocument, retrieveSummaryDocument]);

  const CHECK_OUT_DOCUMENTS = React.useMemo(() => ({
    ...(displayDeliveryDocument ? {
      [DOCUMENT_KEYS.DELIVERY_DOCUMENT]: {
        type: MediaType.DOCUMENT,
        id: DOCUMENT_KEYS.DELIVERY_DOCUMENT,
        status: selfService.mobility.deliveryDocument.status,
        path: selfService.mobility.deliveryDocument.media.path,
        read: selfService.mobility.deliveryDocument.status === SignatureStatus.SIGNED,
        filename: intl.formatMessage({ id: 'signature.documents.DELIVERY_DOCUMENT', defaultMessage: 'Vehicle delivery document' }),
      },
    } : {}),
  }), [intl, selfService, displayDeliveryDocument]);

  const [hasError, setHasError] = React.useState(false);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = React.useState<number>();
  const [documents, setDocuments] = React.useState<SignDocument[]>(
    isCheckOut ? Object.values(CHECK_OUT_DOCUMENTS) : Object.values(CHECK_IN_DOCUMENTS),
  );

  const updateDocument = React.useCallback((updatedDocument: SignDocument) => {
    setDocuments((prevDocuments) => prevDocuments.map((document) => (document.id === updatedDocument.id ? updatedDocument : document)));
  }, [setDocuments]);

  const readDocument = React.useCallback(async (id: string) => {
    const index = documents.findIndex((doc) => doc.id === id);
    setSelectedDocumentIndex(index);
    const document = documents[index];

    try {
      setHasError(false);
      const path = document.path ?? await document.get({ id: selfServiceId, body: formattedData }).unwrap();
      updateDocument({ ...document, path, read: true });
    } catch (e) {
      setHasError(true);
    }
  }, [documents, formattedData, selfServiceId, updateDocument]);

  const readNextDocument = React.useCallback(() => {
    const nextIndex = selectedDocumentIndex === documents.length - 1 ? 0 : selectedDocumentIndex + 1;
    const { id: nextDocumentId } = documents[nextIndex];
    readDocument(nextDocumentId);
  }, [documents, readDocument, selectedDocumentIndex]);

  const hasReadAllDocuments = React.useMemo(() => documents.every(({ read }) => read), [documents]);

  return React.useMemo(() => ({
    hasError,
    isLoading,
    documents,
    readDocument,
    readNextDocument,
    hasReadAllDocuments,
    documentIndex: selectedDocumentIndex,
    setDocumentIndex: setSelectedDocumentIndex,
  }), [documents, selectedDocumentIndex, readDocument, hasReadAllDocuments, readNextDocument, isLoading, hasError]);
};

export default useDocuments;
