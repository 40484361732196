declare global {
  interface Window {
    apiElectron: {
      sendMessage: (message: string) => void,
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export const openOfflineMode = () => {
  window.apiElectron.sendMessage('openOfflineMode');
};
