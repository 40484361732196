import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { Help, Spinner } from 'components/ui';

import SlotsIcon from 'assets/icons/slots.svg';

import useUsedSlots from './useUsedSlots';

interface HeaderProps {
  disableNavigation?: boolean;
}

const Header: React.FC<HeaderProps> = ({ disableNavigation }) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const [date, setDate] = React.useState(new Date());

  const {
    slots, usedSlots, isFetching, isSuccess,
  } = useUsedSlots({ refetch: true });

  React.useLayoutEffect(() => {
    const timeoutId = setTimeout(() => setDate(new Date()), 60000);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [date]);

  const handleClickSlots = React.useCallback(() => {
    if (!disableNavigation) {
      navigate(`/admin/slots${search}`, { replace: pathname === '/admin/slots' });
    }
  }, [disableNavigation, navigate, pathname, search]);

  return (
    <div className="flex w-full justify-between items-center z-20 px-12 fixed bg-white/90 backdrop-blur-sm">
      <div className="w-64" data-testid="admin-date">
        <p className="!text-xl font-bold text-default">
          <FormattedDate value={date} year="numeric" month="short" day="numeric" hour="2-digit" minute="2-digit" />
        </p>
      </div>
      <div
        className="flex items-center justify-center px-6 py-4 rounded-full bg-card-bg gap-5 cursor-pointer"
        onClick={handleClickSlots}
        data-testid="header-slots"
      >
        {isFetching && <Spinner />}
        {isSuccess && (
          <>
            <p className="!text-xl font-bold text-default" data-testid="usedSlots">
              <FormattedMessage
                id="admin.slotsSummary"
                defaultMessage="{used} of {max} slots used"
                values={{ used: usedSlots, max: slots }}
              />
            </p>
            <SlotsIcon className="w-12 aspect-square" />
          </>
        )}
      </div>
      <div className="flex justify-end w-64">
        <Help />
      </div>
    </div>
  );
};

export default Header;
