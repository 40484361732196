import React from 'react';
import { addSeconds } from 'date-fns';
import { FormattedMessage } from 'react-intl';

import useCountdown from 'hooks/useCountdown';

import { b } from 'utils/i18nUtils';
import { createDictionary } from 'utils/objectUtils';
import { useSendPayingLinkMutation } from 'modules/selfServices/service';

import PaymentStatus from 'modules/onlinePayment/types/PaymentStatus';

import QRCodeViewer from 'components/ui/QRCodeViewer';
import Button, { ButtonType } from 'components/ui/Button';

import instructionsGif from 'assets/images/qrCodeScan.gif';

import ProcessingStepModal, { ProcessingStep } from '../../ui/ProcessingStepModal';

const INSTRUCTION_TIME = 3400;

interface Props {
  link: string;
  selfServiceId: string;
  paymentStatus: PaymentStatus;
  open?: boolean;
  onCancel?: () => void;
}

const PROCESSING_STATUS = createDictionary({
  [PaymentStatus.ERROR]: ProcessingStep.ERROR,
  [PaymentStatus.PENDING]: ProcessingStep.PENDING,
  [PaymentStatus.SUCCESS]: ProcessingStep.SUCCESS,
}, ProcessingStep.INIT);

const PayingModal: React.FC<Props> = ({
  open, selfServiceId, paymentStatus, link, onCancel,
}) => {
  const instructionRef = React.useRef<HTMLImageElement>();

  const [countdownDate, setCountdownDate] = React.useState(new Date());
  const [showInstruction, setShowInstruction] = React.useState(false);

  const { countdown } = useCountdown({ date: countdownDate });
  const [sendPayingLink, { isLoading: isSending }] = useSendPayingLinkMutation();

  const currentStep = PROCESSING_STATUS[paymentStatus];
  const isWaitingForUser = paymentStatus === PaymentStatus.WAITING_FOR_USER;

  const handleOnClickSend = React.useCallback(
    () => {
      setCountdownDate(addSeconds(new Date(), 60));
      sendPayingLink({ id: selfServiceId });
    },
    [selfServiceId, sendPayingLink],
  );

  React.useEffect(() => {
    if (open) {
      setShowInstruction(isWaitingForUser);
    }
  }, [open, isWaitingForUser]);

  React.useLayoutEffect(() => {
    if (showInstruction) {
      const callback = () => setTimeout(() => setShowInstruction(false), INSTRUCTION_TIME);

      const instruction = instructionRef.current;

      if (instruction) {
        if (instruction.complete) {
          callback();
        } else {
          instruction.addEventListener('load', callback, { once: true });
          return () => instruction.removeEventListener('load', callback);
        }
      }
    }

    return undefined;
  }, [instructionRef, showInstruction]);

  const config = React.useMemo(
    () => ({
      [ProcessingStep.INIT]: {
        title: !showInstruction && (
          <FormattedMessage id="page.finalInvoice.qrcode.title" defaultMessage="QR code payment" tagName="h2" />
        ),
        hideButton: showInstruction,
        buttonText: <FormattedMessage id="steps.back" defaultMessage="Back" />,
        content: (
          <>
            {showInstruction && (
              <img
                alt=""
                ref={instructionRef}
                src={instructionsGif}
                className="w-full aspect-square"
                data-testid="instructions-animation"
              />
            )}
            {!showInstruction && isWaitingForUser && (
              <>
                <div className="flex justify-center w-full px-6 py-12 bg-gray-100 rounded-2xl">
                  <QRCodeViewer content={link} />
                </div>
                <p className="text-center">
                  <FormattedMessage
                    id="page.finalInvoice.qrcode.instructions"
                    // eslint-disable-next-line max-len
                    defaultMessage="<b>Scan the QR code</b> with your mobile device's camera to <b>complete the payment</b>."
                    tagName={undefined}
                    values={{ b }}
                  />
                </p>
                <Button
                  testId="send-link"
                  loading={isSending}
                  type={ButtonType.LIGHT}
                  onClick={handleOnClickSend}
                  disabled={isSending || countdown > 0}
                  className="underline !p-0 disabled:font-normal disabled:no-underline"
                >
                  {countdown === 0 && (
                    <FormattedMessage id="page.vehicleCheck.qrCodeStep.sendLink" defaultMessage="Send me the link" />
                  )}
                  {countdown > 0 && (
                    <FormattedMessage
                      id="login.resend.resend"
                      defaultMessage="Resent ({countdown})"
                      values={{ countdown }}
                    />
                  )}
                </Button>
              </>
            )}
          </>
        ),
      },
      [ProcessingStep.PENDING]: {
        wrapperClassName: 'flex-col-reverse',
        title: (
          <FormattedMessage
            id="page.finalInvoice.modal.pending.title"
            defaultMessage="Payment in progress"
            tagName="h2"
          />
        ),
        content: (
          <FormattedMessage
            id="page.finalInvoice.qrcode.waiting"
            defaultMessage="<b>Waiting for payment</b>."
            values={{ b }}
            tagName="p"
          />
        ),
      },
      [ProcessingStep.SUCCESS]: {
        wrapperClassName: 'flex-col-reverse',
        buttonText: <FormattedMessage id="socket.modal.confirm" defaultMessage="Continue" />,
        title: (
          <FormattedMessage
            id="page.finalInvoice.modal.kioskInstruction.paidTitle"
            defaultMessage="Payment completed"
            tagName="h2"
          />
        ),
        content: (
          <FormattedMessage
            id="page.finalInvoice.modal.kioskInstruction.paidContent"
            defaultMessage="Thank you for <b>your payment!</b>"
            values={{ b }}
            tagName="p"
          />
        ),
      },
      [ProcessingStep.ERROR]: {
        title: (
          <FormattedMessage
            id="page.finalInvoice.payment.error.unknownError"
            defaultMessage="Payment error"
            tagName="h2"
          />
        ),
        buttonText: <FormattedMessage id="steps.back" defaultMessage="Back" />,
      },
    }),
    [showInstruction, isWaitingForUser, link, isSending, countdown, handleOnClickSend],
  );

  return <ProcessingStepModal open={open} config={config} step={currentStep} onClick={onCancel} />;
};

export default PayingModal;
