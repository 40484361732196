import classNames, { Argument } from 'classnames';
import React from 'react';

interface ProgressBarProps {
  progress: number;
  className?: Argument;
  barClassName?: Argument;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, className, barClassName }) => (
  <div className={classNames('w-full min-h-2 rounded-full bg-card-bg overflow-hidden', className)}>
    <div
      className={classNames(
        'min-h-2 h-full bg-brand-high rounded-full transition-all duration-1000 ease-linear',
        {
          'bg-warning': progress <= 25,
          'bg-danger-default': progress <= 10,
          hidden: progress < 0,
        },
        barClassName,
      )}
      style={{ width: `${progress}%` }}
    />
  </div>
);

export default ProgressBar;
