import React from 'react';
import Linkify from 'linkify-react';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { FormattedMessage, useIntl } from 'react-intl';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { INACTIVITY_TIME_ON_FINAL_INSTRUCTIONS } from 'modules/auth/constants';

import { SelfServiceOrigin, SelfServiceType } from 'modules/selfServices/types/SelfService';

import {
  useGetFinalInstructionsQuery,
  useGetSelfServiceByIdQuery,
  useGetSelfServicePdfMutation,
} from 'modules/selfServices/service';
import useAnalytics from 'hooks/useAnalytics';
import useInactivity from 'hooks/useInactivity';
import { useDispatch, useSelector } from 'hooks';
import useLabelTranslation from 'hooks/useLabelTranslation';

import { logout } from 'modules/auth/actions';

import {
  getOrigin,
  isPublicDevice,
  isRemoteKiosk,
} from 'modules/dealers/selectors';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { getVehicleCheckType } from 'modules/vehicleCheck/selectors';
import { getUnreadCountText, haveUnreadMessages } from 'modules/chat/selectors';
import { getParkingInfo, getSelfServiceType, hasClickableInstruction } from 'modules/selfServices/selectors';

import Modal from 'components/ui/Modal';
import Loader from 'components/ui/Spinner';
import Carousel from 'components/ui/Carousel';
import Attachments from 'components/ui/Attachments';
import Button, { ButtonType } from 'components/ui/Button';

import ChatIcon from 'assets/icons/chat.svg';
import DownloadIcon from 'assets/icons/download.svg';
import ParkedCarIcon from 'assets/icons/parkedCar.svg';

import ChatModal from 'components/ChatModal';
import CourtesyVehicleModal from 'components/CourtesyVehicle/CourtesyVehicleModal';
import ParkingContent from '../Parking/ParkingContent';

const FinalInstructions: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { onClick } = useAnalytics();
  const { getLabelTranslation } = useLabelTranslation();
  const { selfServiceId: selfServiceIdParams } = useParams();

  const origin = useSelector(getOrigin);
  const parking = useSelector(getParkingInfo);
  const isRemote = useSelector(isRemoteKiosk);
  const type = useSelector(getSelfServiceType);
  const isPublic = useSelector(isPublicDevice);
  const unreadCount = useSelector(getUnreadCountText);
  const hasUnreadCount = useSelector(haveUnreadMessages);
  const vehicleCheckType = useSelector(getVehicleCheckType);
  const hasClickableDescription = useSelector(hasClickableInstruction);
  const selfServiceId = useSelector(getSelectedSelfServiceId) ?? selfServiceIdParams;

  const [isChatModalOpen, setChatModal] = React.useState(false);
  const [isParkingModalOpen, setParkingModal] = React.useState(false);
  const [isCourtesyModalOpen, setCourtesyModal] = React.useState(false);
  const [isCarouselFullscreen, setCarouselFullscreen] = React.useState(false);

  const [downloadPdf, { isLoading: isDownloading }] = useGetSelfServicePdfMutation();
  const { data } = useGetSelfServiceByIdQuery(selfServiceId ? { id: selfServiceId } : skipToken);
  const { data: instructions, isLoading, isSuccess } = useGetFinalInstructionsQuery({ selfServiceId });

  const isCheckout = type === SelfServiceType.CHECK_OUT;
  const isBreakdown = type === SelfServiceType.BREAKDOWN;
  const isCheckin = type === SelfServiceType.CHECK_IN || vehicleCheckType === SelfServiceType.CHECK_IN;

  const displayCourtesy = isCheckin && Boolean(data?.mobility?.vehicle);
  const displayDownloadSummary = (!isPublic || isRemote) && (isCheckin || isBreakdown);
  const displayFinalInvoice = !isPublic && isCheckout && data?.finalInvoice?.medias?.length > 0;
  const displayChat = instructions?.isChatEnabled && (origin === SelfServiceOrigin.HOME || isRemote || !isPublic);
  const displayParkingLocation = Boolean(parking?.spotNumber || parking?.x >= 0 || parking?.y >= 0) && isCheckout;

  const Instruction = React.useMemo(
    () => (hasClickableDescription ? Linkify : React.Fragment),
    [hasClickableDescription],
  );

  const instructionProps = React.useMemo( // This avoids giving `options` to a Fragment, which causes a warning
    () => (hasClickableDescription ? { options: { target: { url: '_blank' } } } : {}),
    [hasClickableDescription],
  );

  let finalInstructionMessage;
  if (isSuccess && instructions?.message) {
    finalInstructionMessage = getLabelTranslation(instructions.message);
  }

  const handleShowChat = React.useCallback(() => setChatModal(true), []);
  const handleShowParking = React.useCallback(() => setParkingModal(true), []);
  const handleShowCourtesy = React.useCallback(() => setCourtesyModal(true), []);
  const handleCarouselOpen = React.useCallback(() => setCarouselFullscreen(true), []);
  const handleCarouselClose = React.useCallback(() => setCarouselFullscreen(false), []);

  const handleDownload = React.useCallback(() => {
    onClick(
      { action: 'DownloadFile', category: 'FINAL_INSTRUCTIONS' },
      downloadPdf,
    )({
      id: selfServiceId,
      filename: `${intl.formatMessage({
        id: 'page.finalInstructions.downloadPdf.filename',
        defaultMessage: 'Check-in summary',
      })}.pdf`,
    });
  }, [downloadPdf, intl, selfServiceId, onClick]);

  const handleInactivity = React.useCallback(() => {
    if (isPublic) {
      dispatch(logout());
    }
  }, [dispatch, isPublic]);

  useInactivity({
    // Setting seconds to 0 will avoid a rerender per second when not needed
    seconds: isPublic ? INACTIVITY_TIME_ON_FINAL_INSTRUCTIONS : 0,
    onInactive: handleInactivity,
  });

  const buttons = React.useMemo(
    () => [
      displayParkingLocation && {
        testId: 'showParking',
        onClick: handleShowParking,
        label: <FormattedMessage id="page.finalInstructions.parking" defaultMessage="Show my parking location" />,
        icon: <ParkedCarIcon className="size-6 kiosk:size-12" />,
        addon: (
          <Modal open={isParkingModalOpen} onClose={setParkingModal} className="w-full max-w-lg kiosk:max-w-4xl kioskSharebox:max-w-2xl">
            <div className="content pb-0">
              <ParkingContent hideInstructions maps={data?.parkingMap?.maps} {...data?.parking} />
            </div>
          </Modal>
        ),
      },
      displayCourtesy && {
        testId: 'showCourtesy',
        onClick: handleShowCourtesy,
        label: <FormattedMessage id="courtesyVehicle.title" defaultMessage="Courtesy vehicle" />,
        icon: <InformationCircleIcon className="size-6 kiosk:size-12" />,
        addon: <CourtesyVehicleModal open={isCourtesyModalOpen} onClose={setCourtesyModal} />,
      },
      displayChat && {
        testId: 'showChat',
        onClick: handleShowChat,
        label: (
          <div className="flex-grow flex items-center justify-between gap-2">
            <FormattedMessage id="page.finalInstructions.chat" defaultMessage="Chat with the dealership" />
            {hasUnreadCount && (
              <div className="rounded-full p-1.5 bg-brand-default">
                <span className="min-w-4 flex leading-4 items-center text-xs text-white justify-center">
                  {unreadCount}
                </span>
              </div>
            )}
          </div>
        ),
        icon: <ChatIcon className="size-6 kiosk:size-12" />,
        addon: (
          <ChatModal
            open={isChatModalOpen}
            onClose={setChatModal}
            selfServiceId={selfServiceId}
            maxMessageLength={instructions.maxMessageLength}
          />
        ),
      },
      displayDownloadSummary && {
        loading: isDownloading,
        onClick: handleDownload,
        disabled: isDownloading,
        testId: 'downloadSummary',
        icon: <DownloadIcon className="size-6 kiosk:size-12" />,
        label: <FormattedMessage id="page.finalInstructions.button" defaultMessage="Download summary (PDF)" />,
      },
    ].filter(Boolean),
    // eslint-disable-next-line max-len
    [data?.parking, data?.parkingMap?.maps, displayChat, displayCourtesy, displayDownloadSummary, displayParkingLocation, handleDownload, handleShowChat, handleShowCourtesy, handleShowParking, instructions?.maxMessageLength, instructions?.unreadCount, isChatModalOpen, isCourtesyModalOpen, isDownloading, isParkingModalOpen, selfServiceId],
  );

  return (
    <>
      {isLoading && <Loader className="homePage" />}
      {isSuccess && (
        <div className="flex flex-col items-center">
          <div className="main-content kiosk:pt-12 pt-5">
            <div className="flex flex-col text-center w-full mb-3">
              <h1 className="text-default">
                <FormattedMessage
                  id="page.finalInstructions.title"
                  defaultMessage="Thank you"
                />
              </h1>
              <p className="w-full text-low mb-3 mt-3 whitespace-pre-wrap break-words">
                <Instruction {...instructionProps}>
                  {finalInstructionMessage}
                </Instruction>
              </p>
            </div>
            <div className="flex gap-4 flex-col rounded-xl overflow-hidden w-full">
              {instructions?.medias?.length > 0 && (
                <Carousel
                  medias={instructions.medias}
                  onClose={handleCarouselClose}
                  fullscreen={isCarouselFullscreen}
                  onMediaClick={handleCarouselOpen}
                />
              )}
            </div>
            <div className="flex flex-col gap-4 kiosk:gap-8 w-full">
              {buttons.length > 0 && (
                // eslint-disable-next-line max-len
                <div className="flex flex-col gap-2 kiosk:gap-4 items-center p-3 kiosk:p-6 w-full rounded-[42px] kiosk:rounded-[72px] bg-card-bg">
                  {buttons.map(({
                    label, icon, addon, ...rest
                  }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={index}>
                      <Button
                        type={ButtonType.WHITE}
                        className="flex items-center kiosk:!p-4 kiosk:!pl-9 justify-between w-full"
                        {...rest}
                      >
                        {label}
                        {icon && <div className="inline-block p-3 kiosk:p-6 rounded-full bg-btn-primary-icon-bg ml-2">{icon}</div>}
                      </Button>
                      {addon}
                    </React.Fragment>
                  ))}
                </div>
              )}
              {displayFinalInvoice && (
                // eslint-disable-next-line max-len
                <div className="flex flex-col gap-2 kiosk:gap-4 items-center p-3 kiosk:p-6 w-full rounded-[42px] kiosk:rounded-[72px] bg-card-bg">
                  <FormattedMessage
                    tagName="h2"
                    id="page.finalInstructions.documents"
                    defaultMessage="Final invoice documents"
                  />
                  <Attachments
                    documents={data.finalInvoice.medias}
                    className="w-full"
                    buttonProps={{
                      className: 'w-5/6 max-w-lg mb-5',
                      type: ButtonType.TERTIARY,
                    }}
                    singleDownloadTitle={(
                      <FormattedMessage
                        id="page.finalInvoice.buttons.download"
                        defaultMessage="Download invoice"
                      />
                    )}
                  />
                </div>
              )}
              {isPublic && (
                <div className="w-full mb-5 px-3 kiosk:px-6 text-center">
                  <Button
                    onClick={handleInactivity}
                    className="w-full"
                    testId="goHome"
                  >
                    <FormattedMessage id="quit" defaultMessage="Quit" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FinalInstructions;
