import React from 'react';
import classNames from 'classnames';
import debounce from 'debounce';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';

import { useSelector } from 'hooks';
import { br } from 'utils/i18nUtils';

import { KeyStatus } from 'modules/kiosk/types/Kiosk';
import { KeyType } from 'modules/kiosk/types/GetReadySelfServices';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import {
  getAllSelfServices,
  getKioskId,
  getSelfServiceById,
  getSelfServicesIsLoading,
  hasError as hasErrorSelector,
  isIdle,
} from 'modules/kiosk/selectors';

import { closeKeysSafe, openKeysSafe } from 'modules/kiosk/actions';

import { useGetReadySelfServicesQuery } from 'modules/kiosk/service';

import { Button, Input } from 'components/ui';
import Popup from 'components/ui/Popup';
import NoData from 'components/ui/NoData';
import { ButtonType } from 'components/ui/Button';

import Arrow from 'assets/icons/arrow.svg';
import ArrowDirection from 'assets/icons/Arrow';

import ManagementItem from './ManagementItem';
import KeysManagementModal from '../Keys/PartteamKey/KeysManagementModal';
import AdminKeysManagementModalContent from './AdminKeysManagementModalContent';

import KeysError from '../Keys/PartteamKey/KeysError';
import useUsedSlots from './useUsedSlots';
import AdminCheckKeysModalContent from './AdminCheckKeysModalContent';

const NB_ROW_PER_PAGE = 6;

const Management: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const [showFullPopup, setShowFullPopup] = React.useState(false);
  const [selfServiceId, setSelfServiceId] = React.useState(null);
  const [hasAccepted, setHasAccepted] = React.useState<boolean>();
  const [debouncedSearch, setDebouncedSearch] = React.useState('');

  const { type: selfServiceType } = useParams<{ type: SelfServiceType }>();

  const kioskId = useSelector(getKioskId);
  const doorsIdle = useSelector(isIdle);
  const hasError = useSelector(hasErrorSelector);
  const isLoading = useSelector(getSelfServicesIsLoading);
  const selfServices = useSelector(getAllSelfServices);
  const selfService = useSelector((state) => getSelfServiceById(state, selfServiceId));

  const showContent = doorsIdle && !hasError;
  const { isFull } = useUsedSlots();

  const shouldCheckSlot = selfService?.finalized === false;

  useGetReadySelfServicesQuery(
    { kioskId, type: selfServiceType, text: debouncedSearch || undefined },
    { refetchOnMountOrArgChange: true },
  );

  const maxPage = React.useMemo(
    () => Math.ceil((selfServices.length ?? 1) / NB_ROW_PER_PAGE) - 1,
    [selfServices.length],
  );

  const dataToDisplay = React.useMemo(() => {
    const start = page * NB_ROW_PER_PAGE;
    const end = Math.min(start + NB_ROW_PER_PAGE, selfServices.length);
    return selfServices.slice(start, end);
  }, [selfServices, page]);

  const handlePreviousPage = React.useCallback(() => setPage((currentPage) => Math.max(0, currentPage - 1)), []);

  const handleNextPage = React.useCallback(() => {
    setPage((currentPage) => Math.min(maxPage, currentPage + 1));
  }, [maxPage]);

  const handleCloseFullPopup = React.useCallback(() => setShowFullPopup(false), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchSelfService = React.useCallback(
    debounce((value: string) => {
      setPage(0);
      setDebouncedSearch(value);
    }, 750),
    [],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    searchSelfService(e.target.value);
  };

  const handleClear = () => {
    setPage(0);
    setSearch('');
    setDebouncedSearch('');
  };

  const handleOpenDoor = (targetId: string) => () => {
    const { keyStatus } = dataToDisplay.find(({ id }) => targetId === id);
    if (isFull && keyStatus !== KeyStatus.IN_SLOT) {
      setShowFullPopup(true);
    } else {
      setSelfServiceId(targetId);
      dispatch(
        openKeysSafe({
          selfServiceId: targetId,
          selfServiceType,
          asAdmin: true,
          keyStatus,
        }),
      );
    }
  };

  const handleCloseDoor = (accepted?: boolean) => {
    const userChoice = typeof accepted === 'boolean' ? accepted : hasAccepted; // use in case of error when retrying
    setHasAccepted(userChoice);
    const { keyStatus, type, keyType } = dataToDisplay.find(({ id }) => selfServiceId === id);
    dispatch(
      closeKeysSafe({
        keyType,
        keyStatus,
        selfServiceId,
        asAdmin: true,
        hasAccepted: userChoice,
        selfServiceType: type,
      }),
    );
  };

  const getActionTitle = React.useCallback((keyStatus: KeyStatus, slotId: string, keyType: KeyType, finalize?: boolean) => {
    const isCheckOut = selfServiceType === SelfServiceType.CHECK_OUT && !slotId;

    if (finalize === false) {
      return {
        id: 'admin.manage.action.check',
        defaultMessage: 'Check slot',
      };
    }

    // The only case where we can drop a mobility key is when we are in check-in and the key is not in the slot
    // In this case, we don't have a keyType because we have no key
    if (selfServiceType === SelfServiceType.CHECK_IN && keyStatus === KeyStatus.NONE) {
      return {
        id: 'admin.manage.action.dropMobility',
        defaultMessage: 'Drop mobility key',
      };
    }
    if (keyType === KeyType.MOBILITY) {
      return {
        id: 'admin.manage.action.retrieveMobility',
        defaultMessage: 'Retrieve mobility key',
      };
    }

    return {
      id: `admin.manage.action.${isCheckOut ? 'drop' : 'retrieve'}`,
      defaultMessage: `${isCheckOut ? 'Drop' : 'Retrieve'} Key`,
    };
  }, [selfServiceType]);

  return (
    <div
      className={classNames('main-content transition-all ease-out duration-500', {
        '!pb-0 main-content_admin': showContent,
      })}
    >
      <div className="flex flex-col items-center">
        <h1
          className={classNames('leading-normal transition-all ease-out duration-500', {
            '!text-5xl': !showContent,
          })}
        >
          <FormattedMessage
            id={`home.${selfServiceType === SelfServiceType.CHECK_IN ? 'checkIn' : 'checkOut'}`}
            defaultMessage={selfServiceType === SelfServiceType.CHECK_IN ? 'Check-in' : 'Check-out'}
          />
        </h1>
        <span className="description text-2xl">
          <FormattedMessage
            id={`admin.manage.title.${selfServiceType}`}
            defaultMessage={`Manage your ${selfServiceType.toLowerCase()}`}
          />
        </span>
      </div>

      <div
        className={classNames('content flex grow flex-col admin-content-transition--fade-out', {
          'admin-content-transition--fade-out-leave': !showContent,
        })}
      >
        <Input
          value={search}
          label={intl.formatMessage({ id: 'search', defaultMessage: 'Search' })}
          className="mb-4"
          autoCorrect="off"
          spellCheck="false"
          inputMode="text"
          loading={isLoading}
          onClear={handleClear}
          icon={<MagnifyingGlassIcon className="size-8" />}
          onChange={handleSearch}
          data-testid="search-input"
        />
        <div className="grow gap-4 flex flex-col">
          {selfServices.length === 0 && (
            <NoData
              label={(
                <p className="text-center small">
                  <FormattedMessage
                    id={`admin.manage.${debouncedSearch ? 'search' : 'page'}.emptyResult`}
                    defaultMessage={
                      debouncedSearch
                        ? 'Sorry we couldn\'t find any matches for "{search}".{br}Please try again using another term.'
                        : 'No files yet'
                    }
                    values={{ search: debouncedSearch, br }}
                  />
                </p>
              )}
            />
          )}
          {dataToDisplay.map((sService) => (
            <ManagementItem
              {...sService}
              key={sService.id}
              onClick={handleOpenDoor(sService.id)}
              actionTitle={getActionTitle(sService.keyStatus, sService.slotId, sService.keyType, sService.finalized)}
            />
          ))}
          {showFullPopup && (
            <Popup onClose={handleCloseFullPopup}>
              <h2 className="py-16 text-center">
                <FormattedMessage
                  id="kiosk.fullWarning"
                  defaultMessage="The kiosk is full. It is no longer possible to drop keys."
                  tagName="h2"
                />
              </h2>
            </Popup>
          )}
        </div>
        {maxPage > 0 && (
          <div className="flex justify-between w-full pb-10">
            {page > 0 && (
              <Button className="flex" type={ButtonType.SECONDARY} onClick={handlePreviousPage} testId="previousBtn">
                <Arrow className={classNames('size-8 mr-4', ArrowDirection.LEFT)} />
                <FormattedMessage
                  id="admin.manage.pageLabel"
                  defaultMessage="Page {pageNumber}"
                  values={{ pageNumber: page }}
                />
              </Button>
            )}
            <div />
            {page < maxPage && (
              <Button className="flex" type={ButtonType.SECONDARY} onClick={handleNextPage} testId="nextBtn">
                <FormattedMessage
                  id="admin.manage.pageLabel"
                  defaultMessage="Page {pageNumber}"
                  values={{ pageNumber: page + 2 }}
                />
                <Arrow className="size-8 ml-4" />
              </Button>
            )}
          </div>
        )}
      </div>

      <KeysManagementModal>
        {hasError && <KeysError onOpen={handleOpenDoor(selfServiceId)} onClose={handleCloseDoor} />}
        {!hasError && selfService && (
          <>
            {!shouldCheckSlot && <AdminKeysManagementModalContent {...selfService} onClose={handleCloseDoor} />}
            {shouldCheckSlot && <AdminCheckKeysModalContent {...selfService} onClose={handleCloseDoor} />}
          </>
        )}
      </KeysManagementModal>
    </div>
  );
};

export default Management;
