import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from 'hooks';

import { logout } from 'modules/auth/actions';
import { closeModal } from 'modules/healthCheck/actions';
import { hasToken as hasTokenSelector } from 'modules/auth/selectors';
import {
  isPublicDevice,
  shouldDisplayPhoneNumbers as shouldDisplayPhoneNumbersSelector,
} from 'modules/dealers/selectors';

import { br } from 'utils/i18nUtils';

import NoWifi from 'assets/icons/noWifi.svg';

import Modal from './Modal';
import Button from './Button';
import DealersPhone from './DealersPhone';

const OfflineModal = () => {
  const dispatch = useDispatch();
  const ref = React.useRef<HTMLButtonElement>();

  const isPublic = useSelector(isPublicDevice);
  const hasToken = useSelector(hasTokenSelector);
  const shouldDisplayPhoneNumbers = useSelector(shouldDisplayPhoneNumbersSelector);

  const shouldLogout = isPublic && hasToken;
  const shouldClose = !isPublic || !hasToken;

  const handleClose = React.useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const handleLogout = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Modal focusRef={ref} open className="w-full max-w-md kiosk:max-w-4xl kioskSharebox:max-w-2xl" title={<FormattedMessage id="offline.feedback" defaultMessage="You are currently offline" tagName="h2" />}>
      <div className="flex flex-col items-center">
        <div className="rounded-full size-80 bg-warning-lowest flex items-center justify-center mt-1">
          <div className="rounded-full size-40 bg-warning-default flex items-center justify-center">
            <NoWifi className="absolute w-12 aspect-square" />
          </div>
        </div>
        <p className="text-center mt-10">
          <FormattedMessage
            id={`offline.${isPublic ? 'publicDeviceDescription' : 'description'}`}
            defaultMessage={isPublic
              ? 'If the internet connection cannot be restored, we regret to inform you that it is no longer possible to use this device.{br}If the problem persists, do not hesitate to contact us.'
              : 'Please check your internet connection and try again.'}
            values={{
              br,
            }}
          />
        </p>
        {shouldDisplayPhoneNumbers && <DealersPhone />}
      </div>
      {shouldClose && (
        <Button ref={ref} onClick={handleClose} className="w-full mt-10" testId="close">
          <FormattedMessage id="close" defaultMessage="Close" />
        </Button>
      )}
      {shouldLogout && (
        <Button ref={ref} onClick={handleLogout} className="w-full mt-10" testId="close">
          <FormattedMessage id="socket.updateLogoutModal.logOut" defaultMessage="Logout" values={{ countdown: 0 }} />
        </Button>
      )}
    </Modal>
  );
};

export default OfflineModal;
