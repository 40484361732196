import { createSlice } from '@reduxjs/toolkit';

import healthCheckService from 'modules/healthCheck/service';

export interface InitialState {
  online: boolean;
  failureCount: number;
  isModalOpen: boolean;
}

const healthCheckSlice = createSlice({
  name: 'healthCheck',
  initialState: {
    online: true,
    failureCount: 0,
    isModalOpen: false,
  } as InitialState,
  reducers: {
    setOnline: (state, { payload: online }) => ({ ...state, online, isModalOpen: !online }),
    closeModal: (state) => ({ ...state, isModalOpen: false }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      healthCheckService.endpoints.healthCheck.matchFulfilled,
      (state) => ({
        ...state, online: true, isModalOpen: false, failureCount: 0,
      }),
    );
    builder.addMatcher(
      healthCheckService.endpoints.healthCheck.matchRejected,
      (state) => {
        const nbError = state.failureCount + 1;
        if (nbError < 2) {
          return ({ ...state, failureCount: nbError });
        }
        return ({ ...state, online: false, isModalOpen: true });
      },
    );
  },
});

export default healthCheckSlice;
