import Flow from 'types/Flow';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import { hasHiddenSteps } from '../../utils';
import { FINAL_INVOICE, SUMMARY } from '../home';
import type { SelfServiceStep } from '../../../types';
import { MOBILITY_PARKING as COMMON_MOBILITY_PARKING, PARKING, SIGNATURE } from '../onSite';

export const MOBILITY_PARKING: SelfServiceStep = {
  ...COMMON_MOBILITY_PARKING,
  // `next` needs to be defined in concrete implementation
  data: {
    ...COMMON_MOBILITY_PARKING.data,
    isFinalizeStep: hasHiddenSteps([SIGNATURE]),
  },
};

export type CommonKioskCheckOutSteps =
  SelfServiceSteps.SUMMARY
  | SelfServiceSteps.FINAL_INVOICE
  | SelfServiceSteps.PARKING;

const COMMON_KIOSK_CHECK_OUT_STEPS: Flow<CommonKioskCheckOutSteps> = {
  SUMMARY: {
    ...SUMMARY,
    data: {
      ...SUMMARY.data,
      isFinalizeStep: hasHiddenSteps([FINAL_INVOICE, PARKING, MOBILITY_PARKING, SIGNATURE]),
    },
  },
  FINAL_INVOICE: {
    ...FINAL_INVOICE,
    data: {
      ...FINAL_INVOICE.data,
      isFinalizeStep: hasHiddenSteps([PARKING, MOBILITY_PARKING, SIGNATURE]),
    },
  },
  PARKING: {
    ...PARKING,
    next: COMMON_MOBILITY_PARKING.key,
    data: {
      ...PARKING.data,
      isFinalizeStep: hasHiddenSteps([MOBILITY_PARKING, SIGNATURE]),
    },
  },
};

export default COMMON_KIOSK_CHECK_OUT_STEPS;
