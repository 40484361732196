import Flow from 'types/Flow';
import FlowsByModel from 'types/FlowsByModel';
import { PartteamModel } from 'types/KioskVendors';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import { OnSiteCheckOutSteps, SIGNATURE } from '../onSite';
import { FINAL_INSTRUCTIONS, PARTTEAM_KEY } from '../../common';
import COMMON_KIOSK_CHECK_OUT_STEPS, { MOBILITY_PARKING } from './common';

type PartteamKioskCheckOutSteps = OnSiteCheckOutSteps | SelfServiceSteps.PARTTEAM_KEY;

const flow: Flow<PartteamKioskCheckOutSteps> = {
  ...COMMON_KIOSK_CHECK_OUT_STEPS,
  MOBILITY_PARKING: {
    ...MOBILITY_PARKING,
    next: SIGNATURE.key,
  },
  SIGNATURE: {
    ...SIGNATURE,
    previous: MOBILITY_PARKING.key,
    next: PARTTEAM_KEY.key,
  },
  PARTTEAM_KEY: {
    ...PARTTEAM_KEY,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: PARTTEAM_KEY.key },
};

const PARTTEAM_KIOSK_CHECK_OUT_STEPS: FlowsByModel<PartteamModel> = {
  [PartteamModel.INDOOR]: flow,
  [PartteamModel.OUTDOOR]: flow,
};

export default PARTTEAM_KIOSK_CHECK_OUT_STEPS;
