import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { b } from 'utils/i18nUtils';

import { useGetVCContextQuery } from 'modules/vehicleCheck/service';
import { VehicleCheckType } from 'modules/vehicleCheck/types/VehicleCheckModel';

import CheckIcon from 'assets/icons/check.svg';
import Spinner from '../ui/Spinner';
import { Button, Help } from '../ui';
import { ButtonType } from '../ui/Button';

const VehicleCheck: React.FC = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const { data: context, isSuccess, isFetching } = useGetVCContextQuery(undefined, { refetchOnMountOrArgChange: true });
  const isReady = !isFetching && isSuccess;

  const handleStart = (type: VehicleCheckType) => () => {
    navigate(`/vehicle-check/${type}${search}`);
  };

  return (
    <div className="relative flex items-end bg-vehicleCheck bg-cover bg-center homePage landscape:items-center landscape:pt-16">
      <div className="absolute top-0 inset-x-0 px-7 flex justify-end">
        <Help />
      </div>
      <div className="flex flex-col items-center mx-auto backdrop-blur-2xl py-12 px-8 xs:p-6 w-full max-w-4xl landscape:max-w-lg z-10">
        {isFetching && <Spinner />}
        {isReady && (
          <>
            <div className="mb-8 text-center">
              <h1 className="font-bold text-white text-3xl break-word">{context.dealerName}</h1>
              <p className="flex justify-center text-white text-lg font-semibold">
                <FormattedMessage
                  id="vehicleCheck.title"
                  defaultMessage="Vehicle check - {registration}"
                  values={{ registration: context.registration }}
                />
              </p>
            </div>
            <p className="text-white text-center text-sm mb-8">
              <FormattedMessage
                id="vehicleCheck.description"
                defaultMessage="If you do not complete the vehicle check, you agree to <b>take responsibility for any damage</b> found on the vehicle that was not previously reported."
                values={{ b: (text) => b(text, '!text-white text-sm') }}
              />
            </p>
            {Object.values(VehicleCheckType).map((type, index, data) => {
              const typeData = context[type];
              const isLast = index === data.length - 1;

              return (
                typeData.accepted && (
                  <Button
                    key={type}
                    loading={isFetching}
                    onClick={handleStart(type)}
                    type={typeData.answered ? ButtonType.SUCCESS : ButtonType.PRIMARY}
                    disabled={typeData.answered}
                    testId={`start-vehicle-check_${type}`}
                    className={classNames(
                      'flex items-center justify-center w-full',
                      { 'bg-success-default': typeData.answered, 'mb-2': !isLast },
                    )}
                  >
                    <FormattedMessage id={`vehicleCheck.types.${type}`} defaultMessage={`${type} vehicle`} />
                    {typeData.answered && (<CheckIcon className="ml-2 h-6 fill-white" />)}
                  </Button>
                )
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default VehicleCheck;
