import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';

import { SelfService, SelfServiceType } from 'modules/selfServices/types/SelfService';

import { getKioskSpeechForCurrentLocale, getRackBySelfServiceId } from 'modules/kiosk/selectors';

import Button from 'components/ui/Button';
import SlotCamera from '../../SlotCamera';

const KeysManagementModalContent: React.FC<Partial<SelfService> & { onClose: () => void }> = ({
  type, id: selfServiceId, mobilityEnabled, onClose,
}) => {
  const speechUrl = useSelector(getKioskSpeechForCurrentLocale);
  const rack = useSelector((state) => getRackBySelfServiceId(state, selfServiceId));

  const isBreakdown = type === SelfServiceType.BREAKDOWN;
  const isCheckin = type === SelfServiceType.CHECK_IN || type === SelfServiceType.BREAKDOWN;
  const translationType = isBreakdown ? SelfServiceType.CHECK_IN : type;

  const displayKeyWarning = isCheckin && !mobilityEnabled;
  const shouldPlaySpeech = Boolean(speechUrl) && (isCheckin || mobilityEnabled);

  return (
    <div className="flex flex-col items-center">
      {shouldPlaySpeech && (
        <audio src={speechUrl} autoPlay>
          <source type="audio/mp3" />
        </audio>
      )}
      <h1 className="text-center">
        {!isBreakdown && (
          <FormattedMessage
            id="key.titleHelper"
            // eslint-disable-next-line max-len
            defaultMessage="Please use the{rack,select, 1 { top} 2 { bottom} other {}} door to{type,select, CHECK_IN { drop} other { retrieve}} your keys"
            values={{ rack, type }}
          />
        )}
        {isBreakdown && (
          <FormattedMessage
            id="key.breakdownTitleHelper"
            // eslint-disable-next-line max-len
            defaultMessage="Please use the{rack,select, 1 { top} 2 { bottom} other {}} door to drop the keys"
            values={{ rack }}
          />
        )}
      </h1>
      {mobilityEnabled && (
        <div className="flex flex-col items-center mt-12">
          <div className="flex items-center">
            <div
              className="bg-white rounded-full size-12 flex justify-center items-center mr-7 text-2xl font-bold"
            >
              1
            </div>
            <span className="text-2xl font-semibold">
              <FormattedMessage
                id={`key.mobility.steps.${translationType}.retreive`}
                defaultMessage={isCheckin
                  ? 'Retrieve the courtesy vehicle keys'
                  : 'Retrieve your vehicle keys'}
              />
            </span>
          </div>
          <div className="h-px w-10/12 bg-white my-3" />
          <div className="flex items-center">
            <div
              className="bg-white rounded-full size-12 flex justify-center items-center mr-7 text-2xl font-bold"
            >
              2
            </div>
            <span className="text-2xl font-semibold">
              <FormattedMessage
                id={`key.mobility.steps.${translationType}.drop`}
                defaultMessage={isCheckin
                  ? 'Drop your vehicle keys (without any keychains)'
                  : 'Drop the courtesy vehicle keys (without any keychains)'}
              />
            </span>
          </div>
        </div>
      )}
      <p className="text-center text-low mt-6">
        {mobilityEnabled && (
          <FormattedMessage
            id="key.mobility.helper"
            // eslint-disable-next-line max-len
            defaultMessage={'Make sure to put them far enough (visible below) and click "I\'ve swapped the keys" to confirm and close the door.'}
          />
        )}
        {!mobilityEnabled && (
          <FormattedMessage
            id={`key.${translationType}.helper`}
            defaultMessage={
              isCheckin
                ? 'Make sure to put them far enough and click "I\'ve dropped the keys" to confirm and close the door.'
                : 'Click "I\'ve retrieved the keys" to confirm and close the door.'
            }
          />
        )}
      </p>
      {displayKeyWarning && (
        <p className="text-center text-low font-bold mt-6">
          <FormattedMessage
            id="key.slotHelper"
            defaultMessage="Please ensure that the key is well visible below and without any keychains."
          />
        </p>
      )}
      <SlotCamera rack={rack} className="my-6" />
      <Button className="border-40 border-white" onClick={onClose} testId="key-button">
        {mobilityEnabled && <FormattedMessage id="key.mobility.button" defaultMessage="I've swapped the keys" />}
        {!mobilityEnabled && (
          <FormattedMessage
            id={`key.${translationType}.button`}
            defaultMessage={isCheckin ? "I've dropped the keys" : "I've retrieved the keys"}
          />
        )}
      </Button>
    </div>
  );
};
export default KeysManagementModalContent;
