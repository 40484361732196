import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';

import PageBaseProps from 'types/PageBase';

import { logout } from 'modules/auth/actions';

import { getMFAError } from 'modules/auth/selectors';
import { getKioskName, getLocationId } from 'modules/dealers/selectors';

import Input from 'components/ui/Input';
import Button from 'components/ui/Button';
import PageFooter from 'components/ui/PageFooter';

import RemoteKeyModal from './RemoteKeyModal';
import useKioskLocationConfig from './useKioskLocationConfig';

const KioskLocationId: React.FC<PageBaseProps> = ({ onNext, onPrev }) => {
  const dispatch = useDispatch();

  const [isRemoteKioskModalOpen, setIsRemoteKioskModalOpen] = React.useState(false);

  const mfaError = useSelector(getMFAError);
  const currentKioskName = useSelector(getKioskName);
  const currentLocationId = useSelector(getLocationId);

  const locationId = React.useMemo(
    () => mfaError?.locationId ?? mfaError?.kioskName ?? currentLocationId ?? currentKioskName,
    [mfaError, currentKioskName, currentLocationId],
  );

  const {
    title,
    description,
    locationLabel,
    image: kioskImage,
  } = useKioskLocationConfig();

  const handleQuit = React.useCallback(() => dispatch(logout()), [dispatch]);

  const handleNext = React.useCallback(() => setIsRemoteKioskModalOpen(true), []);
  const handleClose = React.useCallback((hasError: boolean) => {
    if (hasError) {
      setIsRemoteKioskModalOpen(false);
    } else {
      // We don't hide in this case so the user can see the success message and can't click anywhere else
      onNext();
    }
  }, [onNext]);

  return (
    <div className="main-content !pt-24">
      <div className="flex flex-col gap-4 text-center">
        {mfaError && (
          <h1 className="text-default">
            {title}
          </h1>
        )}
        <p className="w-full text-low mb-6 mt-5 whitespace-pre-wrap break-words">
          {description}
        </p>
        <Input
          disabled
          value={locationId}
          label={locationLabel}
          inputClassName="text-center"
        />
        <div className="relative grow bg-card-bg rounded-card p-3 w-full mb-24">
          <img alt={kioskImage.alt} className="h-auto w-full rounded-lg" src={kioskImage.src} />
        </div>
      </div>
      {!mfaError && (
        <>
          <PageFooter onNext={handleNext} onPrev={onPrev} />
          <RemoteKeyModal open={isRemoteKioskModalOpen} onClose={handleClose} />
        </>
      )}
      {mfaError && (
        <div className="nextButton">
          <Button className="w-full" onClick={handleQuit} testId="quit">
            <FormattedMessage id="quit" defaultMessage="Quit" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default KioskLocationId;
