import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames, { Argument } from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { b } from 'utils/i18nUtils';

import { logout } from 'modules/auth/actions';

import { getCurrentStep } from 'modules/steps/selector';
import { getSelfServiceType } from 'modules/selfServices/selectors';
import { getOrigin, isLocalKiosk } from 'modules/dealers/selectors';

import SignOut from 'assets/icons/signOut.svg';
import BackArrow from 'assets/icons/backArrow.svg';

import Confirm from './Confirm';
import Button, { ButtonType } from './Button';
import { StepIndicatorProps } from './StepIndicator';

export interface StepsHeaderProps extends Partial<StepIndicatorProps> {
  onPrev?: () => void;
  hideSteps?: boolean;
  className?: Argument;
  title?: React.ReactNode;
  hidePrevButton?: boolean;
}

// eslint-disable-next-line max-len
const stepIndicator = "before:content=[''] before:absolute before:border-b-2 before:border-header-border-progress before:w-[--progress-width]";

const StepsHeader: React.FC<StepsHeaderProps> = ({
  currentIndex, length, onPrev, hideSteps, className, hidePrevButton, title,
}) => {
  const dispatch = useDispatch();

  const [showSignOutModal, setShowSignOutModal] = React.useState(false);

  const origin = useSelector(getOrigin);
  const isKiosk = useSelector(isLocalKiosk);
  const type = useSelector(getSelfServiceType);
  const currentStep = useSelector(getCurrentStep);

  const styleAttribute = React.useMemo(
    () => ({
      '--progress-width': hideSteps ? 0 : `${((currentIndex + 1) / length) * 100}%`,
    } as React.CSSProperties),
    [currentIndex, hideSteps, length],
  );
  const handleCloseModal = React.useCallback(() => {
    setShowSignOutModal(false);
  }, []);

  const handleConfirmModal = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleSignOut = React.useCallback(() => setShowSignOutModal(true), []);
  const handleClick = React.useCallback(() => {
    if (currentIndex === 0) {
      handleSignOut();
    } else {
      onPrev();
    }
  }, [currentIndex, handleSignOut, onPrev]);

  const displayBackButton = !hidePrevButton && !isKiosk;
  const displayLogoutButton = currentIndex >= 0 && isKiosk;

  return (
    <>
      <div className={classNames('flex items-center py-2 kiosk:py-10 w-100', className)}>
        {displayLogoutButton && (
          <Button
            analytics={{
              category: 'Logout',
              action: `${origin} ${type} logout`,
              label: `Logout started from ${currentStep}`,
            }}
            type={ButtonType.SECONDARY}
            onClick={handleSignOut}
            testId="kiosk-prev-step"
            className="!p-7 w-auto flex-row items-center justify-center flex max-w-full"
          >
            <SignOut className="size-6 shrink-0 mr-2 fill-default" />
            <p className="truncate text-default !text-xl">
              <FormattedMessage id="header.title" defaultMessage="Log out" />
            </p>
          </Button>
        )}
        {displayBackButton && (
          <Button
            analytics={currentIndex === 0 ? {
              category: 'Logout',
              action: `${origin} ${type} logout`,
              label: `Logout started from ${currentStep}`,
            } : undefined}
            type={ButtonType.SECONDARY}
            onClick={handleClick}
            testId="prev-step"
            className="!p-2"
          >
            {currentIndex === 0 && <SignOut className="size-6 fill-default" />}
            {currentIndex !== 0 && <BackArrow className="size-6 " />}
          </Button>
        )}
      </div>
      {title && (
        <div className="mx-4 flex-grow kiosk:flex-grow-0 font-bold truncate text-xl kiosk:text-4xl">
          {title}
        </div>
      )}

      <div
        style={styleAttribute}
        className={classNames(
          'absolute bottom-0 left-0 border-b-2 border-header-border w-full',
          { [stepIndicator]: !hideSteps },
        )}
      />

      <Confirm
        focusCancel
        open={showSignOutModal}
        onCancel={handleCloseModal}
        onConfirm={handleConfirmModal}
        cancel={<FormattedMessage id="header.cancel" defaultMessage="No, continue" />}
        confirm={<FormattedMessage id="header.confirm" defaultMessage="Yes, cancel" />}
        title={(
          <h2 className="flex justify-center items-center p-4">
            <SignOut className="size-6 kiosk:size-12 mr-3 fill-default" />
            <FormattedMessage id="header.title" defaultMessage="Log out" />
          </h2>
        )}
        question={(
          <FormattedMessage
            id={`header.${type?.toLowerCase()}.backModal.content`}
            defaultMessage={`Are you sure you want to <b>cancel</b> your ${type?.toLowerCase()} ?`}
            tagName="p"
            values={{ b }}
          />
        )}
      />
    </>
  );
};

export default StepsHeader;
