import { SelfServiceOrigin } from 'modules/selfServices/types/SelfService';

import FlowsByOrigin from 'types/FlowsByOrigin';
import HOME_CHECK_IN_STEPS from './home';
import KIOSK_CHECK_IN_STEPS from './kiosk';
import ON_SITE_CHECK_IN_STEPS from './onSite';

export default {
  [SelfServiceOrigin.HOME]: HOME_CHECK_IN_STEPS,
  [SelfServiceOrigin.ON_SITE]: ON_SITE_CHECK_IN_STEPS,
  [SelfServiceOrigin.KIOSK]: KIOSK_CHECK_IN_STEPS,
} as FlowsByOrigin;
