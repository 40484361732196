// Once 'FAKE' type is removed, KioskType should removed in favor of Vendor.
export enum KioskType {
  // 'FAKE' represents a fake *Partteam* kiosk (indoor or outdoor).
  // It should be removed in the future to be included into Partteam models.
  FAKE = 'FAKE',
  PARTTEAM = 'PARTTEAM',
  SHAREBOX = 'SHAREBOX',
}

// 'Real' kiosk vendors
export enum Vendor {
  PARTTEAM = KioskType.PARTTEAM,
  SHAREBOX = KioskType.SHAREBOX,
}

// We need this to convert KioskType to Vendor because of 'FAKE' type.
export const KioskTypeToVendor = {
  [KioskType.FAKE]: Vendor.PARTTEAM,
  [KioskType.PARTTEAM]: Vendor.PARTTEAM,
  [KioskType.SHAREBOX]: Vendor.SHAREBOX,
};

// Those are kept for compatibility, but there is no difference between those models.
export enum PartteamModel {
  INDOOR = 'INDOOR',
  OUTDOOR = 'OUTDOOR',
}

export enum ShareboxModel {
  I32 = 'I32',
  S32 = 'S32',
  W64 = 'W64',
  M28 = 'M28',
}

export type KioskModel = PartteamModel | ShareboxModel;

/** @description This list should be used as little as possible, it's intended for styling purposes **only**. */
export const SHAREBOX_INDOOR_KIOSK_MODELS = [ShareboxModel.I32, ShareboxModel.W64];
