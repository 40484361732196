import Flow from 'types/Flow';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import {
  FINAL_INSTRUCTIONS, SHAREBOX_KEY, SIGNATURE,
} from 'components/SelfServices/Steps/common';
import { isNotAnswered } from 'components/SelfServices/Steps/utils';

import { PARKING } from '../../onSite';
import COMMON_KIOSK_BREAKDOWN_STEPS, { CommonKioskBreakdownSteps } from '../common';

export type ShareboxI32BreakdownSteps =
  CommonKioskBreakdownSteps
  | SelfServiceSteps.SIGNATURE
  | SelfServiceSteps.SHAREBOX_KEY
  | SelfServiceSteps.FINAL_INSTRUCTIONS;

const SHAREBOX_I32_BREAKDOWN_STEPS: Flow<ShareboxI32BreakdownSteps> = {
  ...COMMON_KIOSK_BREAKDOWN_STEPS,
  SIGNATURE: {
    ...SIGNATURE,
    previous: PARKING.key,
    next: SHAREBOX_KEY.key,
    data: { ...SIGNATURE.data, isFinalizeStep: true },
  },
  SHAREBOX_KEY: {
    ...SHAREBOX_KEY,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
    data: {
      ...SHAREBOX_KEY.data,
      isVisible: ({ status }) => isNotAnswered(status),
    },
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: SHAREBOX_KEY.key },
};

export default SHAREBOX_I32_BREAKDOWN_STEPS;
