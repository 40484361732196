import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import { Button } from 'components/ui';
import { ButtonType } from 'components/ui/Button';

import { isOffline as isOfflineSelector } from 'modules/healthCheck/selectors';
import { isCourtesyUnavailable, hasErrorOnKiosk } from 'modules/dealers/selectors';

import Arrow from 'assets/icons/arrow.svg';

import type { HomeButtonProps } from './index';

const SelfServiceTypeButtons:React.FC<HomeButtonProps> = ({ onClick }) => {
  const isOffline = useSelector(isOfflineSelector);
  const hasKioskError = useSelector(hasErrorOnKiosk);
  const isCourtesyNotAvailable = useSelector(isCourtesyUnavailable);
  const handleClick = (atDealership: boolean) => () => onClick({ atDealership });

  const isFromDealershipDisabled = hasKioskError || isCourtesyNotAvailable;

  return (
    <div
      className="flex flex-col items-start w-full m-auto pt-6 pb-12 max-w-4xl px-7"
    >
      <Button
        analytics={{
          category: 'Login',
          action: 'From home login',
          label: 'Login started',
        }}
        disabled={isOffline}
        type={ButtonType.PRIMARY}
        testId="from-home-button"
        onClick={handleClick(false)}
        className="flex items-center justify-between w-full mb-6 !drop-shadow-md !p-1.5 !pl-12 !text-btn-home-primary-text border-btn-home-primary-border !bg-btn-home-primary-bg"
      >
        <FormattedMessage id="home.fromHome" defaultMessage="From home" />
        <div className="flex justify-center items-center size-btn-primary-icon-home rounded-button bg-btn-primary-icon-bg">
          <Arrow className="w-4 stroke-btn-home-primary-icon-text stroke-1" />
        </div>
      </Button>
      <Button
        analytics={{
          category: 'Login',
          action: 'From the dealership login',
          label: 'Login started',
        }}
        disabled={isOffline}
        type={ButtonType.PRIMARY}
        testId="from-dealer-button"
        onClick={handleClick(true)}
        disabledStyle={isFromDealershipDisabled}
        className="flex items-center justify-between w-full mb-6 !drop-shadow-md !p-1.5 !pl-12 !text-btn-home-primary-text border-btn-home-primary-border !bg-btn-home-primary-bg"
      >
        <FormattedMessage id="home.fromDealer" defaultMessage="From the dealership" />
        <div className="flex justify-center items-center size-btn-primary-icon-home rounded-button bg-btn-primary-icon-bg">
          <Arrow className="w-4 stroke-btn-home-primary-icon-text stroke-1" />
        </div>
      </Button>
    </div>
  );
};

export default SelfServiceTypeButtons;
