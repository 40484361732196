import Flow from 'types/Flow';

import {
  COMMON_ON_SITE_KIOSK_CHECKIN_STEPS, CommonOnSiteKioskCheckInSteps,
} from '../onSite';

export type CommonKioskCheckInSteps = CommonOnSiteKioskCheckInSteps;

const COMMON_KIOSK_CHECK_IN_STEPS: Flow<CommonKioskCheckInSteps> = COMMON_ON_SITE_KIOSK_CHECKIN_STEPS;

export default COMMON_KIOSK_CHECK_IN_STEPS;
