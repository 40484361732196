import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  getOrigin,
  getSelfServiceAvailableTypes,
  isKioskFull as isKioskFullSelector,
} from 'modules/dealers/selectors';
import { isOffline as isOfflineSelector } from 'modules/healthCheck/selectors';

import { Button } from 'components/ui';
import { ButtonType } from 'components/ui/Button';

import { getSelfServiceType } from 'modules/selfServices/selectors';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import { useSelector } from '../../hooks';

import CheckInLogo from '../../assets/icons/checkIn.svg';
import CheckOutLogo from '../../assets/icons/checkOut.svg';
import BreakdownLogo from '../../assets/icons/breakdown.svg';

import type { HomeButtonProps } from './index';

const SelfServiceTypeButtons: React.FC<HomeButtonProps> = ({ onClick }) => {
  const origin = useSelector(getOrigin);
  const type = useSelector(getSelfServiceType);
  const isOffline = useSelector(isOfflineSelector);
  const isKioskFull = useSelector(isKioskFullSelector);
  const selfServiceAvailableTypes = useSelector(getSelfServiceAvailableTypes);

  const shouldShowCheckIn = type === SelfServiceType.CHECK_IN || (!type && selfServiceAvailableTypes[SelfServiceType.CHECK_IN]?.includes(origin));
  const shouldShowCheckOut = type === SelfServiceType.CHECK_OUT || (!type && selfServiceAvailableTypes[SelfServiceType.CHECK_OUT]?.includes(origin));
  const shouldShowBreakdown = selfServiceAvailableTypes[SelfServiceType.BREAKDOWN]?.includes(origin);

  const handleClick = (selectedType: SelfServiceType) => () => onClick({ type: selectedType });

  return (
    <div
      className="flex flex-col items-start w-full m-auto pt-6 pb-12 max-w-4xl kiosk:w-full px-7 kiosk:px-12 kiosk:mb-52 kioskSharebox:mb-12"
    >
      {shouldShowCheckIn && (
        <Button
          analytics={{
            category: 'Login',
            action: 'CHECK_IN login',
            label: 'Login started',
          }}
          disabled={isOffline}
          testId="check-in-button"
          type={ButtonType.PRIMARY}
          onClick={handleClick(SelfServiceType.CHECK_IN)}
          className={classNames('flex items-center justify-center w-full !drop-shadow-md !text-btn-home-primary-text border-btn-home-primary-border !bg-btn-home-primary-bg', { 'mb-6': shouldShowCheckOut })}
        >
          <CheckInLogo className="w-12 mr-8 fill-btn-home-primary-text" />
          <FormattedMessage id="home.checkIn" defaultMessage="Check-in" />
        </Button>
      )}
      {shouldShowCheckOut && (
        <Button
          analytics={{
            category: 'Login',
            action: 'CHECK_OUT login',
            label: 'Login started',
          }}
          disabled={isOffline}
          testId="check-out-button"
          type={ButtonType.PRIMARY}
          className="flex items-center justify-center w-full !drop-shadow-md !text-btn-home-primary-text border-btn-home-primary-border !bg-btn-home-primary-bg"
          onClick={handleClick(SelfServiceType.CHECK_OUT)}
        >
          <CheckOutLogo className="w-12 mr-8 fill-btn-home-primary-text" />
          <FormattedMessage id="home.checkOut" defaultMessage="Check-out" />
        </Button>
      )}
      {shouldShowBreakdown && (
        <>
          <div className="h-px bg-white my-12 opacity-30 mx-auto w-[calc(100%-6rem)] !drop-shadow-md" />
          <Button
            analytics={{
              category: 'Login',
              action: 'BREAKDOWN login',
              label: 'Login started',
            }}
            testId="breakdown-button"
            type={ButtonType.PRIMARY}
            disabled={isOffline}
            disabledStyle={isKioskFull}
            className="flex items-center justify-center w-full !leading-12 !drop-shadow-md !text-btn-home-primary-text border-btn-home-primary-border !bg-btn-home-primary-bg"
            onClick={handleClick(SelfServiceType.BREAKDOWN)}
          >
            <BreakdownLogo className="w-12 mr-8 fill-btn-home-primary-text" />
            <FormattedMessage id="home.breakdown" defaultMessage="Breakdown service" />
          </Button>
        </>
      )}
    </div>
  );
};

export default SelfServiceTypeButtons;
