import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { KioskImages } from 'types/KioskImages';
import { KioskTypeToVendor, Vendor } from 'types/KioskVendors';

import { useGetKioskImagesQuery } from 'modules/kiosk/service';

import { getMFAError } from 'modules/auth/selectors';
import { getKioskId, getKioskImages, getKioskVendor } from 'modules/dealers/selectors';

type KioskLocationConfig = {
  title: React.ReactNode;
  description: React.ReactNode;
  locationLabel: React.ReactNode;
  image: Partial<HTMLImageElement>;
};

export const getDefaultConfig = (kioskImages: KioskImages) => ({
  title: <FormattedMessage id="kioskLocation.title" defaultMessage="Kiosk location" />,
  description: (
    <FormattedMessage
      id="kioskLocation.description"
      // eslint-disable-next-line max-len
      defaultMessage="Please present yourself in front of the kiosk with the following location ID before going to the next step."
    />
  ),
  locationLabel: <FormattedMessage id="login.locationId" defaultMessage="Location ID" />,
  image: {
    alt: 'Sharebox kiosk',
    src: kioskImages?.image,
  },
} as KioskLocationConfig);

const getErrorConfigs = (kioskImages: KioskImages): Record<string, KioskLocationConfig> => ({
  [Vendor.SHAREBOX]: {
    title: <FormattedMessage id="wrongKiosk.title" defaultMessage="Change kiosk" />,
    description: (
      <FormattedMessage
        id="wrongKiosk.sharebox.description"
        // eslint-disable-next-line max-len
        defaultMessage="Your keys are prepared in another kiosk, please start over on the kiosk with the following location ID"
      />
    ),
    locationLabel: <FormattedMessage id="login.locationId" defaultMessage="Location ID" />,
    image: {
      alt: 'Sharebox kiosk',
      src: kioskImages?.image,
    },
  },
  [Vendor.PARTTEAM]: {
    title: <FormattedMessage id="wrongKiosk.title" defaultMessage="Change kiosk" />,
    description: (
      <FormattedMessage
        id="wrongKiosk.partteam.description"
        // eslint-disable-next-line max-len
        defaultMessage="Your keys are prepared in another kiosk, please start over on the kiosk with the following name"
      />
    ),
    locationLabel: <FormattedMessage id="name" defaultMessage="Name" />,
    image: {
      alt: 'Partteam kiosk',
      src: kioskImages?.image,
    },
  },
});

const useKioskLocationConfig = (): KioskLocationConfig => {
  const error = useSelector(getMFAError);
  const kioskId = useSelector(getKioskId);
  const kioskImages = useSelector(getKioskImages);
  const currentVendor = useSelector(getKioskVendor);

  const hasError = Boolean(error);
  const { data } = useGetKioskImagesQuery(
    { kioskId },
    { skip: hasError || Boolean(kioskImages) || !kioskId },
  );

  const images = React.useMemo(
    () => error?.kioskImages ?? kioskImages ?? data?.images,
    [error, kioskImages, data],
  );
  const vendor = React.useMemo(
    () => (error?.kioskType ? KioskTypeToVendor[error.kioskType] : currentVendor),
    [error, currentVendor],
  );

  return React.useMemo(() => (
    hasError
      ? getErrorConfigs(images)[vendor]
      : getDefaultConfig(images)
  ), [hasError, images, vendor]);
};

export default useKioskLocationConfig;
