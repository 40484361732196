import { REHYDRATE } from 'redux-persist';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import api from 'service/index';
import { getUrlParam } from 'utils/urlUtils';
import healthCheckApi from 'modules/healthCheck/service';
import { AppStartListening } from 'App/ListenerMiddleware';
import { waitForConnectionEvent } from 'modules/app/utils';

import { locationChange } from 'modules/router/actions';

import { isLocalKiosk } from 'modules/dealers/selectors';

import { setOnline } from './actions';

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const HEALTH_CHECK_POLLING_INTERVAL = 10000;

// Listen to navigator online/offline
startAppListening({
  matcher: locationChange.match,
  effect: async (_, {
    dispatch, cancelActiveListeners, signal,
  }) => {
    cancelActiveListeners();

    let isInitializing = true;
    while (!signal.aborted) {
      if (!isInitializing) { // Prevent notif on page load
        const isOnline = typeof navigator !== 'undefined' && 'onLine' in navigator ? navigator.onLine : true;
        dispatch(setOnline(isOnline));
      }

      isInitializing = false;
      // eslint-disable-next-line no-await-in-loop
      await waitForConnectionEvent(signal);
    }
  },
});

startAppListening({
  predicate: (action, state) => {
    const outdoorShareboxToken = getUrlParam('OSToken');

    return (action.type === REHYDRATE || action.type === api.util.resetApiState.type)
      && Boolean(isLocalKiosk(state) || outdoorShareboxToken);
  },
  effect: async (_, { dispatch }) => {
    dispatch(healthCheckApi.endpoints.healthCheck.initiate(
      undefined,
      { subscriptionOptions: { pollingInterval: HEALTH_CHECK_POLLING_INTERVAL } },
    ));
  },
});

export default listenerMiddleware;
