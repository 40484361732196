import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormRenderProps } from 'react-final-form';

import { useSelector } from 'hooks';
import PageBaseProps from 'types/PageBase';
import GenericQuestion from 'types/Question';
import Carousel from 'components/ui/Carousel';
import PageFooter from 'components/ui/PageFooter';
import FormKeys from 'modules/form/types/FormKeys';
import { getOrigin } from 'modules/dealers/selectors';
import Questions from 'components/Questions/Questions';
import { getStepsConfig } from 'modules/form/selectors';
import { QuestionsForm as QuestionsFormType } from 'types/QuestionsForm';
import { KioskPreferencesQuestionsIds } from 'modules/selfServices/constants';
import { isKioskPreferenceQuestionVisible } from 'modules/selfServices/utils';
import { getKioskExplanationMedias, getSelfServiceType } from 'modules/selfServices/selectors';

const KioskUsageRender: React.FC<
FormRenderProps<QuestionsFormType> & Pick<PageBaseProps, 'onPrev' | 'shouldDisplayBackButton'> & { formKey: FormKeys }
> = ({
  onPrev, formKey, handleSubmit, hasValidationErrors, shouldDisplayBackButton,
}) => {
  const [isFullscreen, setIsFullscreen] = React.useState(false);

  const config = useSelector(getStepsConfig);
  const origin = useSelector(getOrigin);
  const type = useSelector(getSelfServiceType);
  const medias = useSelector(getKioskExplanationMedias);

  const handleCarouselOpen = React.useCallback(() => setIsFullscreen(true), []);
  const handleCarouselClose = React.useCallback(() => setIsFullscreen(false), []);

  const isQuestionVisible = (question: GenericQuestion) => isKioskPreferenceQuestionVisible(question.id as KioskPreferencesQuestionsIds, type, origin, config);

  return (
    <div className="main-content max-w-3xl">
      <span className="text-center">
        <FormattedMessage
          id="page.kioskUsage.description"
          defaultMessage="You can use our kiosk to drop/retrieve your keys 24/7."
        />
      </span>
      <div className="content">
        {medias?.length > 0 && (
          <Carousel
            medias={medias}
            onClose={handleCarouselClose}
            fullscreen={isFullscreen}
            onMediaClick={handleCarouselOpen}
          />
        )}
        <Questions name={formKey} questionFilter={isQuestionVisible} />
        <div className="text-center">
          <FormattedMessage
            id="page.kioskUsage.footer"
            defaultMessage="Your dealership will do their best to respect your wishes. You may change you mind by contacting your dealership."
            tagName="span"
          />
        </div>
      </div>
      <PageFooter onNext={handleSubmit} onPrev={onPrev} disabledStyle={hasValidationErrors} shouldDisplayBackButton={shouldDisplayBackButton} />
    </div>
  );
};

export default KioskUsageRender;
