import Flow from 'types/Flow';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';
import { ConnectionTypeLogin } from 'modules/dealers/types/ContextResponse';

import {
  FINAL_INSTRUCTIONS, SHAREBOX_KEY, KIOSK_LOCATION,
} from 'components/SelfServices/Steps/common';

import COMMON_KIOSK_CHECK_OUT_STEPS, { CommonKioskCheckOutSteps, MOBILITY_PARKING } from '../common';
import { SIGNATURE } from '../../onSite';

export type ShareboxI32CheckOutSteps =
  CommonKioskCheckOutSteps
  | SelfServiceSteps.MOBILITY_PARKING
  | SelfServiceSteps.KIOSK_LOCATION
  | SelfServiceSteps.SIGNATURE
  | SelfServiceSteps.SHAREBOX_KEY
  | SelfServiceSteps.FINAL_INSTRUCTIONS;

// Alias 'INDOOR' flow
const SHAREBOX_I32_CHECK_OUT_STEPS: Flow<ShareboxI32CheckOutSteps> = {
  ...COMMON_KIOSK_CHECK_OUT_STEPS,
  MOBILITY_PARKING: {
    ...MOBILITY_PARKING,
    next: SIGNATURE.key,
  },
  SIGNATURE: {
    ...SIGNATURE,
    previous: MOBILITY_PARKING.key,
    next: SHAREBOX_KEY.key,
  },
  // This one is shown in 'remote' flow, i.e. when the user is using their phone
  KIOSK_LOCATION: {
    ...KIOSK_LOCATION,
    key: SHAREBOX_KEY.key,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
    data: {
      ...KIOSK_LOCATION.data,
      isVisible: (selfService, config) => {
        const superIsVisible = KIOSK_LOCATION.data.isVisible;
        const isVisible = typeof superIsVisible === 'function' ? superIsVisible(selfService, config) : superIsVisible;
        return isVisible && config.connectionType === ConnectionTypeLogin.TOKEN;
      },
    },
  },
  // This one is shown when the user is using the kiosk
  SHAREBOX_KEY: {
    ...SHAREBOX_KEY,
    previous: SIGNATURE.key,
    next: FINAL_INSTRUCTIONS.key,
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: SHAREBOX_KEY.key },
};

export default SHAREBOX_I32_CHECK_OUT_STEPS;
