import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import DealersPhone from 'components/ui/DealersPhone';
import { shouldDisplayPhoneNumbers as shouldDisplayPhoneNumbersSelector } from 'modules/dealers/selectors';

import Warning from '../../assets/icons/warning.svg';

const RemoteKioskError: React.FC = () => {
  const shouldDisplayPhoneNumbers = useSelector(shouldDisplayPhoneNumbersSelector);
  return (
    <div className="flex flex-col items-center m-auto kiosk:mb-52 max-w-4xl sm:max-w-none w-full sm:w-auto sm:mx-4 bg-overlay rounded-t-2xl sm:rounded-2xl kiosk:rounded-4xl p-8 kiosk:p-20">
      <div className="rounded-full size-28 kiosk:size-52 bg-white relative flex justify-center items-center">
        <Warning className="absolute size-14 kiosk:size-28" />
      </div>
      <span className="flex flex-col items-center gap-2.5 kiosk:gap-5 text-center mt-5 kiosk:mt-10">
        <FormattedMessage id="home.emergency.title" defaultMessage="Issues with the kiosk" tagName="h1" />
        <FormattedMessage
          id="home.emergency.description"
          defaultMessage="We are currently experiencing an issue with the kiosk, please visit the dealership or contact us via the number below."
          tagName="span"
        />
      </span>
      {shouldDisplayPhoneNumbers && <DealersPhone />}
    </div>
  );
};

export default RemoteKioskError;
