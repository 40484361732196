import Flow from 'types/Flow';
import FlowsByModel from 'types/FlowsByModel';
import { ShareboxModel } from 'types/KioskVendors';

import { createDictionary } from 'utils/objectUtils';

import SHAREBOX_M28_CHECK_OUT_STEPS from './m28';
import SHAREBOX_W64_CHECK_OUT_STEPS from './w64';
import SHAREBOX_I32_CHECK_OUT_STEPS, { ShareboxI32CheckOutSteps } from './i32';
import SHAREBOX_S32_CHECK_OUT_STEPS, { ShareboxS32CheckOutSteps } from './s32';

type ShareboxKioskFlow = Flow<ShareboxI32CheckOutSteps> | Flow<ShareboxS32CheckOutSteps>;

const SHAREBOX_KIOSK_CHECK_OUT_STEPS = createDictionary<ShareboxModel, ShareboxKioskFlow>({
  [ShareboxModel.I32]: SHAREBOX_I32_CHECK_OUT_STEPS,
  [ShareboxModel.W64]: SHAREBOX_W64_CHECK_OUT_STEPS,
  [ShareboxModel.S32]: SHAREBOX_S32_CHECK_OUT_STEPS,
  [ShareboxModel.M28]: SHAREBOX_M28_CHECK_OUT_STEPS,
}, SHAREBOX_I32_CHECK_OUT_STEPS) as FlowsByModel<ShareboxModel>;

export default SHAREBOX_KIOSK_CHECK_OUT_STEPS;
