import React from 'react';
import classNames, { Argument } from 'classnames';

import { AbstractMedia } from 'types/AbstractMedia';

import File, { FileProps } from './File';
import Carousel, { CarouselProps } from './Carousel';

type FileListProps = {
  className?: Argument;
  medias: AbstractMedia[];
} & Pick<FileProps, 'onDelete'> & Pick<CarouselProps, 'showDownload'>;

const FileList: React.FC<FileListProps> = ({
  className, medias, onDelete, showDownload,
}) => {
  const [defaultCarouselMediaIndex, setDefaultCarouselMediaIndex] = React.useState(-1);

  const handleShowCarousel = React.useCallback((index: number) => () => {
    setDefaultCarouselMediaIndex(index);
  }, []);

  const handleHideCarousel = React.useCallback(() => {
    setDefaultCarouselMediaIndex(-1);
  }, []);

  return (
    <div className={classNames('flex flex-wrap', className)}>
      {medias?.map((media, index) => (
        <File
          {...media}
          onDelete={onDelete}
          key={media.id ?? media.uploadId ?? media.path}
          // eslint-disable-next-line max-len
          className="aspect-square w-[calc(25%-0.5rem)] xs:w-[calc(50%-0.5rem)] sm:w-[calc(16.666667%-1rem)] !h-fit m-1 kiosk:m-2"
          onClick={handleShowCarousel(index)}
        />
      ))}
      {defaultCarouselMediaIndex >= 0 && (
        <Carousel
          fullscreen
          medias={medias}
          onDelete={onDelete}
          showDownload={showDownload}
          onClose={handleHideCarousel}
          defaultMediaIndex={defaultCarouselMediaIndex}
        />
      )}
    </div>
  );
};

export default FileList;
