// eslint-disable-next-line import/prefer-default-export
export const waitForConnectionEvent = (signal: AbortSignal) => new Promise((resolve) => {
  const onlineHandler = () => resolve(true);
  const offlineHandler = () => resolve(false);

  window.addEventListener('online', onlineHandler, { once: true });
  window.addEventListener('offline', offlineHandler, { once: true });

  signal.addEventListener('abort', () => {
    window.removeEventListener('online', onlineHandler);
    window.removeEventListener('offline', offlineHandler);
    resolve(true);
  });
});
