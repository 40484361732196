import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormRenderProps, useField, UseFieldConfig, useForm,
} from 'react-final-form';

import { Position } from 'types/Position';
import PageBaseProps from 'types/PageBase';
import Button, { ButtonType } from 'components/ui/Button';

import { b } from 'utils/i18nUtils';
import { useGetSelfServiceByIdQuery } from 'modules/selfServices/service';

import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { displayMobilityParking, isBreakdownSelfService } from 'modules/selfServices/selectors';

import Input from 'components/ui/Input';
import PageFooter from 'components/ui/PageFooter';
import SelectInput from 'components/ui/SelectInput';
import CrossIcon from 'assets/icons/cross.svg';

import ParkingMap from './ParkingMap';

const fieldConfig = { subscription: { value: true } } as UseFieldConfig<string>;

const getIndication = (allowParkingSpot: boolean, displayMap: boolean): string => {
  const keys = {
    PARKING_AND_SPOT: allowParkingSpot && displayMap,
    SPOT: allowParkingSpot,
    MAP: displayMap,
  };
  return Object.entries(keys).find(([, v]) => v === true)[0];
};

const ParkingForm: React.FC<FormRenderProps & PageBaseProps> = ({
  handleSubmit, hasValidationErrors, onPrev, isPenultimate, shouldDisplayBackButton,
}) => {
  const intl = useIntl();
  const isMobility = useSelector(displayMobilityParking);
  const isBreakdown = useSelector(isBreakdownSelfService);
  const selfServiceId = useSelector(getSelectedSelfServiceId);

  const { data } = useGetSelfServiceByIdQuery({ id: selfServiceId });

  const { batch } = useForm();
  const { value: formMapId, onChange: onMapChange } = useField('mapId', fieldConfig).input;
  const [mapId, setMapId] = React.useState(formMapId);

  const format = (value: number | string) => (formMapId === mapId ? value ?? '' : '');
  const mapXInput = useField('x', { ...fieldConfig, format }).input;
  const mapYInput = useField('y', { ...fieldConfig, format }).input;
  const spotNumberInput = useField('spotNumber', { ...fieldConfig, format }).input;

  const maps = React.useMemo(() => {
    if (!data.parkingMap?.maps || data.parkingMap?.maps?.length <= 0) {
      return {};
    }

    const selectedMapId = data.parkingMap?.selectedMaps?.id;

    return data.parkingMap.maps.reduce(
      (acc, map) => {
        if (selectedMapId === map.id) {
          return { [map.id]: map.name, ...acc };
        }
        return { ...acc, [map.id]: map.name ?? map.media.filename };
      },
      {} as Record<string, string>,
    );
  }, [data]);

  const map = React.useMemo(
    () => data.parkingMap.maps?.find(({ id }) => id === mapId),
    [data.parkingMap.maps, mapId],
  );

  const indication = React.useMemo(
    () => getIndication(data.parkingMap.allowParkingSpot, Boolean(mapId)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleClear = React.useCallback(() => {
    batch(() => {
      spotNumberInput.onChange(undefined);
      mapXInput.onChange(undefined);
      mapYInput.onChange(undefined);
    });
  }, [batch, spotNumberInput, mapXInput, mapYInput]);

  const clearPreviousMapData = React.useCallback(() => {
    if (mapId !== formMapId) {
      handleClear();
      onMapChange(mapId);
    }
  }, [formMapId, handleClear, mapId, onMapChange]);

  const handleSpotChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearPreviousMapData();
    batch(() => {
      spotNumberInput.onChange(e.target.value);
    });
  };

  const handleMapChange = ({ x, y }: Position) => {
    clearPreviousMapData();
    batch(() => {
      mapXInput.onChange(x);
      mapYInput.onChange(y);
    });
  };

  const mapValue = {
    x: mapXInput.value || 0,
    y: mapYInput.value || 0,
  };

  const hasMaps = data.parkingMap?.maps?.length > 0;
  const displayMapSelect = data.parkingMap?.maps?.length > 1;

  const shouldDisplayClearBtn = formMapId === mapId && Boolean(spotNumberInput.value || mapXInput.value);

  return (
    <div className="main-content">
      <div className="content">
        <p className="text-center text-low">
          {!isMobility && (
            <FormattedMessage
              id="parking.indication"
              // eslint-disable-next-line max-len
              defaultMessage="Help us find {isBreakdown, select, true {the} other {your}} vehicle by providing {indication, select, PARKING_AND_SPOT {<b>the spot number</b> and/or <b>the location</b> on the map} SPOT {<b>the spot number</b>} other {<b>the location</b> on the map}}."
              values={{ b, indication, isBreakdown }}
            />
          )}
          {isMobility && (
            <FormattedMessage
              id="parking.mobility.indication"
              // eslint-disable-next-line max-len
              defaultMessage="Help us find your courtesy vehicle by providing {indication, select, PARKING_AND_SPOT {<b>the spot number</b> and/or <b>the location</b> on the map} SPOT {<b>the spot number</b>} other {<b>the location</b> on the map}}."
              values={{ b, indication }}
            />
          )}
        </p>
        {displayMapSelect && (
          <SelectInput
            data={maps}
            value={mapId}
            data-testid="map-select"
            onChange={setMapId}
            label={intl.formatMessage({ id: 'parking.selectMap.label', defaultMessage: 'Parking map' })}
          />
        )}
        {data.parkingMap.allowParkingSpot && (
          <Input
            value={spotNumberInput.value}
            onChange={handleSpotChange}
            data-testid="spot-number-input"
            // eslint-disable-next-line max-len
            label={intl.formatMessage({ id: 'parking.spotNumber.label', defaultMessage: 'Parking spot number' })}
          />
        )}
        {hasMaps && <ParkingMap map={map} value={mapValue} onChange={handleMapChange} />}
        {shouldDisplayClearBtn && (
          <Button
            testId="clear-map"
            type={ButtonType.SECONDARY}
            onClick={handleClear}
            className="w-full"
          >
            <span className="flex justify-center items-center">
              <CrossIcon className="w-3.5 mr-2" />
              <FormattedMessage id="parking.map.clear" defaultMessage="Clear location" />
            </span>
          </Button>
        )}
      </div>
      <PageFooter
        onPrev={onPrev}
        onNext={handleSubmit}
        isPenultimate={isPenultimate}
        disabledStyle={hasValidationErrors}
        shouldDisplayBackButton={shouldDisplayBackButton}
      />
    </div>
  );
};

export default ParkingForm;
