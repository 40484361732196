import React from 'react';
import classNames, { Argument } from 'classnames';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { computeTax } from 'utils/priceUtils';

import { AbstractMedia } from 'types/AbstractMedia';

import { isPublicDevice } from 'modules/dealers/selectors';

import { useSelector } from 'hooks';
import FileList from 'components/ui/FileList';
import useLabelTranslation from 'hooks/useLabelTranslation';
import { getPayerById } from 'modules/selfServices/selectors';
import { RepairOrder } from 'modules/selfServices/types/SelfService';

const TRUNCATE_LIMIT = 220;

export interface RepairOrderItemProps extends Omit<RepairOrder, 'adviceName' | 'description'> {
  description?: string;
  name: string;
  vat: number;
  currency: string;
  medias?: AbstractMedia[];
  className?: Argument;
}
const RepairOrderItem: React.FC<RepairOrderItemProps> = ({
  name,
  description,
  price,
  discountedPrice,
  currency,
  vat,
  medias,
  coefficient,
  payerId,
  className,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const isPublic = useSelector(isPublicDevice);
  const payer = useSelector((state) => getPayerById(state, payerId));

  const { getLabelTranslation } = useLabelTranslation();

  const showPayer = Boolean(payer);
  const showPrice = discountedPrice >= 0;
  const showDiscount = price !== discountedPrice;
  const isTruncated = description?.length > TRUNCATE_LIMIT;

  const toggleDescription = React.useCallback(() => setIsExpanded((currentIsExpanded) => !currentIsExpanded), []);

  return (
    <div className={classNames('flex flex-col', className)}>
      <div className="flex justify-between gap-2">
        <div data-testid="repair-order-name" className="text-default font-bold kiosk:text-3xl flex flex-wrap">
          {name}
        </div>
        {showPrice && (
          <div className="flex flex-col justify-start items-end">
            <p className="font-bold text-default" data-testid="repair-order-discountedPrice">
              <FormattedNumber
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                currency={currency}
                currencyDisplay="narrowSymbol"
                value={computeTax(discountedPrice, vat, coefficient)}
              />
            </p>
            {showDiscount && (
              <p className="line-through italic font-medium text-sm" data-testid="repair-order-price">
                <FormattedNumber
                  // eslint-disable-next-line react/style-prop-object
                  style="currency"
                  currency={currency}
                  currencyDisplay="narrowSymbol"
                  value={computeTax(price, vat, coefficient)}
                />
              </p>
            )}
          </div>
        )}
      </div>
      {showPayer && (
        <span
          className="text-right text-lowest italic font-semibold text-sm kiosk:text-2xl kioskSharebox:text-xl truncate"
        >
          <FormattedMessage
            id="billing.payerLabel"
            defaultMessage="Billing: {payerName}"
            values={{ payerName: getLabelTranslation(payer.name) }}
          />
        </span>
      )}
      {Boolean(description) && (
        <div
          data-testid="repair-order-description"
          className="text-low text-sm kiosk:text-2xl kioskSharebox:text-xl mt-2"
        >
          {isExpanded || !isTruncated ? description : `${description?.slice(0, TRUNCATE_LIMIT)}...`}
          {isTruncated && (
            <span
              className="underline text-sm cursor-pointer ml-1"
              onClick={toggleDescription}
              data-testid="expand-description"
            >
              {isExpanded ? (
                <FormattedMessage id="expand.less" defaultMessage="View less" />
              ) : (
                <FormattedMessage id="expand.more" defaultMessage="View more" />
              )}
            </span>
          )}
        </div>
      )}
      {medias?.length > 0 && <FileList medias={medias} showDownload={!isPublic} className="mt-2" />}
    </div>
  );
};

export default RepairOrderItem;
