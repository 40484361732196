import React from 'react';

import { getUrlParam } from 'utils/urlUtils';
import { openOfflineMode } from 'service/apiElectron';
import { isShareboxIndoorKiosk } from 'modules/dealers/selectors';

import { useSelector } from '../../hooks';

const ShareboxEmergencyAccess = () => {
  const timer = React.useRef<NodeJS.Timeout>();

  const isShareboxKiosk = useSelector(isShareboxIndoorKiosk);

  const outdoorShareboxToken = getUrlParam('OSToken');

  const displayShareboxEmergencyAccess = (outdoorShareboxToken || isShareboxKiosk);

  const handleTouchEnd = () => {
    clearTimeout(timer.current);
  };

  const handleTouchStart = () => {
    timer.current = setTimeout(() => {
      openOfflineMode();
    }, 5000);
  };

  return displayShareboxEmergencyAccess && (
    <div
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      className="absolute z-max w-24 h-24"
      data-testid="sharebox-emergency-access"
    />
  );
};

export default ShareboxEmergencyAccess;
