import React from 'react';
import { FormattedMessage } from 'react-intl';

import { b } from 'utils/i18nUtils';

import Doors from '../../../assets/icons/doors.svg';
import Button, { ButtonType } from '../../ui/Button';

const DoorsEmergencyModalContent:React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <div className="flex flex-col justify-center items-center gap-14 max-w-4xl w-full opacity-0 animate-translation-in">
    <div className="bg-white p-14 rounded-full">
      <Doors className="size-36 fill-danger-default" />
    </div>
    <h1 className="text-center !text-white">
      <FormattedMessage id="admin.manage.key.emergencyOpening.title" defaultMessage="Emergency opening" />
    </h1>
    <p className="text-center !text-white">
      <FormattedMessage
        id="admin.emergency.openDoorsContent.subTitle"
        defaultMessage='You are in <b>"emergency opening"</b> mode, you can check and unblock the carousels.'
        values={{ b: (node) => b(node, '!text-white') }}
      />
    </p>
    <div className="w-full text-center p-10 rounded-4xl bg-white">
      <FormattedMessage id="warning" defaultMessage="Warning" tagName="h3" />
      <FormattedMessage
        id="admin.emergency.openDoorsContent.description"
        defaultMessage={'Before any manipulation in the doors, we recommend that you <b>deactivate the "Servicing lock key"</b> inside the kiosk. Don\'t forget to <b>reactivate the "Service lock key"</b> before closing the doors.'}
        values={{ b }}
        tagName="p"
      />
    </div>
    <div className="bg-white rounded-5xl p-10">
      <Button className="min-w-18" type={ButtonType.ERROR} onClick={onClose} testId="close-btn">
        <FormattedMessage id="admin.emergency.openDoorsContent.action" defaultMessage="Close doors and test" />
      </Button>
    </div>
  </div>
);

export default DoorsEmergencyModalContent;
