import React from 'react';
import { push } from 'redux-first-history';
import { FormattedMessage } from 'react-intl';

import { logout } from 'modules/auth/actions';
import { useDispatch, useSelector } from 'hooks';
import { getSearch } from 'modules/router/selectors';
import { setFlowType } from 'modules/selfServices/actions';
import { getRedirectUrl } from 'modules/selfServices/selectors';
import { FlowType as FlowTypeEnum } from 'modules/selfServices/types/SelfService';

import Arrow from 'assets/icons/arrow.svg';
import ArrowDirection from 'assets/icons/Arrow';

import Modal from './Modal';
import Button, { ButtonType } from './Button';

const TYPES = {
  [FlowTypeEnum.CLASSIC]: {
    title: <FormattedMessage id="flowType.types.CLASSIC.title" defaultMessage="Check-in with questions" />,
    titleSuffix: <FormattedMessage id="flowType.averageTime" defaultMessage="(+/- {minutes}min)" values={{ minutes: 3 }} />,
    content: <FormattedMessage id="flowType.types.CLASSIC.content" defaultMessage="Answer a few questions to help us better prepare for the appointment, then drop the keys." />,
  },
  [FlowTypeEnum.QUICK]: {
    title: <FormattedMessage id="flowType.types.QUICK.title" defaultMessage="Quick check-in" />,
    titleSuffix: <FormattedMessage id="flowType.averageTime" defaultMessage="(+/- {minutes}min)" values={{ minutes: 1 }} />,
    content: <FormattedMessage id="flowType.types.QUICK.content" defaultMessage="Simply drop off the keys in just a few seconds." />,
  },
};

const FlowTypeModal: React.FC = () => {
  const dispatch = useDispatch();

  const search = useSelector(getSearch);
  const redirectUrl = useSelector(getRedirectUrl);

  const handleLogout = React.useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleClick = React.useCallback((type: FlowTypeEnum) => () => {
    dispatch(setFlowType(type));
    dispatch(push(`${redirectUrl}${search}`));
  }, [dispatch, search, redirectUrl]);

  return (
    <Modal
      open
      className="w-full max-w-md kiosk:max-w-4xl kioskSharebox:max-w-2xl"
      outsideClickClose={false}
      title={<FormattedMessage id="flowType.title" defaultMessage="Start check-in" tagName="h2" />}
    >
      <div className="flex flex-col gap-6">
        {Object.entries(TYPES).map(([type, data]) => (
          <div
            key={type}
            className="flex items-center p-6 gap-3 rounded-lg border-2 border-input-bg cursor-pointer"
            onClick={handleClick(type as FlowTypeEnum)}
            data-testid={type}
          >
            <div className="flex flex-col flex-grow gap-2">
              <div>
                <span className="font-bold text-base kiosk:text-3xl">{data.title}</span>
                <span className="text-sm kiosk:text-3xl ml-1">
                  {data.titleSuffix}
                </span>
              </div>
              <span className="text-sm kiosk:text-xl">{data.content}</span>
            </div>
            <Arrow className={`size-6 ${ArrowDirection.RIGHT}`} />
          </div>
        ))}
      </div>
      <Button onClick={handleLogout} className="w-full mt-10" type={ButtonType.SECONDARY} testId="logout">
        <FormattedMessage id="socket.updateLogoutModal.logOut" defaultMessage="Logout" values={{ countdown: 0 }} />
      </Button>
    </Modal>
  );
};

export default FlowTypeModal;
