import FinalInvoice from 'components/FinalInvoice';
import AppointmentSummary from 'components/AppointmentSummary';

import { SOCKET_UPDATE_STEPS } from 'modules/sockets/constants';

import Flow from 'types/Flow';
import { SelfServiceAPISteps, SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import { FINAL_INSTRUCTIONS } from '../common';
import type { SelfServiceStep } from '../../types';
import { isNotAnswered, hasHiddenSteps } from '../utils';

export const SUMMARY: SelfServiceStep = {
  key: SelfServiceSteps.SUMMARY,
  apiKey: SelfServiceAPISteps.APPOINTMENT_SUMMARY,
  component: AppointmentSummary,
  title: {
    id: 'page.appointmentSummary.title',
    defaultMessage: 'Your appointment',
  },
  next: SelfServiceSteps.FINAL_INVOICE,
  data: {
    preventBrowserNavigation: true,
    isVisible: ({ status }) => isNotAnswered(status),
    socketUpdateFields: SOCKET_UPDATE_STEPS.SUMMARY,
  },
};

export const FINAL_INVOICE: SelfServiceStep = {
  key: SelfServiceSteps.FINAL_INVOICE,
  apiKey: SelfServiceAPISteps.FINAL_INVOICE,
  component: FinalInvoice,
  title: {
    id: 'page.finalInvoice.title',
    defaultMessage: 'Final invoice',
  },
  previous: SUMMARY.key,
  next: SelfServiceSteps.PARKING,
  data: {
    isVisible: ({ status, finalInvoice }) => isNotAnswered(status) && Boolean(finalInvoice),
    socketUpdateFields: SOCKET_UPDATE_STEPS.FINAL_INVOICE,
  },
};

export type HomeCheckOutSteps =
  SelfServiceSteps.SUMMARY
  | SelfServiceSteps.FINAL_INVOICE
  | SelfServiceSteps.FINAL_INSTRUCTIONS;

const HOME_CHECK_OUT_STEPS: Flow<HomeCheckOutSteps> = {
  SUMMARY: {
    ...SUMMARY,
    data: { ...SUMMARY.data, isFinalizeStep: hasHiddenSteps([FINAL_INVOICE]) },
  },
  FINAL_INVOICE: {
    ...FINAL_INVOICE,
    next: FINAL_INSTRUCTIONS.key,
    data: { ...FINAL_INVOICE.data, isFinalizeStep: true },
  },
  FINAL_INSTRUCTIONS: {
    ...FINAL_INSTRUCTIONS,
    apiKey: SelfServiceAPISteps.INSTRUCTIONS,
    previous: FINAL_INVOICE.key,
  },
};

export default HOME_CHECK_OUT_STEPS;
