import React from 'react';

import Label from 'types/Label';
import { useSelector } from 'hooks';
import useLabelTranslation from 'hooks/useLabelTranslation';

import { getPayers } from 'modules/selfServices/selectors';
import { Payer, SelfService } from 'modules/selfServices/types/SelfService';

import { RepairOrderItemProps } from './RepairOrderItem';

type UseRepairOrderProps = Pick<SelfService, 'repairOrder' | 'dealer' | 'valueAddedTax'>;

const getPayerNameById = (payers: Array<Payer>, payerId: string, getTranslation: (label: Label) => string) => {
  const payer = payers.find((currentPayer) => currentPayer.id === payerId);
  return getTranslation(payer?.name);
};

const sortByPayers = (getTranslation: (label: Label) => string, payers: Array<Payer>) => (
  (itemA: RepairOrderItemProps, itemB: RepairOrderItemProps) => {
    if (!itemA.payerId || !itemB.payerId) {
      return !itemA.payerId ? 1 : -1;
    }

    const aPayerName = getPayerNameById(payers, itemA.payerId, getTranslation);
    const bPayerName = getPayerNameById(payers, itemB.payerId, getTranslation);
    return aPayerName.localeCompare(bPayerName);
  }
);

const useRepairOrder = ({ repairOrder, valueAddedTax, dealer }: UseRepairOrderProps) => {
  const { getLabelTranslation } = useLabelTranslation();
  const payers = useSelector(getPayers);

  const sortedRepairOrders = React.useMemo(
    () => repairOrder?.toSorted(({ workOrderTag: a = '' }, { workOrderTag: b = '' }) => a.localeCompare(b)) ?? [],
    [repairOrder],
  );

  const sortedRepairOrderItems = React.useMemo(
    () => sortedRepairOrders.map<RepairOrderItemProps>(({ adviceName, description, ...work }) => ({
      ...work,
      vat: valueAddedTax,
      currency: dealer.currencyIsoCode,
      name: getLabelTranslation(adviceName),
      description: description ? getLabelTranslation(description) : undefined,
    })).toSorted(sortByPayers(getLabelTranslation, payers)),
    [sortedRepairOrders, valueAddedTax, dealer, payers, getLabelTranslation],
  );

  return React.useMemo(() => ({
    sortedRepairOrderItems,
    hasRepairOrder: sortedRepairOrderItems.length > 0,
  }), [sortedRepairOrderItems]);
};

export default useRepairOrder;
