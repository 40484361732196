import { createDraftSafeSelector } from '@reduxjs/toolkit';

import type { RootState } from 'App/Store';

import { isLocalKiosk } from 'modules/dealers/selectors';

import { InitialState } from 'modules/healthCheck/reducer';

const getState = (state: RootState): InitialState => state.healthCheck;

export const isOffline = createDraftSafeSelector(getState, ({ online }) => !online);

export const isModalOpen = createDraftSafeSelector(getState, ({ isModalOpen: isOpen }) => isOpen);

export const isLocalKioskOffline = createDraftSafeSelector(
  isLocalKiosk,
  isOffline,
  (isKiosk, offline) => isKiosk && offline,
);
