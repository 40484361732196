import React from 'react';
import classNames from 'classnames';
import { FormattedList, FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  hasUnFinalizedSlot as hasUnFinalizedSlotSelector,
  unFinalizedSlotTypes as unFinalizedSlotTypesSelector,
} from 'modules/kiosk/selectors';
import { isKioskEmergencyMode } from 'modules/dealers/selectors';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import { useSelector } from 'hooks';
import { b } from 'utils/i18nUtils';
import { Button } from 'components/ui';
import { ButtonType } from 'components/ui/Button';

import CheckInIcon from 'assets/icons/checkIn.svg';
import CheckOutIcon from 'assets/icons/checkOut.svg';

interface TypeButtonProps {
  icon: React.ReactNode;
  type: string;
  description: string;
  onClick: () => void;
  'data-testid'?: string;
  disabled?: boolean;
}

const TypeButton: React.FC<TypeButtonProps> = ({
  icon, type, description, onClick, 'data-testid': dataCy, disabled,
}) => {
  const [animation, setAnimation] = React.useState(false);

  const handleOnClick = React.useCallback(() => setAnimation(true), []);

  React.useLayoutEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (animation) {
      timeoutId = setTimeout(() => {
        onClick?.();
        setAnimation(false);
      }, 300);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [animation, onClick]);

  return (
    <Button
      testId={dataCy}
      disabled={disabled}
      onClick={handleOnClick}
      type={ButtonType.LIGHT}
      className={classNames('flex-1 bg-card-bg rounded-3xl border-2 border-border-default overflow-hidden hover:no-underline focus:no-underline', { 'opacity-20': disabled })}
    >
      <div className={classNames(
        'absolute rounded-full w-1/3 bg-white aspect-square left-1/3 transition-all duration-300 z-0',
        animation && 'scale-500',
      )}
      />
      <div className="flex flex-col items-center relative z-10">
        <div className="rounded-full size-48 bg-white p-10 flex items-center justify-center mb-5">
          {icon}
        </div>
        <h3>{type}</h3>
        <p className="font-normal small">{description}</p>
      </div>
    </Button>
  );
};

const Admin = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { search } = useLocation();

  const hasKioskDoorsError = useSelector(isKioskEmergencyMode);
  const hasUnFinalizedSlot = useSelector(hasUnFinalizedSlotSelector);
  const unFinalizedSlotTypes = useSelector(unFinalizedSlotTypesSelector);

  const hasWarning = hasKioskDoorsError || hasUnFinalizedSlot;

  const handleOnClick = (type: SelfServiceType) => () => navigate(`/admin/management/${type}${search}`);

  const handleRedirectToEmergencyView = () => {
    navigate(`/admin/slots${search}`);
  };

  return (
    <div className="main-content !pt-120">
      <h1 className="text-center"><FormattedMessage id="home.admin" defaultMessage="What would you like to do?" /></h1>
      {hasWarning && (
        <div className="flex flex-col items-center gap-5 m-auto max-w-4xl w-full p-10 mt-10 bg-danger-lowest rounded-4xl border-2 border-danger-low text-danger-default text-center">
          {hasKioskDoorsError && (
            <>
              <h1 className="text-danger-default"><FormattedMessage id="admin.emergency.title" defaultMessage="Alert" /></h1>
              <FormattedMessage
                id="admin.emergency.description"
                defaultMessage="An issue has been detected with the kiosk. You must first <b>resolve the issue</b> in the slots thanks to <b>emergency access</b> before managing your check-ins/check-outs."
                tagName="span"
                values={{ b }}
              />
              <Button type={ButtonType.ERROR} onClick={handleRedirectToEmergencyView}>
                <FormattedMessage id="admin.emergency.button.title" defaultMessage="See slots" />
              </Button>
            </>
          )}
          {!hasKioskDoorsError && hasUnFinalizedSlot && (
            <FormattedMessage
              tagName="span"
              id="admin.unfinalizedSlot.description"
              defaultMessage="Some <b>{list}</b> have not been finalized correctly by the customer. <b>Please check them.</b>"
              values={{
                b,
                list: <FormattedList
                  type="conjunction"
                  value={unFinalizedSlotTypes.map((type) => (
                    <span className="lowercase">
                      <FormattedMessage
                        key={type}
                        defaultMessage={type}
                        id={`${type === SelfServiceType.CHECK_OUT ? 'checkOut' : 'checkIn'}s`}
                      />
                    </span>
                  ))}
                />,
              }}
            />
          )}
        </div>
      )}
      <div className="flex gap-3 w-5/6">
        <TypeButton
          disabled={hasKioskDoorsError}
          onClick={handleOnClick(SelfServiceType.CHECK_IN)}
          icon={<CheckInIcon className="aspect-square size-20 fill-brand-high" />}
          data-testid="check-in"
          type={intl.formatMessage({
            id: 'home.checkIn',
            defaultMessage: 'Check-in',
          })}
          description={intl.formatMessage({
            id: 'home.manage.checkIn',
            defaultMessage: 'Manage my check-ins',
          })}
        />
        <TypeButton
          disabled={hasKioskDoorsError}
          onClick={handleOnClick(SelfServiceType.CHECK_OUT)}
          icon={<CheckOutIcon className="aspect-square size-20 fill-brand-high" />}
          data-testid="check-out"
          type={intl.formatMessage({ id: 'home.checkOut', defaultMessage: 'Check-out' })}
          description={intl.formatMessage({ id: 'home.manage.checkOut', defaultMessage: 'Manage my check-outs' })}
        />
      </div>
    </div>
  );
};

export default Admin;
